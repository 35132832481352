import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import css from "./style.module.css"
import trash from "../../Images/Delete.svg"
import PromoCode from "../../Images/Icon.svg"
import YoumayAlsoLike from "./YoumayAlsoLike"
import "./styles/cartStyle.scss"
import $ from "jquery"
import axiosInstance from "../../api/axiosInstance"
import { productContext } from "../../Context/product"
import Loding from "../Loding/Loding"
import { UserContext } from "../../Context/UserInof"
export default function Cart() {
  const [cartItems, setCartItems] = useState([])
  const [warning, setWarning] = useState("")
  const [loading, setLoading] = useState(true)
  const [youMayLikeData, setYouMayLikeData] = useState([])
  const [finalCartToCheckOut, setFinalCartToCheckOut] = useState([])
  const navigate = useNavigate()
  const { setCartCount } = useContext(productContext)
  const { isUser, userGlobalInfo } = useContext(UserContext)

  useEffect(() => {
    getCartItems()
  }, [isUser])

  const getCartItems = async () => {
    setLoading(true)
    if (isUser === true) {
      try {
        const { data } = await axiosInstance.get("/user/cart", {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        })

        const response = await axiosInstance.post(
          `/product/getCartItemsDetails`,
          {
            products: data.cart,
          }
        )

        const productsQuantity = data.cart.map((product, index) => {
          return {
            ...response?.data?.products[index],
            quantity: product.quantity,
          }
        })

        setCartItems(productsQuantity)
        setCartCount(productsQuantity?.length)
        setFinalCartToCheckOut(productsQuantity)
      } catch (e) {
        console.log(e)
        setCartItems([])
      } finally {
        setLoading(false)
      }
    } else if (isUser === false) {
      const savedCartItems = JSON.parse(localStorage.getItem("cartLis")) || []
      if (savedCartItems.length > 0) {
        try {
          const productsArr = savedCartItems.map((savedCartItem) => {
            return {
              productId: savedCartItem.productId,
              variantId: savedCartItem.variantId,
            }
          })
          const response = await axiosInstance.post(
            `/product/getCartItemsDetails`,
            {
              products: productsArr,
            }
          )
          const fetchedProducts = response?.data?.products
          fetchedProducts.forEach((fetchedProduct, index) => {
            fetchedProduct.quantity = savedCartItems[index].quantity
          })

          setCartItems(fetchedProducts)
          localStorage.setItem("cart", JSON.stringify(fetchedProducts))
          getYouMayLike(productsArr[0].productId)
          setCartCount(fetchedProducts?.length)
        } catch (error) {
          console.error("Error fetching cart items:", error)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  const getYouMayLike = async (productId) => {
    try {
      const res = await axiosInstance.get(`/product/${productId}`)
      setYouMayLikeData(res?.data.productsYouMayLike)
    } catch (error) {
      console.error("Error fetching product data:", error)
    }
  }

  const handelChangeQuantity = async (value, ele, productId) => {
    const input = ele.target.closest(".quantitySec").querySelector("input")
    switch (value) {
      case "-":
        if (Number(input.value) > 1) {
          let newVal = Number(input.value) - 1
          input.value = newVal
        }
        break
      case "+":
        let newVal = Number(input.value) + 1
        input.value = newVal
        break
      default:
        return
    }
    const productHwoUpdateing = cartItems.findIndex(
      (item) => item._id === productId
    )
    const cartClone = [...cartItems]
    cartClone[productHwoUpdateing].quantity = Number(input.value)
    if (isUser === true) {
      localStorage.setItem("checkOutCart", JSON.stringify(cartClone))
      const newCart = cartClone.map((item) => {
        return {
          productId: item._id,
          quantity: item.quantity,
          variantId: item.variants[0]._id,
        }
      })
      try {
        const req = await axiosInstance.patch(
          "/user/cart",
          {
            cart: newCart,
          },
          {
            headers: {
              Authorization: `Bearer ${userGlobalInfo.token}`,
            },
          }
        )
      } catch (err) {
        console.log(err)
      }
    } else if (isUser === false) {
      localStorage.setItem("cart", JSON.stringify(cartClone))
    }

    setCartItems(cartClone)
  }

  const handelCheckPromoCode = async (value) => {
    try {
      const res = await axios.get(
        `https://api.yafa-brand.com/promoCode/${value}`
      )
      if (res.data.success) {
        console.log("Promo code is valid")
      } else {
        console.log("Promo code is invalid")
      }
    } catch (error) {
      console.log("Error checking promo code:", error)
      setWarning("Invalid promo code. Please try again.")
    }
  }

  const handleDeleteItemFromCart = async (productId, variantId) => {
    if (isUser === true) {
      try {
        const req1 = await axiosInstance.get(`/user/cart`, {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        })
        const filteredItems = req1.data.cart.filter((item) => {
          return item.productId !== productId
        })
        const req = await axiosInstance.patch(
          `/user/cart`,
          { cart: filteredItems },
          {
            headers: {
              Authorization: `Bearer ${userGlobalInfo.token}`,
            },
          }
        )
        getCartItems()
        setCartCount((prev) => prev - 1)
      } catch (error) {
        console.error("Error deleting item from cart:", error)
      }
    } else if (isUser === false) {
      const savedCartItems = JSON.parse(localStorage.getItem("cartLis")) || []
      const updatedCartList = savedCartItems.filter(
        (item) =>
          !(item.productId === productId && item.variantId === variantId)
      )
      const updatedCartItems = cartItems.filter(
        (item) =>
          !(item._id === productId && item.variants[0]._id === variantId)
      )

      setCartItems(updatedCartItems)
      localStorage.setItem("cartLis", JSON.stringify(updatedCartList))
      localStorage.setItem("cart", JSON.stringify(updatedCartItems))
      setCartCount((prev) => prev - 1)
    }
  }

  const handleCheckout = () => {
    if (!cartItems || cartItems.length === 0) {
      setWarning("Your cart is empty. Add items to proceed to checkout.")
    } else {
      setWarning("")
      localStorage.setItem("checkOutCart", JSON.stringify(finalCartToCheckOut))
      navigate("/checkout")
    }
  }

  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        {loading ? (
          <Loding />
        ) : (
          <div className="cartContainer">
            <div className="rightSide">
              <div className="cartText">
                <p
                  className={`${css.categoryHeader} archivo text-center text-md-start`}
                >
                  Your Cart
                </p>
              </div>

              <div className={`cartOffer archivo text-start text-md-start`}>
                LIMITED UNITS! Buy now and avoid the products in your basket
                running out.
              </div>
              {cartItems?.length === 0 ? (
                <p>Your cart is empty.</p>
              ) : (
                cartItems?.map((item, index) => {
                  const quantity = item.quantity
                  const price = item.price.toFixed(2)
                  const totalPrice = (Number(price) * Number(quantity)).toFixed(
                    2
                  )

                  return (
                    <div key={index} className="productBox">
                      <div className="imageSide">
                        <div className="imgBox">
                          <img src={item?.variants[0].images[0]} alt="" />
                        </div>
                        <div className="infoBox">
                          <span className="value">{item?.name}</span>
                          <ul className="variants">
                            {/* <li className="key">
                              Color :{" "}
                              <span className="value">
                                {item?.variants[0]?.color?.name}
                              </span>
                            </li> */}
                            <li className="key">
                              Size :{" "}
                              <span className="value">
                                {item?.variants[0]?.size}
                              </span>
                            </li>
                          </ul>
                          <span className="value">{totalPrice} EGP</span>
                        </div>
                      </div>
                      <div className="deleteIcon">
                        <div className="quantitySec">
                          <span
                            onClick={(e) =>
                              handelChangeQuantity("+", e, item._id)
                            }
                          >
                            <i className="fa-solid fa-plus"></i>
                          </span>
                          <input
                            type="number"
                            min={1}
                            value={item?.quantity}
                            readOnly
                          />
                          <span
                            onClick={(e) =>
                              handelChangeQuantity("-", e, item?._id)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            handleDeleteItemFromCart(
                              item._id,
                              item.variants[0]._id
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={trash} alt="" />
                        </div>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
            <div className="leftSide">
              <div>
                <p className={`${css.orderCrat}`}>Order Summary</p>
                <div className="ms-4 d-flex justify-content-between align-items-center">
                  <p>Sub Total</p>
                  <p>
                    {cartItems
                      ?.reduce(
                        (total, item) => (total + item.price) * item.quantity,
                        0
                      )
                      .toFixed(2)}{" "}
                    EGP
                  </p>
                </div>
                <div className="ms-4 d-flex justify-content-between align-items-center">
                  <p>Shipping</p>
                  <p>0 EGP</p>
                </div>
                <div className="ms-4 d-flex justify-content-between align-items-center">
                  <p>Add Promo Code</p>
                  <img
                    src={PromoCode}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      $(".promoCodeInput").slideToggle()
                    }}
                  />
                </div>
                <div className="promoCodeInput">
                  <input
                    type="text"
                    onBlur={(e) => handelCheckPromoCode(e.value)}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className={`${css.orderCrat}`}>Total</p>
                  <p className={`${css.orderCrat}`}>
                    {cartItems
                      .reduce(
                        (total, item) => (total + item.price) * item.quantity,
                        0
                      )
                      .toFixed(2)}{" "}
                    EGP
                  </p>
                </div>
                <div>
                  <button
                    className={`${css.Quantity2} text-center w-100`}
                    onClick={handleCheckout}
                  >
                    Check Out
                  </button>

                  {/* Display warning message if cart is empty */}
                  {warning && <p className="text-danger mt-2">{warning}</p>}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {youMayLikeData.length > 0 && (
        <div className="container">
          <YoumayAlsoLike
            data={youMayLikeData}
            breakpoints={{
              500: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
          />
        </div>
      )}
    </section>
  )
}
