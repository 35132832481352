import React, { useContext, useEffect, useState } from 'react';
import css from "../../style.module.css";
import { countriesContext } from '../../../../Context/Countries';

export default function CountriesEdition({ selectedCities, selectedCountry, setCitiesData, setCountriesData, countriesData, citiesData, setVisible }) {
    const { updateCountry, updateCity, deleteCity } = useContext(countriesContext);
    const [countryInfo, SetCountryInfo] = useState({});
    const [cityInfo, SetCityInfo] = useState({});
    const [spinner, setSpinner] = useState(false);
    const [spinnerCity, setSpinnerCity] = useState(false);
    const [error, setError] = useState(null);
    const [cities, setCities] = useState([]);
    console.log("selectedCountry is ");
    console.log(selectedCountry);
    console.log("selectedCities is ");
    console.log(selectedCities);

    useEffect(() => {
        if (selectedCities.length > 0) {
            setCities(selectedCities);
        }
    }, [selectedCities]);

    const liveCountryAdded = (country) => {
        setCountriesData([...countriesData, country]);
    };

    const liveCityAdded = (city) => {
        setCitiesData([...citiesData, city]);
    };

    const getCountryInfo = (e) => {
        const { name, value } = e.target;

        SetCountryInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    const getCityInfo = (e) => {
        const { name, value } = e.target;
        SetCityInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    const editCountry = async () => {
        setSpinner(true);
        try {
            const { data, status } = await updateCountry(countryInfo.name, countryInfo.code, countryInfo.countryId);
            // if (status === 201) {
            //     liveCountryAdded({ name: countryInfo.name, _id: data.country._id });
            // }
            console.log({ data, status });

            setSpinner(false);
        } catch (error) {
            setError(error?.response?.data?.message || "An error occurred");
            setSpinner(false);
        }
    };

    const editCity = async () => {
        setSpinner(true);
        try {
            const resopse = await updateCity(cityInfo.name, cityInfo.cityId);
            // if (status === 201) {
            //     liveCityAdded({ name: cityInfo.name, country: { _id: data.city.country } });
            // }
            console.log(resopse);
            setSpinner(false);
        } catch (error) {
            setError(error?.response?.data?.message || "An error occurred");
            setSpinnerCity(false);
        }
    };

    const deleteCities = async (id) => {
        setSpinner(true);
        try {
            await deleteCity(id);
            setCities(cities.filter(city => city._id !== id)); // Remove deleted city from the local state
            setSpinner(false);
        } catch (error) {
            setError(error?.response?.data?.message || "An error occurred");
            setSpinner(false);
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Edit Country</p>
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='name' onChange={getCountryInfo} placeholder='Name' />
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='code' onChange={getCountryInfo} placeholder='Code' />
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='countryId' onChange={getCountryInfo} placeholder='Country Id' />
            <button className={`${css.btnBoth} my-3 text-white`} onClick={editCountry}>
                Submit
                {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
            </button>

            <div className='d-flex justify-content-between align-items-center mt-2 '>
                <p className={`inter ${css.textHeader}`}>Edit City</p>
            </div>
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='name' onChange={getCityInfo} placeholder='Name' />
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='cityId' onChange={getCityInfo} placeholder='City Id' />

            {cities.length > 0 && cities.map((city, index) => (
                <div key={city._id} className={`${css.cities} d-flex justify-content-between align-items-center `}>
                    <p className={`${css.citiesProps} m-0`}>{city.name}</p>
                    <svg width="12" className='cursor' height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => deleteCities(city._id)}>
                        <path d="M10.9979 1.00488L1.0083 10.9945" stroke="#3D3D3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.9999 11L1.00195 1" stroke="#3D3D3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            ))}

            <div className='position-absolute bottom-0 mb-4 ms-5'>
                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`} onClick={() => { setVisible(0); }}>
                    Cancel
                </button>
                <button className={`${css.btnBoth} text-white`} onClick={editCity}>
                    Submit
                    {spinnerCity && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    );
}
