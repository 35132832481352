import React, { useContext, useState } from 'react';
import css from "../style.module.css";
import { PermissionContext } from '../../../Context/Permission';

export default function PermissiongroupsNav({ setVisible, permissionData, setPermissionData }) {
    const [active, setActive] = useState(0);
    const { createPermission } = useContext(PermissionContext);
    const initialPermissions = {
        addAdmin: false,
        editAdmin: false,
        deleteAdmin: false,
        createProduct: false,
        editProduct: false,
        deleteProduct: false,
        addCountry: false,
        editCountry: false,
        deleteCountry: false,
        editBanner: false,
        getPromoCode: false,
        createPromoCode: false,
        updatePromoCode: false,
        deletePromoCode: false,
        banUser: false
    };
console.log(initialPermissions);

    // State to store permissions
    const [permissions, setPermissions] = useState(initialPermissions);
    const [PermissionName, setPermissionName] = useState({});

    const handleCheckboxChange = (permissionName) => {
        // Update the state when a checkbox is clicked
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [permissionName]: !prevPermissions[permissionName], // Toggle the value (true/false)
        }));
    };
    const handelPermissionName = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setPermissionName({ [name]: value });
    };
    const livePermissionAdded = (permissionGroup) => {
        const copyPermissionGroup = [...permissionData]
        console.log(copyPermissionGroup);
        copyPermissionGroup.push(permissionGroup)
        setPermissionData(copyPermissionGroup)
    }
    const Submit = async () => {
        try {
            const response = await createPermission({ name: PermissionName.name, permissions });
            if (response.status === 200) {
                livePermissionAdded(response.data.permissionGroup)
            }

            console.log("createPermission : ");
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <nav className={`${css.sideNavDesign} `} >
            <p className={`inter ${css.textHeader}`}>Add New Permission Group</p>
            <input type="text" name='name' className={`${css.permissionInput}`} onChange={(e) => handelPermissionName(e)} placeholder='Name' />
            <p className={`inter ${css.textHeader}`}>Select Permissions</p>
            {Object.entries(permissions).map(([permissionName, isChecked], index) => (
                <div key={index} className='d-flex justify-content-start align-items-center my-3'>
                    <input
                        type="checkbox"
                        className={css.checkBoxInput}
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(permissionName)} // Handle checkbox change
                    />
                    <p className={`inter ${css.Permission} ${css.textFont} m-0 ms-2`}>
                        {permissionName}
                    </p>
                </div>
            ))}
            {/* {
            console.log({ PermissionName, permissions })
            } */}
            <div className='d-flex justify-content-between mt-3 '>
                <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(0)}>
                    <i className="fa-solid fa-angles-left"></i>
                </div>
                <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(active === 0 ? active + 2 : active - 1)}>
                    <i className="fa-solid fa-chevron-left"></i>
                </div>

                {/* Map through the array and set active state based on index */}
                {Array(3).fill(null).map((_, index) => (
                    <div
                        key={index}
                        onClick={() => setActive(index)}
                        className={`${css.pages} ${css.pagesShadow} ${active === index ? `${css.activePage} text-white` : "bg-white text-dark"}  d-flex justify-content-center align-items-center cursor`}
                    >
                        <span>{index + 1}</span>
                    </div>
                ))}
                <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(active < 2 ? active + 1 : active - 2)}>
                    <i className="fa-solid fa-chevron-right"></i>
                </div>
                <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(2)}>
                    <i className="fa-solid fa-angles-right"></i>
                </div>
            </div>
            <div className='d-flex justify-content-center align-items-center  mt-5 '>

                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3 `} onClick={() => setVisible(0)}>
                    <svg width="25" height="24" className='me-2' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Cancel
                </button>
                <button className={`${css.btnBoth} `} onClick={Submit}>
                    <svg width="25" className='me-2' height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Submit
                </button>
            </div>
        </nav>
    );
}
