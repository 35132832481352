import { useNavigate } from "react-router-dom";
import "./formsStyle.scss";
import formPhoto from "../../../../Images/formBg.png";
import { useContext, useState } from "react";
import { UserContext } from "../../../../Context/UserInof";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../api/axiosInstance";

const Register = () => {
  const { setCurrentForm } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Initialize loading as false
  const navg = useNavigate();

  const handleSubmit = async (Val) => {
    setLoading(true); // Start loading when submission begins
    const finalData = {
      firstName: Val.firstName,
      lastName: Val.lastName,
      email: Val.email,
      password: Val.password,
      phone: Val.phone,
    };

    try {
      if (typeof window.fbq !== "undefined") {
        window.fbq("track", "CompleteRegistration");
      }
      const req = await axiosInstance.post("/user/register", finalData);
      if (req.status === 200 || req.status === 201) {
        setCurrentForm("login");
        registerForm.resetForm(); // Reset form after successful registration
      } else {
        setErrorMessage("Registration failed. Please try again.");
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading after API call completes
    }
  };

  // Define validation schema with Yup
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("First name is required")
      .min(3, "Minimum 3 characters")
      .max(20, "Maximum 20 characters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Minimum 3 characters")
      .max(20, "Maximum 20 characters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "Password must have at least 8 characters, including an uppercase, a lowercase, and a number"
      ),
    rePassword: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref("password")], "Passwords must match"),
    phone: yup
      .string()
      .required("Phone is required")
      .matches(/^01[1250][0-9]{8}$/, "Enter a valid phone number"),
  });

  const registerForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      phone: "",
    },
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="formPage">
      <div className="blurLay"></div>
      <div className="formContainer">
        <div className="imageSide">
          <img src={formPhoto} alt="form background" />
        </div>
        <form onSubmit={registerForm.handleSubmit} className="formSide">
          <motion.div
            initial={{ y: "-400px" }}
            animate={{
              y: "0px",
              transition: { delay: 0.1, duration: 0.8, type: "spring" },
            }}
            className="formTilte"
          >
            <h1>Register</h1>
            <p>
              Create an account in our store and start shopping for your
              products.
            </p>
          </motion.div>

          <span className="close" onClick={() => setCurrentForm("")}>
            <i className="fa-solid fa-xmark"></i>
          </span>

          <span className="back" onClick={() => setCurrentForm("backToLogin")}>
            <i className="fa-solid fa-arrow-left"></i>
          </span>

          {/* Input fields with validation errors displayed */}
          {[
            "firstName",
            "lastName",
            "email",
            "phone",
            "password",
            "rePassword",
          ].map((field, index) => (
            <motion.div
              key={field}
              initial={{ x: "-600px" }}
              animate={{
                x: "0px",
                transition: {
                  delay: 0.1 * (index + 1),
                  duration: 1,
                  type: "spring",
                },
              }}
              className="inputContainer"
            >
              <label htmlFor={field}>{`${
                field.charAt(0).toUpperCase() +
                field.slice(1).replace("Re", "Confirm ")
              } :`}</label>
              <input
                type={field.includes("password") ? "password" : "text"}
                name={field}
                id={field}
                placeholder={`Enter ${
                  field.charAt(0).toUpperCase() + field.slice(1)
                }`}
                onChange={registerForm.handleChange}
                onBlur={registerForm.handleBlur}
              />
              {registerForm.errors[field] && registerForm.touched[field] && (
                <span className="error">{registerForm.errors[field]}</span>
              )}
            </motion.div>
          ))}

          {/* Submit Button with loading state */}
          <motion.div
            initial={{ x: "600px" }}
            animate={{
              x: "0px",
              transition: { delay: 0.6, duration: 1, type: "spring" },
            }}
            className="submitBtn"
          >
            <button type="submit" disabled={loading}>
              {loading ? (
                <i className="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Sign Up"
              )}
            </button>
          </motion.div>

          {/* Display error message if exists */}
          {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Register;
