import React, { useContext, useEffect, useState } from 'react'
import css from '../style.module.css';
import deleteSvg from '../../../Images/Delete.svg';
import AddStockSvg from '../../../Images/AddStock.svg';
import editProduct from '../../../Images/editProduct.svg';
import AddNewBuuton from '../../../Images/AddNewBuuton.svg';
import ProductNavEdition from '../../Navs/SideNav/Edition/ProductNavEdition';
import AddStock from '../../Navs/SideNav/AddStock';
import EditVariant from '../../Navs/SideNav/EditVariant';
import { productContext } from '../../../Context/product';
import AddVariant from '../../Navs/SideNav/AddVariant';

export default function ProductDetails({ setDetails, productData,setVisibleProductNav }) {
    setVisibleProductNav(false)
    const { deleteProduct } = useContext(productContext)
    const [visible, setVisible] = useState(false);
    const [stock, setStock] = useState(false);
    const [variantStatus, setVariantStatus] = useState(false);
    const [productDetails, setProductDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [variantsData, setvariantsData] = useState([]);
    const [singlevariantsData, setSingleVariantsData] = useState([]);
    const [stockEditData, setStockEditData] = useState([]);
    const [AddVaraintState, setAddVaraintState] = useState(false);

    useEffect(() => {
        if (productData) {
            setIsLoading(false);
            setvariantsData(productData.variants);
            console.log(productData.variants);
            setProductDetails(productData);
            console.log("productData : ");
            console.log(productData);

        }
    }, [productData]);
    useEffect(()=>{
        console.log("variantsData: ",variantsData);
    },[variantsData])

    const openProductNavEdition = () => {
        setVisible(true);
        setStock(false);
        setVariantStatus(false);
    }

    const openAddVariant = () => {
        setAddVaraintState(true)
        setVisible(false);
        setStock(false);
        setVariantStatus(false);
    }


    const openStock = () => {
        console.log("dmamnlmsd,");
        setAddVaraintState(false)

        setVisible(false);
        setStock(true);
        setVariantStatus(false);
    }
    const openVariantNav = () => {
        setAddVaraintState(false)

        setVisible(false);
        setStock(false)
        setVariantStatus(true)
    }

    const editIds = (variantId) => {

        const ids = {
            productId: productDetails._id,
            variantId
        }

        setStockEditData(ids)
        return console.log(ids);
    }

    const SpecificVariantdata = (variant) => {

        setSingleVariantsData(variant)
        return null
    }
    const deleteVariantFunc = async (variantId) => {

        const productId = productDetails._id;

        try {
            const res = await deleteProduct(productId, variantId);
            if (res.status === 200) {
                const updatedVariants = variantsData.filter((e) => e._id !== variantId);
                console.log(updatedVariants);
                setvariantsData(updatedVariants);
            }
        } catch (error) {
            console.error("Error deleting variant:", error);
        }
    };

    if (isLoading) {
        return (
            <p>
                Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
            </p>
        );
    }

    return (
        <>
            <div className="d-flex " style={{ width: '100%' }}>
                <div
                    className={`fadeInAnimate px-3 ${visible || stock || variantStatus ||AddVaraintState ? 'col-md-8 ' : ' col-12'}`}
                    style={{ transition: 'margin-left 0.3s' }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex align-items-center">
                                <button className={`mt-1 ${css.btnProduct} me-2`} onClick={() => setDetails(0)}>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.00033 1.83331C11.6818 1.83331 14.667 4.8178 14.667 8.49998C14.667 12.1815 11.6818 15.1666 8.00033 15.1666C4.31885 15.1666 1.33366 12.1815 1.33366 8.49998C1.33366 4.8178 4.31889 1.83331 8.00033 1.83331ZM13.667 8.49998C13.667 5.37016 11.1296 2.83331 8.00033 2.83331C4.8711 2.83331 2.33366 5.37016 2.33366 8.49998C2.33366 11.6292 4.87114 14.1666 8.00033 14.1666C11.1295 14.1666 13.667 11.6292 13.667 8.49998Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.66895 6.18739L7.34648 8.49987L9.66895 10.8123L8.96337 11.521L5.9292 8.49987L8.96336 5.47876L9.66895 6.18739Z" fill="white" />
                                    </svg>
                                </button>
                                <div className="mt-3">
                                    <p className={`${css.textProp} inter m-0`}>Product Name</p>
                                    <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                                </div>
                            </div>
                        </div>
                        <button className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white d-flex justify-content-center align-items-center border-0`} onClick={() => openProductNavEdition()}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 8.5C14 5.1875 11.3125 2.5 8 2.5C4.6875 2.5 2 5.1875 2 8.5C2 11.8125 4.6875 14.5 8 14.5C11.3125 14.5 14 11.8125 14 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                                <path d="M8 6V11V6ZM10.5 8.5H5.5H10.5Z" fill="white" />
                                <path d="M8 6V11M10.5 8.5H5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            EDIT
                        </button>
                    </div>

                    <div className={css.productContainer}>
                        <div className="row">
                            <div className="col-md-2">
                                <img src={productDetails.images[0]} alt="" className="w-100 h-100 rounded-3" />
                            </div>
                            <div className="col-md-10">
                                <div>
                                    <p className={`inter ${css.Internalwriting}`}>Name <span className="fw-bold">{productDetails.name}</span></p>
                                </div>
                                <div className="d-flex">
                                    <p className={`inter ${css.Internalwriting}`}>Category <span className="fw-bold">{productDetails.category ? productDetails.category.name : "N/A"}</span></p>
                                    <p className={`inter ${css.Internalwriting} m-auto`}>Price <span className="fw-bold">{productDetails.price} EGP</span></p>
                                </div>
                                <div>
                                    <p className={`inter ${css.Internalwriting}`}>Description</p>
                                    <p className={`inter ${css.Internalwriting}`}>{productDetails.description}</p>
                                </div>
                            </div>
                        </div>

                        <div className={`d-flex align-items-center ${css.productBanar} mt-2`}>
                            <div className="w-50 d-flex justify-content-between align-items-center">
                                <p className="m-0">Sales</p>
                                <p className="d-flex align-items-center my-auto">
                                    <svg width="11" height="12" className="me-2" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.25 5.625L5.75 1.125L10.25 5.625M5.75 1.75V10.875" stroke="#FFA52F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {productDetails.sale_percentage || 0} %
                                </p>
                                <span className={css.middelBar}></span>
                            </div>
                            <div className="w-50">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="m-0">Remaining</p>
                                    <p className="d-flex align-items-center my-auto">
                                        <div className={css.counterbar}>
                                            <p style={{ width: "70%", height: "100%", backgroundColor: "#FFA52F", borderRadius: "8px" }}></p>
                                        </div>
                                        {productDetails.variants.reduce((total, variant) => total + variant.stock, 0)}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            {variantsData.map((item, index) => (
                                <div className="row mt-3" key={index}>
                                    <div className="col-md-1">
                                        <img src={item.images[0]} alt="" className="w-100" />
                                    </div>
                                    <div className="col-md-11 d-flex flex-column justify-content-between">
                                        <div className="d-flex justify-content-between">
                                            <p className={`inter ${css.Internalwriting} m-0`}>color <span className="fw-bold">{item.color?.name}</span></p>
                                            <img src={deleteSvg} className='cursor' onClick={() => deleteVariantFunc(item._id)} alt="" />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            <p className={`inter m-0 ${css.Internalwriting}`}>Size <span className="fw-bold">{item.size}</span></p>
                                            <p className={`inter m-0 ${css.Internalwriting}`}>Qty <span className="fw-bold">{item.stock} Item</span></p>
                                            <p className={`inter m-0 ${css.Internalwriting}`}>Status <span className="fw-bold ms-2">{item.status}</span></p>
                                            <div className="d-flex">
                                                <img src={editProduct} alt="" className="me-2 cursor" onClick={() => { openVariantNav(); editIds(item._id); SpecificVariantdata(item) }} />
                                                <img src={AddStockSvg} alt="" className='cursor' onClick={() => { openStock(); editIds(item._id) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-content-end mt-4'>
                            <img src={AddNewBuuton} onClick={() => openAddVariant()  } omc alt="" />
                        </div>
                    </div>

                </div>


                {visible && (
                    <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'}`}>
                        <ProductNavEdition setVisible={setVisible} productDetails={productDetails} variantsData={variantsData} visible={visible} />
                    </div>
                )}
                {stock && (
                    <div className={`p-0 displayAnimation ${stock ? 'show' : 'hide'}`}>
                        <AddStock setStock={setStock} stockEditData={stockEditData} />
                    </div>
                )}
                {variantStatus && (
                    <div className={`p-0 displayAnimation ${variantStatus ? 'show' : 'hide'}`}>
                        <EditVariant setVariantStatus={setVariantStatus} stockEditData={stockEditData} productDetails={productDetails} />
                    </div>
                )}

                {AddVaraintState && (
                    <div className={`p-0 displayAnimation ${AddVaraintState ? 'show' : 'hide'}`}>
                        <AddVariant setVariantStatus={setVariantStatus} AddVaraintState={AddVaraintState} setAddVaraintState={setAddVaraintState}  productDetails={productDetails} />
                    </div>
                )}
            </div>
        </>
    )
}
