import { Link } from "react-router-dom";
import "./NotFoundStyle.scss";

const NotFound = () => {
  return (
    <>
      <div className="notFound">
        <img src="/404.png" alt="" />
        <div className="message">
          <span>Oops!</span>
          <h1>Same like something went error</h1>
        </div>
        <Link to={`/`}>Back To Home Page</Link>
      </div>
    </>
  );
};

export default NotFound;