import "./formsStyle.scss";
import formPhoto from "../../../../Images/formBg.png";
import { useContext, useState } from "react";
import { UserContext } from "../../../../Context/UserInof";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../api/axiosInstance";

const Login = () => {
  const { setCurrentForm, setUserGlobalInfo } = useContext(UserContext);
  let [errorMessage, setErrorMessage] = useState("");
  let [isLoding, setIsLoading] = useState(false);
  const handelSubmit = async (Val) => {
    setIsLoading(true);
    try {
      const req = await axiosInstance.post("/user/login", Val);
      if (req.status === 200 || 201) {
        localStorage.setItem("user", JSON.stringify(req?.data));
        setUserGlobalInfo(req?.data);
        setCurrentForm("");
        loginForm.resetForm();
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(e.response.data.message);
    }
    setIsLoading(false);
  };

  let validationSchema = yup.object({
    email: yup
      .string()
      .required("Email Is Required")
      .email("Enter Valid Email"),
    password: yup
      .string()
      .required("password is required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "enter valid passowrd"
      ),
  });

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handelSubmit,
    validationSchema,
  });

  return (
    <>
      <div className="formPage">
        <div className="blurLay"></div>
        <div className="formContainer">
          <div className="imageSide">
            <img src={formPhoto} alt="formPhoto" />
          </div>
          <form onSubmit={loginForm.handleSubmit} className="formSide">
            <motion.div
              initial={{ y: "-400px" }}
              animate={{
                y: "0px",
                transition: { delay: 0.5, duration: 0.8, type: "spring" },
              }}
              className="formTilte"
            >
              <h1>Sign In</h1>
              <p>Login to our store an start shopping your products </p>
            </motion.div>
            <span
              className="close"
              onClick={() => {
                setCurrentForm("");
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
            <motion.div
              initial={{ x: "-600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.5, duration: 1, type: "spring" },
              }}
              className="inputContainer"
            >
              <label htmlFor="email">Email : </label>
              <input
                type="email"
                name="email"
                id=""
                placeholder="Enter Email"
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
              />
              {loginForm.errors.email && loginForm.touched.email && (
                <span className="error">{loginForm.errors.email}</span>
              )}
            </motion.div>

            <motion.div
              initial={{ x: "-600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.8, duration: 1, type: "spring" },
              }}
              className="inputContainer"
            >
              <label htmlFor="password">password : </label>
              <input
                type="password"
                name="password"
                id=""
                placeholder="Enter password"
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
              />
              {loginForm.errors.password && loginForm.touched.password && (
                <span className="error">{loginForm.errors.password}</span>
              )}
            </motion.div>

            <motion.span
              onClick={() => {
                setCurrentForm("forgetPass");
              }}
              className="forgotPass"
              initial={{ x: "600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.8, duration: 1, type: "spring" },
              }}
              style={{ cursor: "pointer" }}
            >
              Forget Password
            </motion.span>
            <motion.div
              initial={{ x: "600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.8, duration: 1, type: "spring" },
              }}
              className="submitBtn"
            >
              <button type="submit" disabled={isLoding}>
                {isLoding ? (
                  <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  "Sign In"
                )}
              </button>
            </motion.div>
            <motion.span
              style={{
                color: "var(--sec-Color)",
                fontSize: "14px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              initial={{ x: "600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.8, duration: 1, type: "spring" },
              }}
              onClick={() => {
                setCurrentForm("register");
              }}
            >
              Don't Have Account
            </motion.span>
            {errorMessage && <span className="error">{errorMessage}</span>}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
