import { Route, Routes } from "react-router-dom";
// Login
import Login from "./Components/Login/Login";
import LayOuts from "./Components/LayOuts/LayOuts";
import ForgetPassword from "./Components/Login/ForgetPassword";
import OTPVerification from "./Components/Login/OTPVerification";
import CreatePassword from "./Components/Login/CreatePassword";
// Admin Panal
import 'bootstrap/dist/css/bootstrap.min.css';
import PromoCodes from "./Components/Admin Panel/PromoCodes/PromoCodes";
import CreateData from "./Components/Admin Panel/Create Data/CreateData";
import Admin from "./Components/Admin Panel/Admin/Admin";
import Dashboard from "./Components/Admin Panel/Dashboard/Dashboard";
import Users from "./Components/Admin Panel/Users/Users";
import OrderManagement from "./Components/Admin Panel/Order Management/OrderManagement";
import Revenue from "./Components/Admin Panel/Revenue/Revenue";
import Media from "./Components/Admin Panel/Media/Media";
import Products from "./Components/Admin Panel/Products/Products";
// Super Panal
import SuperDashboard from "./Components/Super Admin/Dashboard/Dashboard";
import SuperProducts from "./Components/Super Admin/Products/Products";
import SuperUsers from "./Components/Super Admin/Users/Users";
import SuperOrderManagement from "./Components/Super Admin/Order Management/OrderManagement";
import SuperRevenue from "./Components/Super Admin/Revenue/Revenue";
// Permision Groups
import SuperPermissiongroups from "./Components/Super Admin/Permission Groups/Permissiongroups";
// Counters
import SuperCountries from "./Components/Super Admin/Countries/Countries";

// stores
import SuperStores from "./Components/Super Admin/Stores/Stores";
import SuperAdmin from "./Components/Super Admin/Admin/Admin";
import UserProvider from "./Context/UserInof";
import PermissionProvider from "./Context/Permission";
import CountriesProvider from "./Context/Countries";
import ImageSlider from "./Components/test/swiper";
import Home from "./Components/Home/Home";
import Category from "./Components/Home/Category";
import ProductDetails from "./Components/Home/ProductDetails";
import Cart from "./Components/Home/Cart";
import CheckOut from "./Components/Home/CheckOut";
import SelectAddress from "./Components/Home/SelectAddress";
import Payment from "./Components/Home/Payment";
import MyOrders from "./Components/Home/MyOrders";
import MyAddress from "./Components/Home/MyAddress";
import MyPersonalData from "./Components/Home/MyPersonalData";
import Favorites from "./Components/Home/Favorites";
import Offers from "./Components/Home/Offers";
import Stores from "./Components/Home/Stores";
import AboutUs from "./Components/Home/AboutUs";
import ContactUs from "./Components/Home/ContactUs";
import AdminProvider from "./Context/Admin";
import StoreProvider from "./Context/Stores";
import PromoCodeProvider from "./Context/PromoCodes";
import CategoriesProvider from "./Context/categories";
import ProductProvider from "./Context/product";
import ColorProvider from "./Context/color";
import NotFound from "./Components/NotFound/NotFound";
import "react-toastify/dist/ReactToastify.css";
import MediaProvider from "./Context/Media";
import Popup from "./Components/Admin Panel/Popup/Popup";
import PopupProvider from "./Context/Popup";
import TermsAndConditions from "./Components/Home/TermsAndConditions"
function App() {
  return (
    <UserProvider>
      <PermissionProvider>
        <CountriesProvider>
          <AdminProvider>
            <StoreProvider>
              <PromoCodeProvider>
                <CategoriesProvider>
                  <ProductProvider>
                    <ColorProvider>
                      <MediaProvider>
                        <PopupProvider>
                          <Routes>
                            <Route element={<LayOuts />}>
                              {/* Login Paths */}
                              <Route path="/login" element={<Login />} />
                              <Route
                                path="/ForgetPassword"
                                element={<ForgetPassword />}
                              />
                              <Route
                                path="/Verification"
                                element={<OTPVerification />}
                              />
                              <Route
                                path="/createpassword"
                                element={<CreatePassword />}
                              />
                              {/* Admin Panal */}
                              <Route
                                path="/adminpanel/dashboard"
                                element={<Dashboard />}
                              />
                              <Route
                                path="/adminpanel/promocodes"
                                element={<PromoCodes />}
                              />
                              <Route
                                path="/adminpanel/media"
                                element={<Media />}
                              />
                              <Route
                                path="/adminpanel/createdata"
                                element={<CreateData />}
                              />
                              <Route
                                path="/adminpanel/admins"
                                element={<Admin />}
                              />
                              <Route
                                path="/adminpanel/users"
                                element={<Users />}
                              />
                              <Route
                                path="/adminpanel/Popup"
                                element={<Popup />}
                              />
                              <Route
                                path="/adminpanel/ordermanagement"
                                element={<OrderManagement />}
                              />
                              <Route
                                path="/adminpanel/revenue"
                                element={<Revenue />}
                              />
                              <Route
                                path="/adminpanel/products"
                                element={<Products />}
                              />

                              {/* Super Panal */}
                              <Route
                                path="/superpanal/dashboard"
                                element={<SuperDashboard />}
                              />
                              <Route
                                path="/superpanal/products"
                                element={<SuperProducts />}
                              />
                              <Route
                                path="/superpanal/users"
                                element={<SuperUsers />}
                              />
                              <Route
                                path="/superpanal/ordermanagement"
                                element={<SuperOrderManagement />}
                              />
                              <Route
                                path="/superpanal/revenue"
                                element={<SuperRevenue />}
                              />
                              <Route
                                path="/superpanal/permissiongroups"
                                element={<SuperPermissiongroups />}
                              />
                              <Route
                                path="/superpanal/stores"
                                element={<SuperStores />}
                              />
                              <Route
                                path="/superpanal/countries"
                                element={<SuperCountries />}
                              />
                              <Route
                                path="/superpanal/admin"
                                element={<SuperAdmin />}
                              />
                              {/* Home */}
                              <Route index="true" path="/" element={<Home />} />
                              <Route path="/swiper" element={<ImageSlider />} />
                              <Route
                                path="/category/:categoryId"
                                element={<Category />}
                              />
                              <Route
                                path="/productdetails/:productId"
                                element={<ProductDetails />}
                              />
                              <Route path="/cart" element={<Cart />} />
                              <Route path="/checkout" element={<CheckOut />} />
                              <Route
                                path="/selectaddress"
                                element={<SelectAddress />}
                              />
                              <Route path="/payment" element={<Payment />} />
                              <Route
                                path="/myorders/:id"
                                element={<MyOrders />}
                              />
                              <Route
                                path="/myaddress"
                                element={<MyAddress />}
                              />
                              <Route
                                path="/mypersonaldata"
                                element={<MyPersonalData />}
                              />
                              <Route
                                path="/favorites"
                                element={<Favorites />}
                              />
                              <Route path="/offers" element={<Offers />} />
                              <Route path="/stores" element={<Stores />} />
                              <Route path="/about" element={<AboutUs />} />
                              <Route path="/terms" element={<TermsAndConditions />} />
                              <Route
                                path="/contactus"
                                element={<ContactUs />}
                              />
                              <Route path="*" element={<NotFound />} />
                            </Route>
                          </Routes>
                        </PopupProvider>
                      </MediaProvider>
                    </ColorProvider>
                  </ProductProvider>
                </CategoriesProvider>
              </PromoCodeProvider>
            </StoreProvider>
          </AdminProvider>
        </CountriesProvider>
      </PermissionProvider>
    </UserProvider>
  );
}

export default App;
