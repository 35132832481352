import React, { useContext, useEffect, useState } from 'react';
import css from "../style.module.css";
import { productContext } from '../../../Context/product';

export default function AddStock({ setStock, stockEditData }) {
    const { AddStockFunc } = useContext(productContext)
    const [stockData, setStockData] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        console.log(stockEditData);

    }, [stockEditData])

    const getStock = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setStockData((prevStockData) => ({
            ...prevStockData,
            [name]: Number(value) // Ensure value is stored as a number
        }));
    };

    const Submit = async () => {
        if (typeof stockData.stock === 'undefined') {
            console.error("Stock is undefined");
            setMessage("Please enter a stock quantity.");
            return;
        }
        console.log("stockData form func");
        console.log(stockData);
        

        setIsLoading(true);
        try {
            const response = await AddStockFunc(stockData, stockEditData);
            if (response && response.status === 200) {
                setMessage(response.message);
            }
        } catch (error) {
            console.log(error.message || "Error in submitting stock data.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Add Stock</p>
            <input type="number" name="stock" className={`${css.adminInput} work-sans my-2 `} placeholder='Quantity' onChange={(e) => getStock(e)} />
            <div className='position-absolute bottom-0 mb-4 ms-5 '>
                {message && <p className='text-center mt-3 text-danger fw-bold'>{message}</p>}
                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3 `} onClick={() => setStock(false)}>
                    <svg width="25" height="24" className='me-2' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Cancel
                </button>
                <button className={`${css.btnBoth} `} onClick={Submit}>
                    <svg width="25" className='me-2' height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Submit
                    {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    )
}
