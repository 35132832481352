import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";

export const storeContext = createContext(null);

export default function StoreProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [store, setStore] = useState(null);
  const [pagination, setPagination] = useState(null);

  const getstore = async () => {
    try {
      const { data } = await axios.get("https://api.yafa-brand.com/store", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      //   console.log(data);
      setStore(data.stores);
      setPagination(data.pagination);
    } catch (error) {
      console.error("Failed to fetch store", error);
    }
  };

  const createstore = async (Newstore) => {
    console.log("Newstore");
    console.log(Newstore);

    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/store",
        Newstore,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch store", error);
    }
  };
  const deletestore = async (id) => {
    try {
      const response = await axios.delete(`https://api.yafa-brand.com/store/${id}`, {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Failed to fetch store", error);
    }
  };
  const updatestore = async (data, storeId) => {
    console.log(userGlobalInfo.token);

    console.log(data);
    
    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/store/${storeId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch store", error);
    }
  };

  useEffect(() => {
    if (userGlobalInfo?.token) {
      getstore();
    }
  }, [userGlobalInfo]);
  return (
    <storeContext.Provider
      value={{
        store,
        setStore,
        pagination,
        createstore,
        deletestore,
        updatestore,
      }}
    >
      {children}
    </storeContext.Provider>
  );
}
