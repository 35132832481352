import React, { useContext, useEffect, useState } from 'react';
import css from '../style.module.css';
import { colorContext } from '../../../Context/color';

export default function Colors() {

    const { color, deletecolor } = useContext(colorContext);
    const [colorData, setColorData] = useState([]);
    useEffect(() => {
        if (color) {
            setColorData(color.colors);
        }

    }, [color]); // Only depend on `color`

    const deletecolorData = async (id) => {
        const data = await deletecolor(id)
        if (data.status === 200) {
            setColorData(colorData.filter((color) => color._id !== id));
        }
        
    }

    return (
        <>
            <div className="row">
                {colorData?.map((color) => (
                    <div key={color._id} className="col-md-4">
                        <div className={`mt-3 mb-2 d-flex justify-content-between align-items-center ${css.createdDataBars}`}>
                            {/* Render the 'name' of the color object */}
                            <p className={`m-0 inter ${css.permissionGroupsText}`}>{color.name}</p>
                            <div className="">
                                <svg width="28" height="28" className="me-3" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="28" rx="8" fill="#8B442A" /> {/* Use the 'hex' value for color */}
                                    <path d="M14 19.3334H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 8.33322C17.2652 8.068 17.6249 7.91901 18 7.91901C18.1857 7.91901 18.3696 7.95559 18.5412 8.02666C18.7128 8.09773 18.8687 8.2019 19 8.33322C19.1313 8.46454 19.2355 8.62044 19.3066 8.79202C19.3776 8.9636 19.4142 9.1475 19.4142 9.33322C19.4142 9.51894 19.3776 9.70284 19.3066 9.87442C19.2355 10.046 19.1313 10.2019 19 10.3332L10.6667 18.6666L8 19.3332L8.66667 16.6666L17 8.33322Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <svg width="28" className='cursor' height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={()=>deletecolorData(color._id)}>
                                    <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#8B442A" />
                                    <path d="M8 10H9.33333H20" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.3334 10V8.66671C11.3334 8.31309 11.4739 7.97395 11.7239 7.7239C11.9739 7.47385 12.3131 7.33337 12.6667 7.33337H15.3334C15.687 7.33337 16.0261 7.47385 16.2762 7.7239C16.5262 7.97395 16.6667 8.31309 16.6667 8.66671V10M18.6667 10V19.3334C18.6667 19.687 18.5262 20.0261 18.2762 20.2762C18.0261 20.5262 17.687 20.6667 17.3334 20.6667H10.6667C10.3131 20.6667 9.97395 20.5262 9.7239 20.2762C9.47385 20.0261 9.33337 19.687 9.33337 19.3334V10H18.6667Z" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.6666 13.3334V17.3334" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.3334 13.3334V17.3334" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


        </>
    )
}
