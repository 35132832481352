import React, { useContext, useEffect, useState } from 'react';
import css from "../style.module.css";
import { PopupContext } from '../../../Context/Popup';
import { promoCodeContext } from '../../../Context/PromoCodes';

export default function PopupNav({ setVisibleNavPoup, setPopUpData }) {
    const { addPopup } = useContext(PopupContext);
    const { promo } = useContext(promoCodeContext);

    const [data, setData] = useState({});
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPromoId, setSelectedPromoId] = useState(''); // To store the selected promo's _id

    useEffect(() => {
        console.log("promoo", promo);
    }, [promo]);

    const getEmail = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle the change in the promo code select dropdown
    const handlePromoSelect = (e) => {
        const selectedId = e.target.value;
        setSelectedPromoId(selectedId); // Store the selected _id
        setData(prevState => ({
            ...prevState,
            promoCode: selectedId, // Save the promoId in data to be sent to the server
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const Submit = async () => {
        setIsLoading(true);
        try {
            const response = await addPopup(data);
            if (response.status === 200 || response.status === 201) {
                setIsLoading(false);
                setMessage(response.message);
            }
            console.log(response);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            console.log(error.message || "error");
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Add Popup</p>

            {/* Other inputs */}
            <input
                onChange={(e) => getEmail(e)}
                type="text"
                name='body'
                className={`${css.adminInput} work-sans my-2`}
                placeholder="body"
            />

            {/* True/False Options */}
            <div className="mb-3">
                <label className="work-sans me-3">Homepage:</label>
                <label className="me-3">
                    <input
                        type="radio"
                        name="homepage"
                        value="true"
                        checked={data.homepage === "true"}
                        onChange={handleSelectChange}
                    /> Show
                </label>
                <label className="me-3">
                    <input
                        type="radio"
                        name="homepage"
                        value="false"
                        checked={data.homepage === "false"}
                        onChange={handleSelectChange}
                    /> Hide
                </label>
            </div>

            <div className="mb-3">
                <label className="work-sans me-3">Categories:</label>
                <label className="me-3">
                    <input
                        type="radio"
                        name="categories"
                        value="true"
                        checked={data.categories === "true"}
                        onChange={handleSelectChange}
                    /> Show
                </label>
                <label className="me-3">
                    <input
                        type="radio"
                        name="categories"
                        value="false"
                        checked={data.categories === "false"}
                        onChange={handleSelectChange}
                    /> Hide
                </label>
            </div>

            <div className="mb-3">
                <label className="work-sans me-3">Single Product:</label>
                <label className="me-3">
                    <input
                        type="radio"
                        name="singleProduct"
                        value="true"
                        checked={data.singleProduct === "true"}
                        onChange={handleSelectChange}
                    /> Show
                </label>
                <label className="me-3">
                    <input
                        type="radio"
                        name="singleProduct"
                        value="false"
                        checked={data.singleProduct === "false"}
                        onChange={handleSelectChange}
                    /> Hide
                </label>
            </div>

            {/* Promo Code Dropdown */}
            <select
                value={selectedPromoId}
                onChange={handlePromoSelect}
                className={`${css.adminInput} work-sans my-2`}
            >
                <option value="">Select Promo Code</option>
                {promo && promo.map((item) => (
                    <option key={item._id} value={item._id}>
                        {item.code}
                    </option>
                ))}
            </select>

            {message && <p className='text-center mt-3 text-danger fw-bold'>{message}</p>}

            {/* buttons */}
            <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="position-absolute" style={{ bottom: "6%" }}>
                    <button
                        className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`}
                        onClick={() => setVisibleNavPoup(false)}
                    >
                        <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Cancel
                    </button>
                    <button className={`${css.btnBoth} text-white`} onClick={Submit}>
                        <svg width="25" className="me-2" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Submit
                        {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                    </button>
                </div>
            </div>
        </nav>
    );
}
