import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";
import axiosInstance from "../api/axiosInstance";

export const productContext = createContext(null);

export default function ProductProvider({ children }) {
  const { userGlobalInfo, isUser } = useContext(UserContext);
  const [product, setProduct] = useState({});
  const [cartCount, setCartCount] = useState();
  const [iconCount, setIconCount] = useState();

  const getaproduct = async () => {
    try {
      const { data } = await axios.get("https://api.yafa-brand.com/product");
      setProduct(data);
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const getSingelProduct = async (productId) => {
    try {
      const { data } = await axios.get(
        `https://api.yafa-brand.com/product/${productId}`
      );
      return data;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const createaproduct = async (Newaproduct) => {
    const formData = new FormData();
    Object.keys(Newaproduct).forEach((key) => {
      if (Array.isArray(Newaproduct[key])) {
        // For multiple files in `productImages`
        Newaproduct[key].forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, Newaproduct[key]);
      }
    });

    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/product",
        formData,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const AddVariant = async (NewaVariant, productId) => {
    const formData = new FormData();
    Object.keys(NewaVariant).forEach((key) => {
      if (Array.isArray(NewaVariant[key])) {
        // For multiple files in `productImages`
        NewaVariant[key].forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, NewaVariant[key]);
      }
    });

    try {
      const response = await axios.post(
        `https://api.yafa-brand.com/product/${productId}/variant  `,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const editproduct = async (Newaproduct) => {
    const formData = new FormData();
    Object.keys(Newaproduct).forEach((key) => {
      if (Array.isArray(Newaproduct[key])) {
        // For multiple files in `productImages`
        Newaproduct[key].forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, Newaproduct[key]);
      }
    });

    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/aproduct",
        formData,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const deleteAproduct = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.yafa-brand.com/aproduct/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };
  const deleteVariant = async (productId, variantId) => {
    try {
      const response = await axios.delete(
        `https://api.yafa-brand.com/product/${productId}/variant/${variantId}`,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };
  const updateproduct = async (Newaproduct, id) => {
    const formData = new FormData();
    Object.keys(Newaproduct).forEach((key) => {
      if (Array.isArray(Newaproduct[key])) {
        // For multiple files in `productImages`
        Newaproduct[key].forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, Newaproduct[key]);
      }
    });

    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/product/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const handelAddFav = async (icon, productId, varId) => {
    icon.classList.toggle("fa-solid");
    icon.classList.toggle("fa-regular");
    try {
      if (isUser === true) {
        try {
          const req1 = await axiosInstance.get("/user/wishlist", {
            headers: {
              Authorization: `Bearer ${userGlobalInfo.token}`,
            },
          });

          const isFavorite = req1.data.wishlist.find(
            (product) => product.productId === productId
          )
            ? true
            : false;

          if (isFavorite) {
            const products = req1?.data?.wishlist?.filter(
              (pro) => pro.productId !== productId
            );

            let req = await axiosInstance.patch(
              "/user/wishlist",
              {
                wishlist: [...products],
              },
              {
                headers: {
                  Authorization: `Bearer ${userGlobalInfo.token}`,
                },
              }
            );
            setIconCount(req.data.wishlist.length);
            window.location.reload();
          } else {
            const product = {
              productId: productId,
              variantId: varId,
            };
            const currentProducts = req1.data.wishlist;
            currentProducts.push(product);
            let req = await axiosInstance.patch(
              "/user/wishlist",
              {
                wishlist: currentProducts,
              },
              {
                headers: {
                  Authorization: `Bearer ${userGlobalInfo.token}`,
                },
              }
            );
            setIconCount(req.data.wishlist.length);
          }
        } catch (e) {
          console.log(e);
        }
      } else if (isUser === false) {
        const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
        const isFavorite = favorites.find(
          (product) => product.productId === productId
        )
          ? true
          : false;
        icon.classList.toggle("fa-solid", !isFavorite);
        icon.classList.toggle("fa-regular", isFavorite);

        if (isFavorite) {
          const products = favorites.filter(
            (pro) => pro.productId !== productId
          );
          localStorage.setItem("favorites", JSON.stringify(products));
          setIconCount((prev) => prev - 1);
          document
            ?.querySelector(".favoriteSection")
            ?.addEventListener("click", (e) => {
              if (
                e.target.tagName.toUpperCase() === "I" ||
                (e?.target?.getAttribute("class")?.includes("addToFav") &&
                  window.location.href === "/favorites")
              ) {
                window.location.reload();
              }
            });
        } else {
          const product = {
            productId: productId,
            variantId: varId,
          };
          favorites.push(product);
          localStorage.setItem("favorites", JSON.stringify(favorites));
          setIconCount((prev) => prev + 1);
        }
      }
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  useEffect(() => {
    getaproduct();
  }, []);

  const getCartCount = async () => {
    const req = await axiosInstance.get(`/user/cart`, {
      headers: {
        Authorization: `Bearer ${userGlobalInfo.token}`,
      },
    });
    if (req.data.cart) {
      setCartCount(req.data.cart.length);
    } else {
      setCartCount(0);
    }
  };

  useEffect(() => {
    if (isUser === false) {
      const storedCart = localStorage.getItem("cartLis");
      const cartLis = storedCart ? JSON.parse(storedCart) : [];
      if (cartLis.length > 0) {
        setCartCount(cartLis.length);
      }
    } else if (isUser === true) {
      getCartCount();
    }
  }, [cartCount, isUser]);

  const createaVariante = async (Newaproduct, ids) => {
    const formData = new FormData();
    Object.keys(Newaproduct).forEach((key) => {
      if (Array.isArray(Newaproduct[key])) {
        // For multiple files in `productImages`
        Newaproduct[key].forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, Newaproduct[key]);
      }
    });

    try {
      const response = await axios.post(
        `https://api.yafa-brand.com/product/addStock/${ids.productId}/variant/${ids.variantId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const AddStockFunc = async (NewaStock, ids) => {
    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/product/addStock/${ids.productId}/variant/${ids.variantId}`,
        NewaStock,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch aproduct", error);
    }
  };

  const getCountOfWishList = async () => {
    if (isUser === true) {
      const req = await axiosInstance.get("/user/wishlist", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });

      setIconCount(req?.data?.wishlist?.length);
      console.log(req.data?.wishlist);
    } else if (isUser === false) {
      const storedFavorites = localStorage.getItem("favorites");
      const favoritesLis = storedFavorites ? JSON.parse(storedFavorites) : [];
      if (favoritesLis.length > 0) {
        setIconCount(favoritesLis.length);
      }
    }
  };

  useEffect(() => {
    getCountOfWishList();
  }, [iconCount, isUser]);

  return (
    <productContext.Provider
      value={{
        product,
        createaproduct,
        deleteAproduct,
        updateproduct,
        editproduct,
        deleteVariant,
        getSingelProduct,
        cartCount,
        iconCount,
        AddVariant,
        setIconCount,
        setCartCount,
        handelAddFav,
        createaVariante, //laptop
        AddStockFunc, //laptop
      }}
    >
      {children}
    </productContext.Provider>
  );
}
