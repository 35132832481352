import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import YoumayAlsoLike from "./YoumayAlsoLike";
import css from "./style.module.css";
import "./styles/productDetailStyle.scss";
import axiosInstance from "../../api/axiosInstance";
import support1 from "../../Images/box.truck.fill.svg";
import support2 from "../../Images/repeat.svg";
import support3 from "../../Images/headphones.svg";
import support4 from "../../Images/shield.lefthalf.filled.svg";
import "swiper/css";
import "swiper/css/navigation";
import { productContext } from "../../Context/product";
import { toast, ToastContainer } from "react-toastify";
import Stories from "./ui/stories/Stories";
import Loding from "../Loding/Loding";
import $ from "jquery";
import { UserContext } from "../../Context/UserInof";
import { body } from "framer-motion/client";
export default function ProductDetails() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [allData, setAllData] = useState(null);
  const [variants, setVariants] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [viewPhotoSlider, setViewPhotoSlider] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [avaliableColors, setAvaliableColors] = useState([]);
  const [avaliableSizes, setAvaliableSizes] = useState([]);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isFavorites, setIsFavorites] = useState(false);
  const [isLoding, setIsLoding] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    color: "",
    size: "",
    quantity: 1,
  });
  const notify = () => toast("Item added to cart!");

  const { isUser, userGlobalInfo } = useContext(UserContext);
  const { setCartCount, handelAddFav } = useContext(productContext);
  const size = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
  const Support = [
    { icon: support1, text: "Safe Shipping" },
    { icon: support2, text: "No Returns" },
    { icon: support3, text: "Support 24*7" },
    { icon: support4, text: "Secure Payments" },
  ];
  const data = ["Description", "Size Chart", "Write a review"];
  const navigate = useNavigate();

  const fetchProduct = async () => {
    try {
      setIsLoding(true);
      const response = await axiosInstance.get(`/product/${productId}`);
      setAllData(response?.data);
      setProduct(response?.data?.product);
      setVariants(response?.data?.product?.variants);
      initializeColors(response?.data?.product.variants);
      setAvaliableSizes(
        removeDuplicates(
          response?.data?.product?.variants.map((S) => {
            return S.size;
          })
        )
      );
    } catch (error) {
      if (error?.response?.status === 404) {
        navigate("/404");
      }
      console.error("Error fetching product data:", error);
    } finally {
      setIsLoding(false);
    }
  };

  useEffect(
    () => {
      console.log(avaliableSizes);
      console.log(avaliableColors);
    },
    { avaliableSizes, avaliableColors }
  );

  const detailsSection = useRef();

  const removeDuplicates = (array) => {
    const uniqueSet = new Set(array);
    return [...uniqueSet];
  };

  const initializeColors = (data) => {
    const colors = data.map((variant) => variant.color.hex);
    setAvaliableColors(removeDuplicates(colors));
  };

  const toggleOpenIndex = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const handleAddToCart = async () => {
    if (selectedOptions.size === "" || selectedOptions.color === "") {
      setErrorMessage("Please select color and size");
      $(detailsSection.current).slideToggle();
      return "Please select color and size";
    }
    setErrorMessage("");

    const finalSelectedVariant = variants?.filter(
      (variant) =>
        variant?.color?.hex === selectedOptions?.color &&
        variant?.size === selectedOptions?.size
    );
    setSelectedVariant(finalSelectedVariant[0]);
    const cartItem = {
      productId: productId,
      variantId: finalSelectedVariant[0]._id,
      quantity: quantity,
    };
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "AddToCart");
    }
    if (isUser === true) {
      try {
        const req1 = await axiosInstance.get("/user/cart", {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        });
        const cartItems = req1?.data?.cart;
        cartItems.push(cartItem);
        await axiosInstance.patch(
          "/user/cart",
          { cart: cartItems },
          {
            headers: {
              Authorization: `Bearer ${userGlobalInfo.token}`,
            },
          }
        );
        setCartCount((prev) => prev + 1);
        $(detailsSection.current).slideToggle();
        notify();
      } catch (e) {
        console.log(e);
      }
    } else if (isUser === false) {
      console.log("🚀 ~ handleAddToCart ~ localStorage.getItem(cartLis):", localStorage.getItem("cartLis"))
      const existingCart = JSON.parse(localStorage.getItem("cartLis")) || [];
      const existingItemIndex = existingCart.findIndex(
        (item) => item.variantId === cartItem.variantId
      );
      if (existingItemIndex !== -1) {
        existingCart[existingItemIndex].quantity += selectedOptions.quantity;
        existingCart[existingItemIndex].selectedOptions = selectedOptions;
      } else {
        existingCart.push(cartItem);
      }
      localStorage.setItem("cartLis", JSON.stringify(existingCart));
      setCartCount((prev) => prev + 1);

      notify();
    }
  };

  const handelChangeColor = (value) => {
    setSelectedColor(value);
    setSelectedOptions((prev) => ({ ...prev, color: value }));
    setSelectedSize("");
    const selected = product?.variants?.filter((v) => v.color.hex === value);
    setAvaliableSizes(selected.map((s) => s.size));
  };

  const handelChangeSize = (value) => {
    setSelectedSize(value);
    setSelectedOptions((prev) => ({ ...prev, size: value }));
  };

  const handelChangeQuantity = (value) => {
    switch (value) {
      case "-":
        if (quantity > 0) {
          setQuantity((prevValue) => prevValue - 1);
        }
        break;
      case "+":
        setQuantity((prevValue) => prevValue + 1);
        break;
      default:
        return;
    }
  };

  const handelOpenBottomSec = (e) => {
    $(detailsSection.current).slideToggle();
    e.target.classList.toggle("fa-angle-up");
    e.target.classList.toggle("fa-angle-down");
  };

  const handelIsFavorite = async () => {
    if (isUser) {
      const req = await axiosInstance.get("/user/wishlist", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });

      const favorite = req.data.wishlist.find((p) => p.productId === productId);
      const isFavorite = favorite ? true : false;
      setIsFavorites(isFavorite);
    } else {
      const favoriteProducts =
        JSON.parse(localStorage.getItem("favorites")) || [];
      const favorite = favoriteProducts.find((p) => p.productId === productId);
      const isFavorite = favorite ? true : false;
      setIsFavorites(isFavorite);
    }
  };

  useEffect(() => {
    fetchProduct();
    handelIsFavorite();
  }, [productId]);

  useEffect(() => {
    setSelectedOptions((prev) => ({ ...prev, quantity: quantity }));
  }, [quantity]);

  useEffect(() => {
    if (product?.variants[0].color) {
      handelChangeColor(product?.variants[0]?.color?.hex);
    }
  }, [product]);

  return (
    <>
      {isLoding ? (
        <Loding />
      ) : (
        <>
          <section className="productDetailsSec mt-5 web">
            <div className="leftSide">
              <div className="imgSec">
                <div
                  className="viweAll"
                  onClick={() => setViewPhotoSlider(true)}
                >
                  <i class="fa-solid fa-photo-film"></i>
                </div>
                {product?.images?.slice(0, 5).map((img, index) => (
                  <>
                    <img src={img} alt="" />
                  </>
                ))}
              </div>
            </div>
            <div className="rightSide ">
              <div>
                <p
                  className="Archivo fw-bold mb-0"
                  style={{ fontSize: "25px", lineHeight: "27.2px" }}
                >
                  {product?.name}
                </p>
                <p
                  className=" nunito mb-0 "
                  style={{
                    fontSize: "18px",
                    lineHeight: "24.55px",
                    margin: "10px 0px",
                  }}
                >
                  {product?.subCategory?.name}
                </p>
                <p
                  className="nunito fw-bold"
                  style={{
                    fontSize: "18px",
                    lineHeight: "24.55px",
                    margin: "10px 0px",
                  }}
                >
                   <span  className={product?.variants[0].is_on_sale&&`m-0 archivo ${css.strikethrough}`}style={{fontSize:"12", color:!product?.variants[0].is_on_sale&& "#8B442A" }}>{product?.price}L.E.</span>
        {product?.variants[0].is_on_sale&&(<span style={{fontSize:"12", paddingLeft:"10px",color: "#8B442A" }}>{product?.price-((product?.variants[0].sale_percentage*product?.price)/100)} </span>)}
      
                </p>
              </div>
              {avaliableColors.length > 0 && avaliableSizes.length > 0 ? (
                <>
                  <div className="colorSelect">
                    <div className="title">Colors : </div>
                    {/* <div className="avalColors">
                      {avaliableColors?.map((color, index) => {
                        return (
                          <>
                            {color && (
                              <span
                                key={index}
                                className={`color ${selectedColor === color && "selected"
                                  }`}
                                style={{
                                  backgroundColor: `${color}`,
                                  outlineColor: `${color}`,
                                }}
                                value={color}
                                onClick={() => handelChangeColor(color)}
                              ></span>
                            )}
                          </>
                        );
                      })}
                    </div> */}
                  </div>
                  <div className="sizeSec">
                    <div className="sizeList">
                      {size.map((size, index) => {
                        return (
                          <div
                            className={`sizeOption ${selectedSize === size && "activeSize"
                              } ${!avaliableSizes?.includes(size) &&
                              "notAvailableSize"
                              }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (!avaliableSizes?.includes(size)) return;

                              handelChangeSize(size);
                            }}
                          >
                            {size}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <span className="outOfStock">Currently unavailable</span>
                </>
              )}

              <div className="mt-4">
                <p className={` ${css.color} text-dark`}>
                  Pieces Available :
                  <span
                    className={` ${css.color} mx-2`}
                    style={{
                      color: `${product?.variants[0].stock < 10 && "rgb(128, 7, 7)"
                        }`,
                    }}
                  >
                    {product?.variants[0].stock}
                  </span>
                </p>
                <div className="addToCartSec">
                  <div className="quantitySec">
                    <span onClick={() => handelChangeQuantity("+")}>
                      <i class="fa-solid fa-plus"></i>
                    </span>
                    <input type="number" min={1} value={quantity} readOnly />
                    <span onClick={() => handelChangeQuantity("-")}>
                      <i class="fa-solid fa-minus"></i>
                    </span>
                  </div>
                  <div className="addCartBtn">

                    <div
                      className="cartBtn"
                      onClick={(e) => handleAddToCart(e)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="mb-0">
                      <span  className={product?.variants[0].is_on_sale&&`m-0 archivo ${css.strikethrough}`}style={{fontSize:"12", color:!product?.variants[0].is_on_sale&& "#8B442A" }}>{product?.price * quantity}L.E.</span>
        {product?.variants[0].is_on_sale&&(<span style={{fontSize:"12", paddingLeft:"10px",color: "#8B442A" }}>{(product?.price-((product?.variants[0].sale_percentage*product?.price)/100)) * quantity} </span>)}
      
                        <i className="fas fa-circle text-white mx-2 fa-1"></i>
                        Add to Cart
                      </p>
                    </div>


                  </div>
                  <div className="addToWashList">
                    <i
                      class={` ${isFavorites ? "fa-solid" : " fa-regular"
                        } fa-heart`}
                      onClick={(e) => {
                        handelAddFav(
                          e.target,
                          product._id,
                          product.variants[0]._id
                        );
                      }}
                    ></i>
                  </div>
                </div>
                {errorMessage && <p className=" " style={{ color: "red",position:"absolute", left:"40%" ,transform:"translateX(-50%)" } }>{errorMessage}</p>}
              </div>
              <div className="mt-4">
                <div className="row align-items-center g-3">
                  {Support.map((e, index) => (
                    <div className="col-md-3">
                      <div key={index} className={`${css.support} d-flex `}>
                        <img src={e.icon} alt="" />
                        <p className={`mb-0  ${css.supportFont}`}> {e.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <div className="row">
                  {Array(1)
                    .fill(null)
                    .map((_, firstIndex) => (
                      <div
                        key={firstIndex}
                        className={`${css.permissionGroupsProp} bg-white my-2 cursor `}
                        onClick={() => toggleOpenIndex(firstIndex)} // Toggle visibility
                      >
                        <div className="d-flex justify-content-between align-items-center ">
                          <p className={`nunito  m-0  fw-semibold`}>
                            {data[firstIndex]}
                          </p>
                          {openIndexes.includes(firstIndex) ? (
                            <i className="fa-solid fa-angle-down"></i>
                          ) : (
                            <i className="fa-solid fa-angle-up"></i>
                          )}
                        </div>
                        <div
                          className="slidingContentAnimate "
                          style={{
                            padding: "10px",
                            maxHeight: openIndexes.includes(firstIndex)
                              ? "200px"
                              : "0px", // Adjust maxHeight for animation
                            opacity: openIndexes.includes(firstIndex) ? 1 : 0,
                            transition:
                              "max-height 0.4s ease, opacity 0.4s ease", // Smooth transition
                            overflow: "hidden", // Prevent overflow
                          }}
                        >
                          {product?.description}
                          <div style={{ height: "30px" }}></div>
                        </div>
                      </div>
                    ))}
                </div>
                {allData?.productsYouMayLike.length > 0 && (
                  <div className="youMayLike">
                    <YoumayAlsoLike
                      data={allData?.productsYouMayLike}
                      breakpoints={{
                        500: {
                          slidesPerView: 3,
                        },
                        640: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                      }}
                    />
                  </div>
                )}
                <Stories />
              </div>
            </div>
            {viewPhotoSlider && (
              <>
                <div className="allPhotoSlider">
                  <div
                    className="closeIcon"
                    onClick={() => setViewPhotoSlider(false)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="slideContainer">
                    <Swiper
                      spaceBetween={10}
                      grabCursor={true}
                      slidesPerView={1}
                      navigation={true}
                      modules={[Navigation]}
                      loop={true}
                      pagination={{ clickable: true }}
                    >
                      {product?.images?.map((img, index) => (
                        <SwiperSlide key={index}>
                          <img src={img} alt="" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </>
            )}

            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </section>
          <section className="productDetailsSec mt-5 mobileView">
            <div className="leftSide">
              {product?.images?.slice(0, 5).map((img, index) => (
                <>
                  <img src={img} alt="" />
                </>
              ))}
            </div>

            <div className={`rightSide `}>
              <div className="topSec">
                <div className="openIcon">
                  <i
                    className="fa-solid fa-angle-up"
                    onClick={handelOpenBottomSec}
                  ></i>
                </div>
                <div>
                  <p
                    className="Archivo fw-bold mb-0 productName"
                    style={{ fontSize: "25px", lineHeight: "27.2px" }}
                  >
                    {product?.name}
                  </p>
                  <div className="infoPrice">
                    <p
                      className=" nunito mb-0 "
                      style={{
                        fontSize: "18px",
                        lineHeight: "24.55px",
                        margin: "10px 0px",
                      }}
                    >
                      {product?.subCategory?.name}
                    </p>
                    <p
                      className="nunito fw-bold"
                      style={{
                        fontSize: "18px",
                        lineHeight: "24.55px",
                        margin: "10px 0px",
                      }}
                    >
                      <span  className={product?.variants[0].is_on_sale&&`m-0 archivo ${css.strikethrough}`}style={{fontSize:"12", color:!product?.variants[0].is_on_sale&& "#8B442A" }}>{product?.price}L.E.</span>
        {product?.variants[0].is_on_sale&&(<span style={{fontSize:"12", paddingLeft:"10px",color: "#8B442A" }}>{product?.price-((product?.variants[0].sale_percentage*product?.price)/100)} </span>)}
      
                    </p>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="addToCartSec">
                    <div className="addCartBtn">
                      <div
                        className="cartBtn"
                        onClick={handleAddToCart}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="mb-0">
                        <span  className={product?.variants[0].is_on_sale&&`m-0 archivo ${css.strikethrough}`}style={{fontSize:"12", color:!product?.variants[0].is_on_sale&& "#8B442A" }}>{product?.price}L.E.</span>
        {product?.variants[0].is_on_sale&&(<span style={{fontSize:"12", paddingLeft:"10px",color: "#8B442A" }}>{product?.price-((product?.variants[0].sale_percentage*product?.price)/100)} </span>)}
      
                          <i className="fas fa-circle text-white mx-2 fa-1"></i>
                          Add to Cart
                        </p>
                      </div>
                    </div>
                    <div className="addToWashList">
                      <i
                        class={` ${isFavorites ? "fa-solid" : " fa-regular"
                          } fa-heart`}
                        onClick={(e) =>
                          handelAddFav(
                            e.target,
                            product._id,
                            product.variants[0]._id
                          )
                        }
                      ></i>
                    </div>
                  </div>
                </div>
                {errorMessage && <p className=" " style={{ color: "red" } }>{errorMessage}</p>}
              </div>
              <div className="bottomSec" ref={detailsSection}>
                <div>
                  <div className="colorSelect">
                    <div className="avalColors">
                      {avaliableColors?.map((color, index) => {
                        return (
                          <>
                            {color && (
                              <span
                                key={index}
                                className={`color ${selectedColor === color && "selected"
                                  }`}
                                style={{
                                  backgroundColor: `${color}`,
                                  outlineColor: `${color}`,
                                }}
                                value={color}
                                onClick={() => handelChangeColor(color)}
                              ></span>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="sizeSec">
                    <div className="sizeList">
                      {size.map((size, index) => {
                        return (
                          <div
                            className={`sizeOption ${selectedSize === size && "activeSize"
                              } ${!avaliableSizes?.includes(size) &&
                              "notAvailableSize"
                              }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (!avaliableSizes?.includes(size)) return;

                              handelChangeSize(size);
                            }}
                          >
                            {size}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="quantity">
                    <p className={` ${css.color} text-dark`}>
                      Pieces Available :
                      <span
                        className={` ${css.color} mx-2`}
                        style={{
                          color: `${product?.variants[0].stock < 10 && "rgb(128, 7, 7)"
                            }`,
                        }}
                      >
                        {product?.variants[0].stock}
                      </span>
                    </p>
                    <div className="quantitySec">
                      <span onClick={() => handelChangeQuantity("+")}>
                        <i class="fa-solid fa-plus"></i>
                      </span>
                      <input type="number" min={1} value={quantity} readOnly />
                      <span onClick={() => handelChangeQuantity("-")}>
                        <i class="fa-solid fa-minus"></i>
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    {allData?.productsYouMayLike.length > 0 && (
                      <div className="youMayLike">
                        <YoumayAlsoLike
                          data={allData?.productsYouMayLike}
                          breakpoints={{
                            300: {
                              slidesPerView: 3,
                            },
                            500: {
                              slidesPerView: 3,
                            },
                            640: {
                              slidesPerView: 3,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            1024: {
                              slidesPerView: 3,
                            },
                          }}
                        />
                      </div>
                    )}
                    <Stories />
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </section>
        </>
      )}
    </>
  );
}
