import React, { useContext, useState, useEffect } from "react"
import css from "../style.module.css"
import Search from "../Search"
import BarChart from "../Charts/BarChart"
import PieChart from "../Charts/PieChart"
import axios from "axios"

import { UserContext } from "../../../Context/UserInof"

export default function Dashboard() {
  const { userGlobalInfo } = useContext(UserContext)

  const percentageArr = [
    { field: "Total Users", result: "12.9K", percentage: 50, color: "#584A2F" },
    {
      field: "Total Revenue",
      result: "700,825 EGP",
      percentage: 75,
      color: "#8B693F",
    },
    {
      field: "Total Products",
      result: "15.5K",
      percentage: 50,
      color: "#B0B9BF",
    },
    {
      field: "Total Orders",
      result: "11.4K",
      percentage: 75,
      color: "#8B442A",
    },
  ]
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [error, setError] = useState()
  const [dashboardDetails, setDashboardDetails] = useState("")
  const [revenue, setRevenue] = useState([])
  useEffect(() => {
    axios
      .get(
        `https://api.yafa-brand.com/dashboard/dashboard`,

        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        setDashboardDetails(res?.data)
      })
      .catch((e) => {
        // console.log(e)
        setError("Something went wrong")
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
    axios
      .get(
        `https://api.yafa-brand.com/dashboard/revenue`,

        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        const result = []
        res.data.bestSellingProductsDetails.map((one) => {
          result.push({
            name: one.productName,
            value: one.totalQuantity * one.productPrice,
          })
        })
        console.log(result)
        setRevenue(result)
      })
      .catch((e) => {
        // console.log(e)
        setError("Something went wrong")
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }, [])
  return (
    <div className="flex-grow-1 " style={{ transition: "margin-left 0.3s" }}>
      <div>
        <Search />
        <div className="fadeInAnimate">
          <p className={`${css.pad} ${css.textProp} inter m-0`}>Dashboard</p>
          {/* <p className={`${css.dateProp}`}>Friday, December 15th 2023</p> */}
          <div className="row mb-5">
            <div className="col-md-3">
              <div
                className={`${css.percentageBox} d-flex justify-content-between align-items-center`}
              >
                <div className="lato">
                  <p className={`m-0 ${css.field} ${css.fieldColor}`}>
                    {dashboardDetails.totalUsers}
                  </p>
                  <p
                    className={`m-0 fw-bold ${css.results} ${css.resultsColor}`}
                  >
                    {"Total Users"}
                  </p>
                </div>
                <div className={`${css.percentageContainer}`}>
                  <svg
                    className={`${css.percentageCircle}`}
                    viewBox="-2 -2 40 40"
                  >
                    <path
                      className={`${css.circleBackground}`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                    />
                    <path
                      className={`${css.circleProgress}`}
                      strokeDasharray={`${dashboardDetails.totalUsers}, 100`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                      style={{ stroke: "#8B693F" }}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className={`${css.percentageBox} d-flex justify-content-between align-items-center`}
              >
                <div className="lato">
                  <p className={`m-0 ${css.field} ${css.fieldColor}`}>
                    {dashboardDetails.totalRevenue}
                  </p>
                  <p
                    className={`m-0 fw-bold ${css.results} ${css.resultsColor}`}
                  >
                    {"Total Revenue"}
                  </p>
                </div>
                <div className={`${css.percentageContainer}`}>
                  <svg
                    className={`${css.percentageCircle}`}
                    viewBox="-2 -2 40 40"
                  >
                    <path
                      className={`${css.circleBackground}`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                    />
                    <path
                      className={`${css.circleProgress}`}
                      strokeDasharray={`${
                        (dashboardDetails.totalRevenue / 1000000) * 100
                      }, 100`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                      style={{ stroke: "#8B693F" }}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className={`${css.percentageBox} d-flex justify-content-between align-items-center`}
              >
                <div className="lato">
                  <p className={`m-0 ${css.field} ${css.fieldColor}`}>
                    {dashboardDetails.totalOrders}
                  </p>
                  <p
                    className={`m-0 fw-bold ${css.results} ${css.resultsColor}`}
                  >
                    {"Total Orders"}
                  </p>
                </div>
                <div className={`${css.percentageContainer}`}>
                  <svg
                    className={`${css.percentageCircle}`}
                    viewBox="-2 -2 40 40"
                  >
                    <path
                      className={`${css.circleBackground}`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                    />
                    <path
                      className={`${css.circleProgress}`}
                      strokeDasharray={`${
                        (dashboardDetails.totalOrders / 100) * 100
                      }, 100`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                      style={{ stroke: "#8B693F" }}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className={`${css.percentageBox} d-flex justify-content-between align-items-center`}
              >
                <div className="lato">
                  <p className={`m-0 ${css.field} ${css.fieldColor}`}>
                    {dashboardDetails.totalProducts}
                  </p>
                  <p
                    className={`m-0 fw-bold ${css.results} ${css.resultsColor}`}
                  >
                    {"Total Products"}
                  </p>
                </div>
                <div className={`${css.percentageContainer}`}>
                  <svg
                    className={`${css.percentageCircle}`}
                    viewBox="-2 -2 40 40"
                  >
                    <path
                      className={`${css.circleBackground}`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                    />
                    <path
                      className={`${css.circleProgress}`}
                      strokeDasharray={`${
                        (dashboardDetails.totalProducts / 100) * 1000
                      }, 100`}
                      d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                      style={{ stroke: "#8B693F" }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {/* BarChart */}
          <div className="d-flex justify-content-center align-items-center ">
            <div  className=" me-3">
              {/* <BarChart dashboardDetails={revenue} /> */}
            </div>
            <div  className="">
              <PieChart revenue={revenue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
