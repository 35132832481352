import React from "react"
import car from "../../Images/Group 19.svg"
import safe from "../../Images/Group 20.svg"
import crafted from "../../Images/footerIcon.svg"
import callUs from "../../Images/Frame 23.svg"
import visa from "../../Images/Visa.svg"
import mastercard from "../../Images/Mastercard.svg"
import PayPal from "../../Images/PayPal.svg"
import gPay from "../../Images/GooglePay.svg"
import appelPay from "../../Images/ApplePay.svg"
import css from "./style.module.css"
import { NavLink } from "react-router-dom"

export default function Footer() {
  const footerImage = [
    {
      icon: car,
      text: "Free shipping on all orders above 9000 L.E",
    },
    { icon: safe, text: "We ensure secure payment with PayMob" },
    { icon: crafted, text: "Crafted from top materials" },
    { icon: callUs, text: "Contact us 24 hours a day, 7 days a week" },
  ]

  const paymentMethod = [visa, mastercard, PayPal, gPay, appelPay]
  return (
    <section className={`'bg-info ${css.mtop105}`}>
      <div className="container">
        <div className={` ${css.footerStyle}`}>
          {footerImage.map((e, index) => (
            <div key={index} className={` ${css.box}`}>
              <img src={e.icon} alt="" />
              <p className="archivo m-0 ms-2 fst-italic text-center text-white mobileFont">
                {e.text}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={css.secFooter}>
        <div className="container">
          <div className={`${css.footer} `}>
            {/* <div className={`${css.footerBox} `}>
              <div>
                <p className={css.fotterHeader}>Iconic Fashion</p> */}
            {/* <p>Best Sellers</p> */}
            {/* <NavLink
                  to="/offers">
                  <p>Offers</p>
                </NavLink> */}

            {/* <p>Shopping</p> */}
            {/* </div>
            </div> */}

            <div className={`${css.footerBox} `}>
              <div>
                <p className={css.fotterHeader}>Information</p>
                <NavLink to="/favorites">
                  <p>Favorites</p>
                </NavLink>
                <NavLink to="/stores">
                  <p>Stores</p>
                </NavLink>
                <NavLink to="/about">
                  <p>About Us</p>
                </NavLink>

                {/* <p>Cookie Policy</p>
                <p>Terms Of Use</p> */}
                <NavLink to="/contactus">
                  <p>Contact Us</p>
                </NavLink>
                {/* <p>Returns</p> */}
              </div>
            </div>
            <div className={`${css.footerBox} `}>
              <div>
                <p className={`${css.fotterHeader} m-0`}>Follow Us</p>
                <div className="d-flex align-items-center my-2">
                  <a
                    href="/https://www.facebook.com/people/Yafa-Egy/61566974760521/"
                    target="_blank"
                  >
                    <div
                      className={`${css.socialMedia} ${css.socialMediaIcon} me-3  d-flex justify-content-center align-items-center`}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </div>
                  </a>
                  {/* <div
                    className={`${css.socialMedia} ${css.socialMediaIcon} me-3  d-flex justify-content-center align-items-center`}
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </div> */}
                  <a href="/https://www.instagram.com/yafa_eg/" target="_blank">
                    <div
                      className={`${css.socialMedia} ${css.socialMediaIcon} me-3  d-flex justify-content-center align-items-center`}
                    >
                      <i className="fab fa-instagram-square"></i>
                    </div>
                  </a>

                  {/* <div
                    className={`${css.socialMedia} ${css.socialMediaIcon} me-3  d-flex justify-content-center align-items-center`}
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </div>
                  <div
                    className={`${css.socialMedia} ${css.socialMediaIcon} me-3  d-flex justify-content-center align-items-center`}
                  >
                    <i className="fa-brands fa-pinterest"></i>
                  </div>
                  <div
                    className={`${css.socialMedia} ${css.socialMediaIcon} me-3  d-flex justify-content-center align-items-center`}
                  >
                    <i className="fa-brands fa-tiktok"></i>
                  </div> */}
                </div>
                <p>Language</p>
                <p>Arabic | English</p>
                <p>Secure payment guaranteed</p>
                <div className="d-flex mt-2">
                  {paymentMethod.map((e, index) => (
                    <div key={index} className="me-3">
                      <img src={e} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
