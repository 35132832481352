import React, { useContext, useState, useEffect } from 'react';
import css from "../../style.module.css";
import { storeContext } from '../../../../Context/Stores';

export default function StoreEdition({ setVisibleEdition, editStore }) {
    const { updatestore } = useContext(storeContext);
    console.log(editStore);

    // Initialize state with an empty object
    const [data, setData] = useState({});
    const [dataEdittion, setDataEdittion] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Effect to set initial values from editStore
    useEffect(() => {
        setData(editStore);
    }, [editStore]);

    const getCountryInfo = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        
        // Update both data and dataEdittion to reflect input changes
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setDataEdittion(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const Submit = async (id) => {
      
        
        setIsLoading(true);
        try {
            const {data,status} = await updatestore(dataEdittion, id); // Send the updated data
            if (status === 200) {
                setIsLoading(false);                
            }
            console.log(data);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            console.log(error.message || "error");
        }
    };
    return (
        <nav className={`${css.sideNavDesign} mb-5`} style={{ height: "98%", position: "relative" }}>
            <p className={`inter ${css.textHeader}`}>Edit Store</p>
            <input 
                type="text" 
                name='name' 
                value={data.name || ''} // Use data state for value
                className={`${css.adminInput} work-sans my-2`} 
                placeholder='Store Name' 
                onChange={getCountryInfo} 
            />
            <input 
                type="text" 
                name='location' 
                value={data.location || ''} // Use data state for value
                className={`${css.adminInput} work-sans my-2`} 
                placeholder='Location' 
                onChange={getCountryInfo} 
            />
          
            {/* buttons */}
            <div className="d-flex justify-content-center align-items-center">
                <div className="position-absolute" style={{ bottom: "6%" }}>
                    <button
                        className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`}
                        onClick={() => setVisibleEdition(false)}>
                        <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Cancel
                    </button>
                    <button className={`${css.btnBoth} text-white`} onClick={() => Submit(editStore._id)}>
                        <svg width="25" className="me-2" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Submit
                        {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                    </button>
                </div>
            </div>
        </nav>
    );
}

