
import React, { useContext, useEffect, useState } from 'react'
import css from '../style.module.css';
import Search from '../Search';
import AdminNav from '../../Navs/SideNav/AdminNav';
import { PopupContext } from '../../../Context/Popup';
import { getFormattedDate } from '../../dateFuc';
import PopupNav from '../../Navs/SideNav/PopupNav';
export default function Popup() {
    const { popup, subscribers, } = useContext(PopupContext);

    const [popUpData, setPopUpData] = useState([]);
    const [subscribersData, setSubscribersData] = useState([]);
    const [visibleNavPoup, setVisibleNavPoup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [openIndexes, setOpenIndexes] = useState([]);
    useEffect(() => {
        if (popup) {
            console.log("popup is: ", popup);
            setPopUpData([popup]);
        }
        if (popup) {
            console.log("subscribersData is: ", subscribersData);
            setSubscribersData(subscribersData);
            setIsLoading(false);
        }
        setIsLoading(false);
    }, [popup, subscribersData]);

    if (isLoading) {
        return (
            <p>
                Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
            </p>
        );
    }


    const toggleOpenIndex = (index) => {
        // If the index is already open, remove it from the array; otherwise, add it
        setOpenIndexes(prevIndexes =>
            prevIndexes.includes(index) ? prevIndexes.filter(i => i !== index) : [...prevIndexes, index]
        );
    };

    return (
        <>
            <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
                <Search />
                <div className='fadeInAnimate'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <p className={`${css.pad} ${css.textProp} inter m-0`}>Pop UP Promo code </p>
                            <p className={`${css.dateProp}`}>{getFormattedDate()}</p>
                        </div>
                        <button className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white  d-flex justify-content-center align-items-center border-0`} onClick={() => setVisibleNavPoup(true)}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 8.5C14 5.1875 11.3125 2.5 8 2.5C4.6875 2.5 2 5.1875 2 8.5C2 11.8125 4.6875 14.5 8 14.5C11.3125 14.5 14 11.8125 14 8.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                <path d="M8 6V11V6ZM10.5 8.5H5.5H10.5Z" fill="white" />
                                <path d="M8 6V11M10.5 8.5H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            ADD NEW
                        </button>
                    </div>
                    <div className="row">
                        {popUpData?.map((item, index) => (
                            <div
                                key={item._id}
                                className={`${css.permissionGroupsProp} bg-white my-2 cursor`}
                                onClick={() => toggleOpenIndex(index)}
                            >

                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className={`inter ${css.permissionGroupsText} m-0`}>{item?.promoCode?.code}</p>
                                    {openIndexes.includes(index) ? (
                                        <i className="fa-solid fa-angle-down"></i>
                                    ) : (
                                        <i className="fa-solid fa-angle-up"></i>
                                    )}
                                </div>
                                <div
                                    className='slidingContentAnimate'
                                    style={{
                                        maxHeight: openIndexes.includes(index) ? '250px' : '0px', // Adjust maxHeight for animation
                                        opacity: openIndexes.includes(index) ? 1 : 0,
                                        transition: 'max-height 0.4s ease, opacity 0.4s ease', // Smooth transition
                                        overflow: 'hidden' // Prevent overflow
                                    }}
                                >

                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6'>
                                            <p className={`inter ${css.mainText}`}>body <span className='fw-bold'>{item?.body}</span></p>
                                        </div>
                                    </div>
                                    <div className='row mt-3 '>
                                        
                                        <div className='col-12 col-md-3'>
                                            <p className={`inter ${css.mainText}`}>ExpirationDate <span className='fw-bold'>{item?.promoCode?.expirationDate}</span></p>
                                        </div>
                                        <div className='col-12 col-md-3 text-center'>
                                            <p className={`inter ${css.mainText}`}>Percentage <span className='fw-bold'>{item?.promoCode?.percentage}</span></p>
                                        </div>
                                        <div className='col-12 col-md-3 text-center'>
                                            <p className={`inter ${css.mainText}`}>MinAmount <span className='fw-bold'>{item?.promoCode?.minAmount}</span></p>
                                        </div>
                                        <div className='col-12 col-md-3 text-end pe-5'>
                                            <p className={`inter ${css.mainText}`}>MaxAmount <span className='fw-bold'>{item?.promoCode?.maxAmount}</span></p>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-4'>
                                            <p className={`inter ${css.mainText}`}>
                                                Homepage appear{"   "}
                                                <span className="fw-bold">
                                                    {item.homepage === true ? (
                                                        <span className="text-success fw-bold">Appeared in the home page</span>
                                                    ) : (
                                                        <span className="text-danger fw-bold">Not appear in the home page</span>
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <p className={`inter ${css.mainText}`}>
                                                Categories appear{" "}
                                                <span className={item.categories === true ? "text-success fw-bold" : "text-danger fw-bold"}>
                                                    {item.categories === true ? "Appeared in the categories pages" : "Not appear in the categories pages"}
                                                </span>
                                            </p>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <p className={`inter ${css.mainText}`}>
                                                SingleProduct appear{" "}
                                                <span className={item.singleProduct === true ? "text-success fw-bold" : "text-danger fw-bold"}>
                                                    {item.singleProduct === true ? "Appeared in the singleProduct pages" : "Not appear in the singleProduct pages"}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
            {/* SideBar */}
            {
                visibleNavPoup && (
                    <div className={`p-0 displayAnimation ${visibleNavPoup ? 'show' : 'hide'}`}>
                        <PopupNav setPopUpData={setPopUpData} setVisibleNavPoup={setVisibleNavPoup} />
                    </div>
                )
            }
        </>
    )
}
