import React, { useState } from 'react'
import css from './style.module.css';
import imgSwiper1 from '../../Images/high-fashion-look-glamor-closeup-portrait-beautiful-sexy-stylish-caucasian-young-woman-model.png';
import imgSwiper2 from '../../Images/pisces-portrait-beautiful-woman.png';
import imgSwiper3 from '../../Images/blonde-young-woman-elegant-green-dress.png';
import imgSwiper4 from '../../Images/portrait-woman-wearing-hat-close-up.png';
import theMostWanted1 from '../../Images/the most wanted1.png';
import theMostWanted2 from '../../Images/the most wanted2.png';
import theMostWanted3 from '../../Images/the most wanted3.png';
import theMostWanted4 from '../../Images/the most wanted4.png';
import theMostWanted5 from '../../Images/the most wanted5.png';
import theMostWanted6 from '../../Images/the most wanted6.png';

export default function Offers() {

    const [selectedIndices, setSelectedIndices] = useState([]);




    const handleSvgClick = (index) => {
        if (selectedIndices.includes(index)) {
            // Remove the index if it's already in the array (toggle off)
            setSelectedIndices(selectedIndices.filter(i => i !== index));
        } else {
            // Add the index if it's not in the array (toggle on)
            setSelectedIndices([...selectedIndices, index]);
        }
    };


    const images = [
        { imgs: imgSwiper1, text: "Product Name", price: "250 EGP" }, { imgs: imgSwiper2, text: "Product Name", price: "250 EGP" }, { imgs: imgSwiper3, text: "Product Name", price: "250 EGP" }, { imgs: imgSwiper4, text: "Product Name", price: "250 EGP" },
    ];
    const mostwanted = [
        { img: theMostWanted1, text: "Product Name", price: "250 EGP" }, { img: theMostWanted2, text: "Product Name", price: "250 EGP" },
        { img: theMostWanted3, text: "Product Name", price: "250 EGP" }, { img: theMostWanted4, text: "Product Name", price: "250 EGP" },
        { img: theMostWanted5, text: "Product Name", price: "250 EGP" }, { img: theMostWanted6, text: "Product Name", price: "250 EGP" },
    ];
    return (
        <section className='' style={{ marginTop: "120px" }}>
            <div className='container'>
                <div className="row">
                    <div className={``}>
                        <p className={`${css.categoryHeader} archivo `}>Offers</p>
                        <p className='nunito p-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12"> {/* Adjusted to col-md-12 for full width */}
                        <div className="d-flex flex-wrap"> {/* Use flex-wrap to wrap items */}
                            {images.map((item, index) => (
                                <div key={index} className="col-md-3 p-2"> {/* Each image occupies 3 columns */}
                                    <div className='d-flex w-100 pt-2 position-relative'>
                                        <img src={item.imgs} alt="japanesewoman" className='w-100' />
                                        <div
                                            className={`${css.svgBgGray} d-flex justify-content-center align-items-center`}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering parent click
                                                handleSvgClick(index);
                                            }}
                                            style={{ cursor: 'pointer' }} // Add cursor to indicate clickable div
                                        >
                                            <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill={selectedIndices.includes(index) ? "#BB0000" : "none"} // Toggle fill color
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.76066 15.5199L2.46528 8.96432C0.692056 7.11784 0.80372 4.09016 2.70772 2.39071C4.59662 0.704728 7.4607 1.03212 8.95338 3.10465L9.20434 3.45307L9.4553 3.10465C10.948 1.03212 13.812 0.704728 15.701 2.39071C17.605 4.09016 17.7167 7.11784 15.9434 8.96432L9.64802 15.5199C9.40298 15.775 9.0057 15.775 8.76066 15.5199Z"
                                                    stroke="#BB0000" // Keep stroke color as red always
                                                    fill={selectedIndices.includes(index) ? "#BB0000" : "none"} // Toggle fill color
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <div className="position-absolute text-center bottom-0 start-0 " style={{ background: "#BEAE99" }}>
                                            <p className='fw-bold m-0 archivo'>Sale 10%</p>
                                            <div className='d-flex justify-content-center'>
                                                <p className={`m-0 archivo ${css.strikethrough}`} style={{ color: " #D9D9D9" }} >1000 EGP</p>
                                                <p className='m-0 archivo ms-2' style={{ color: "#8B442A" }}>900 EGP</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className={`${css.category} text-center archivo`}>{item.text}</p>
                                        <p className={`${css.category} text-center archivo`}>{item.price}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row mb-4">
                            {mostwanted.map((e, index) => (
                                <div id='ProductImg' key={index} className="col-md-2">
                                    <div className='d-flex w-100 pt-2 position-relative cursor' > {/* Click event on the container */}
                                        <img src={e.img} alt="japanesewoman" className='w-100' />
                                        <div
                                            className={`${css.svgBgGray} d-flex justify-content-center align-items-center`}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering parent click
                                                handleSvgClick(index);
                                            }}
                                            style={{ cursor: 'pointer' }} // Add cursor to indicate clickable div
                                        >
                                            <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill={selectedIndices.includes(index) ? "#BB0000" : "none"} // Toggle fill color
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.76066 15.5199L2.46528 8.96432C0.692056 7.11784 0.80372 4.09016 2.70772 2.39071C4.59662 0.704728 7.4607 1.03212 8.95338 3.10465L9.20434 3.45307L9.4553 3.10465C10.948 1.03212 13.812 0.704728 15.701 2.39071C17.605 4.09016 17.7167 7.11784 15.9434 8.96432L9.64802 15.5199C9.40298 15.775 9.0057 15.775 8.76066 15.5199Z"
                                                    stroke="#BB0000" // Keep stroke color as red always
                                                    fill={selectedIndices.includes(index) ? "#BB0000" : "none"} // Toggle fill color
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <div className="position-absolute text-center bottom-0 start-0 " style={{ background: "#BEAE99" }}>
                                            <p className='fw-bold m-0 archivo '>Sale 10%</p>
                                            <div className='d-flex justify-content-center'>
                                                <p className={`m-0 archivo ${css.strikethrough}`} style={{ color: " #D9D9D9" }} >1000 EGP</p>
                                                <p className='m-0 archivo ms-2' style={{ color: "#8B442A" }}>900 EGP</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className={`${css.category} text-center archivo`}>{e.text}</p>
                                        <p className={`${css.category} text-center archivo `}>{e.price}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}
