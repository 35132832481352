import React, { useContext, useEffect, useRef, useState } from "react";
import css from "./style.module.css";
import image from "../../Images/young-beautiful-woman-standing-back-camera-grey-surface 1.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./styles/navBarStyle.scss";
import { productContext } from "../../Context/product";
import YafaLogo from "../../Images/yafa_logo.png";
import $ from "jquery";
import axiosInstance from "../../api/axiosInstance";
import { UserContext } from "../../Context/UserInof";

export default function Nav() {
  const navigat = useNavigate();
  const { cartCount, iconCount } = useContext(productContext);
  const [isVesible, setIsVesible] = useState(true);
  const { isUser, setCurrentForm, setUserGlobalInfo, userGlobalInfo } =
    useContext(UserContext);
  const [scrollY, setScrollY] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const handelOpenSearch = () => {
    searchPageMinScreen.current.classList.add("active");
  };

  window.addEventListener("scroll", () => {
    setScrollY(window.scrollY);
  });
  const userOption = useRef();
  const searchResults = useRef();
  const minScreenMenu = useRef();
  const categoriesMenu = useRef();

  useEffect(() => {
    scrollY > 200 ? setIsVesible(false) : setIsVesible(true);
  }, [scrollY]);

  useEffect(() => {
    handleGetAllProducts();
    handelGetAllCategories();
  }, []);

  const searchInput = useRef();
  const searchPageMinScreen = useRef();
  const searchInputMobile = useRef();

  const handleGetAllProducts = async () => {
    try {
      const req = await axiosInstance.get(`product`);
      setProducts(req?.data?.products);
    } catch (e) {
      console.log(e);
    }
  };

  const handelGetAllCategories = async () => {
    try {
      const { data } = await axiosInstance.get(`/product/categories`);
      setCategories(data?.categories);
    } catch (e) {
      setCategories([]);
    }
  };

  const handelSearch = (name) => {
    if (name?.trim() === "") {
      setSearchResult([]);
      searchResults.current.classList.remove("visble");
      return;
    }
    const filteredProducts = products?.filter((product) =>
      product?.name?.toLowerCase().includes(name?.toLowerCase())
    );
    setSearchResult(filteredProducts);
    searchResults.current.classList.add("visble");
  };
  return (
    <>
      <div className={`mainNavBar ${!isVesible && "vesible"}`}>
        <div className="searchPage" ref={searchPageMinScreen}>
          <div className="searchInputContainer">
            <input
              type="text"
              placeholder="Search..."
              ref={searchInputMobile}
              onChange={(e) => {
                handelSearch(e.target.value);
              }}
            />
            <span
              className="close"
              onClick={() => {
                searchPageMinScreen.current.classList.remove("active");
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div className="searchResultList">
            <ul className="list-group list-group-flush">
              {searchResult.map((product, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      navigat(`/productdetails/${product._id}`);
                      searchPageMinScreen.current.classList.remove("active");
                      searchInputMobile.current.value = "";
                    }}
                  >
                    <img src={image} alt="" />
                    <div className="info">
                      <h4>{product?.name}</h4>
                      <p>
                        {product?.description
                          ?.split(" ")
                          ?.slice(0, 8)
                          ?.join(" ") + "..."}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={`shippingLink ${isVesible && "vesible"}`}>
          <p>Free Shipping on Orders over 9000 EGP</p>
        </div>

        <nav className="navBar">
          <div className="container " style={{ padding: "10px" }}>
            <Link to="/" className="navBrand">
              <img src={YafaLogo} alt="" />
            </Link>
            <div className="links">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About Us</NavLink>
              <NavLink to="/contactus">Contact Us</NavLink>
            </div>
            <div className="rightSide">
              <div className=" d-flex align-items-center  ">
                <div
                  className="d-flex "
                  style={{ gap: "10px", alignItems: "center" }}
                >
                  <div className="search-bar position-relative me-3 searchBar">
                    <div className="searchResult" ref={searchResults}>
                      <ul className="list-group list-group-flush">
                        {searchResult.map((product, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                onClick={() => {
                                  navigat(`/productdetails/${product._id}`);
                                  searchResults.current.classList.remove(
                                    "visble"
                                  );
                                  searchInput.current.value = "";
                                }}
                              >
                                <li>
                                  <img src={image} alt="" />
                                  <div className="info">
                                    <h4>{product?.name}</h4>
                                    <p>
                                      {product?.description
                                        ?.split(" ")
                                        .slice(0, 8)
                                        .join(" ") + " ..."}
                                    </p>
                                  </div>
                                </li>
                              </div>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="position-absolute searchIcon"
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={handelOpenSearch}
                    >
                      <path
                        d="M15.2036 18.344L10.0989 13.243L12.7384 10.6035L17.8431 15.7082C18.262 16.1271 18.262 16.8167 17.8431 17.2356L16.7346 18.344C16.312 18.7629 15.6188 18.7629 15.2036 18.344Z"
                        fill="#BEAE99"
                      />
                      <path
                        d="M11.6003 10.7187L10.2175 12.1014L8.64201 10.5259C6.49558 12.0162 3.52246 11.8086 1.60958 9.8957C-0.536851 7.74927 -0.536851 4.26086 1.60958 2.11072C3.75601 -0.0394124 7.24442 -0.0357055 9.39455 2.11072C11.3074 4.0236 11.515 6.99672 10.0248 9.14315L11.6003 10.7187ZM8.31207 3.17838C6.75508 1.62138 4.23052 1.62138 2.67723 3.17838C1.12024 4.73537 1.12024 7.25993 2.67723 8.81322C4.23423 10.3702 6.75878 10.3702 8.31207 8.81322C9.86907 7.25993 9.86907 4.73537 8.31207 3.17838Z"
                        fill="#BEAE99"
                      />
                    </svg>
                    <input
                      ref={searchInput}
                      type="text"
                      className={`form-control border-0 border-bottom ${css.searchInput} searchInput`}
                      placeholder="Search"
                      onChange={(e) => {
                        handelSearch(e.target.value);
                      }}
                      style={{
                        paddingLeft: "10px",
                        borderBottom: "2px solid white",
                        backgroundColor: "transparent",
                        borderRadius: "0",
                        boxShadow: "none",
                      }}
                    />
                  </div>
                  <div className="iconsGroup">
                    <div
                      className="searchIconMinSreen"
                      onClick={handelOpenSearch}
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div
                      className="userIcon"
                      onClick={() => {
                        $(userOption.current)?.slideToggle();
                        $(minScreenMenu.current)?.slideUp();
                      }}
                    >
                      {isUser ? (
                        <>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1001 11.1495C10.0417 11.1412 9.96672 11.1412 9.90006 11.1495C8.43339 11.0995 7.26672 9.8995 7.26672 8.4245C7.26672 6.91616 8.48339 5.69116 10.0001 5.69116C11.5084 5.69116 12.7334 6.91616 12.7334 8.4245C12.7251 9.8995 11.5667 11.0995 10.1001 11.1495Z"
                              stroke="#BEAE99"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.6166 16.6496C14.1333 18.0079 12.1666 18.8329 9.99997 18.8329C7.8333 18.8329 5.86663 18.0079 4.3833 16.6496C4.46663 15.8662 4.96663 15.0996 5.8583 14.4996C8.14163 12.9829 11.875 12.9829 14.1416 14.4996C15.0333 15.0996 15.5333 15.8662 15.6166 16.6496Z"
                              stroke="#BEAE99"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99996 18.8329C14.6023 18.8329 18.3333 15.102 18.3333 10.4996C18.3333 5.89722 14.6023 2.16626 9.99996 2.16626C5.39759 2.16626 1.66663 5.89722 1.66663 10.4996C1.66663 15.102 5.39759 18.8329 9.99996 18.8329Z"
                              stroke="#BEAE99"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="optionList" ref={userOption}>
                            <div className="options">
                              <div className="accountLi">
                                <h6 style={{ color: "var(--thrd-Color)" }}>
                                  Wellcom {userGlobalInfo?.user?.firstName}
                                  {userGlobalInfo?.user?.lastName}
                                </h6>
                                <span>{userGlobalInfo?.user?.email}</span>
                              </div>

                              <Link to={"/myorders/user"}>
                                <div>My Orders</div>
                              </Link>
                              <Link to={"/mypersonaldata"}>
                                <div>My Personal Data</div>
                              </Link>
                              <li>
                                <div
                                  className="logOut"
                                  onClick={() => {
                                    localStorage.setItem("user", "{}");
                                    setUserGlobalInfo({});

                                    window.location.reload();
                                  }}
                                >
                                  <span>Log Out</span>
                                  <i class="fa-solid fa-right-from-bracket"></i>
                                </div>
                              </li>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              color: "var(--sec-Color)",
                              fontSize: "18px;",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCurrentForm("login");
                            }}
                          >
                            <i class="fa-solid fa-user"></i>
                          </span>
                        </>
                      )}
                    </div>

                    <span
                      className="cartIcon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigat("/cart");
                      }}
                    >
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.92505 7.16618H17.0917V15.7162C17.0917 16.3218 16.8512 16.9025 16.4229 17.3307C15.9947 17.7589 15.414 17.9995 14.8084 17.9995H5.20838C4.6028 17.9995 4.02203 17.7589 3.59382 17.3307C3.16561 16.9025 2.92505 16.3218 2.92505 15.7162V7.16618Z"
                          stroke="#BEAE99"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.74172 2.99951H11.2417C11.9048 2.99951 12.5406 3.2629 13.0095 3.73174C13.4783 4.20059 13.7417 4.83647 13.7417 5.49951V7.16618H6.24172V5.49951C6.24172 4.83647 6.50511 4.20059 6.97395 3.73174C7.44279 3.2629 8.07867 2.99951 8.74172 2.99951Z"
                          stroke="#BEAE99"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {cartCount > 0 && <span id="cartCount">{cartCount}</span>}
                    </span>
                    <div
                      className="heartIcon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigat("/favorites");
                      }}
                    >
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5166 17.8411C10.2333 17.9411 9.76663 17.9411 9.48329 17.8411C7.06663 17.0161 1.66663 13.5744 1.66663 7.7411C1.66663 5.1661 3.74163 3.08276 6.29996 3.08276C7.81663 3.08276 9.15829 3.8161 9.99996 4.94943C10.8416 3.8161 12.1916 3.08276 13.7 3.08276C16.2583 3.08276 18.3333 5.1661 18.3333 7.7411C18.3333 13.5744 12.9333 17.0161 10.5166 17.8411Z"
                          stroke="#BEAE99"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {iconCount > 0 && <span id="iconCount">{iconCount}</span>}
                    </div>
                  </div>
                </div>
                <div className="barsIcon">
                  <i
                    class="fa-solid fa-bars-staggered"
                    onClick={() => {
                      $(minScreenMenu.current)?.slideToggle();
                      $(userOption.current)?.slideUp();
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="navBarMinScreen" ref={minScreenMenu}>
              <ul>
                <li
                  onClick={() => {
                    $(minScreenMenu.current)?.slideUp();
                  }}
                >
                  <Link to={`/about`}>About Us</Link>
                </li>
                <li
                  onClick={() => {
                    $(minScreenMenu.current)?.slideUp();
                  }}
                >
                  <Link to={`/contactus`}>Contact Us</Link>
                </li>
                <li>
                  <span
                    onClick={() => {
                      $(categoriesMenu.current).slideToggle();
                    }}
                  >
                    Categories <i class="fa-solid fa-chevron-down"></i>
                  </span>
                  <ul ref={categoriesMenu}>
                    {categories.map((item) => {
                      return (
                        <li
                          key={item._id}
                          onClick={() => {
                            $(categoriesMenu.current)?.slideUp();
                            $(minScreenMenu.current)?.slideUp();
                          }}
                        >
                          <Link to={`/category/${item._id}`}>{item.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
