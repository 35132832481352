import React, { useContext, useEffect, useState } from 'react'
import css from '../style.module.css';
import Search from '../Search';
import Banner from './Banner';
import AboutUS from './AboutUS';
import { mediaContext } from '../../../Context/Media';
import MediaNav from '../../Navs/SideNav/MediaNav';
import Mission from '../../Navs/SideNav/Mission';
import Vision from '../../Navs/SideNav/Vision';
export default function Media() {
    const { media } = useContext(mediaContext);
    const [mediaData, setMediaData] = useState([]);
    const [bannerData, setBannerData] = useState({});
    const [aboutUsData, setAboutUsData] = useState({});
    const [visibleBannerNavVisible, setBannerNavVisible] = useState(false);
    const [visibleMission, setMission] = useState(false);
    const [visibleVision, setVision] = useState(false);
    const [visibleCoreValue, setCoreValue] = useState(false);
    const [visibleStore, setStore] = useState(false);
    const [activePage, setActivePage] = useState('Banner');

    function openbanarNav() {
        setBannerNavVisible(true);
        setMission(false);
        setVision(false)
        setCoreValue(false)
        setStore(false)
    }


    function closNavs(navName) {
        if (navName === 'mission') {
            setBannerNavVisible(false);
            setMission(true);
            setVision(false)
            setCoreValue(false)
            setStore(false)
        } else if (navName === 'vision') {
            setVision(true)
            setMission(false)
            setStore(false)

            setBannerNavVisible(false);
            setCoreValue(false)

        } else if (navName === "coreValue") {
            setCoreValue(true)
            setStore(false)
            setVision(false)
            setMission(false)
            setBannerNavVisible(false);
        } else {
            setCoreValue(true)
            setStore(true)
            setVision(false)
            setMission(false)
            setBannerNavVisible(false);
        }
    }

    useEffect(() => {
        if (media) {
            console.log("Media array updated:", media);
            setMediaData([media])
        }
    }, [media]);

    useEffect(() => {
        if (mediaData.length > 0) {
            const { banner, banner2, ...restData } = mediaData[0];
            setBannerData([banner, banner2]);

            // Add restData along with banner and banner2 in aboutUsData
            setAboutUsData([restData]);
        }
    }, [mediaData])
    const handleNavigation = (page) => {
        setActivePage(page); // Set the active page based on the clicked link
    };

    return (
        <>
            {/* Main content */}
            <div className="flex-grow-1 px-3 " style={{ transition: 'margin-left 0.3s' }}>
                <Search />
                <div className='fadeInAnimate'>
                    <div className='d-flex justify-content-between align-items-center  '>
                        <div>
                            <p className={`${css.pad} ${css.textProp} inter m-0`}>Media</p>
                            <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                        </div>
                        {activePage === "Banner" &&
                            <button className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white  d-flex justify-content-center align-items-center border-0`} onClick={() => openbanarNav()}>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 8.5C14 5.1875 11.3125 2.5 8 2.5C4.6875 2.5 2 5.1875 2 8.5C2 11.8125 4.6875 14.5 8 14.5C11.3125 14.5 14 11.8125 14 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                                    <path d="M8 6V11V6ZM10.5 8.5H5.5H10.5Z" fill="white" />
                                    <path d="M8 6V11M10.5 8.5H5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                ADD NEW
                            </button>}
                    </div>
                    <div className={`bg-white ${css.mediaPages} d-flex  overflow-hidden`}>
                        <div onClick={() => handleNavigation('Banner')} className={`col-md-6 cursor my-auto d-flex justify-content-center ${activePage === 'Banner' ? `${css.activeMediaPages}` : ''} `}>
                            <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Banner' ? ` text-white fst-normal` : ''}`}>Banner</p>
                        </div>
                        <div onClick={() => handleNavigation('AboutUs')} className={`col-md-6 cursor my-auto d-flex justify-content-center ${activePage === 'AboutUs' ? `${css.activeMediaPages}` : ''} `}>
                            <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'AboutUs' ? ` text-white fst-normal` : ''}`}>About Us</p>
                        </div>
                    </div>
                    {activePage === "Banner" ? <Banner bannerData={bannerData} /> : <AboutUS aboutUsData={aboutUsData} setVision={setVision} setBannerNavVisible={setBannerNavVisible} setMission={setMission} />}
                </div>
            </div>
            {/* SideBar */}
            {visibleBannerNavVisible && (
                <div className={`p-0 displayAnimation ${visibleBannerNavVisible ? 'show' : 'hide'}`}>
                    <MediaNav setBannerNavVisible={setBannerNavVisible} />
                </div>
            )}

            {/* about */}
            {visibleMission && (
                <div className={`p-0 displayAnimation ${visibleMission ? 'show' : 'hide'}`}>
                    <Mission setMission={setMission} closNavs={closNavs} setAboutUsData={setAboutUsData} />
                </div>
            )}
            {visibleVision && (
                <div className={`p-0 displayAnimation ${visibleVision ? 'show' : 'hide'}`}>
                    <Vision setVision={setVision} closNavs={closNavs} setAboutUsData={setAboutUsData} />
                </div>
            )}
        </>

    )
}