import React, { useContext, useState } from 'react';
import css from "../style.module.css";
import { categoriesContext } from '../../../Context/categories';

export default function SubCategoriseNav({ setVisible }) {
    const { addSubCategories, categories } = useContext(categoriesContext);
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(''); // State to hold selected category ID

    const getData = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCategoryChange = (e) => {
        const { value } = e.target;
        setSelectedCategoryId(value); // Update state with selected category ID
        console.log("Selected Category ID:", value); // Optional logging
    };

    const Submit = async () => {
        setIsLoading(true);
        try {
            console.log({ ...data, category: selectedCategoryId });
            
            const response = await addSubCategories({ ...data, category: selectedCategoryId }); // Include category ID in data
            if (response.status && response.status === 200) {
                setIsLoading(false);
                setMessage(response.data.message);
            }
            console.log(response);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            setMessage("Name is already used");
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Add New Subcategory</p>
            <input type="text" className={`${css.adminInput} work-sans my-2`} name="name" placeholder="Name" onChange={getData} />

            <select className={`${css.adminInput} work-sans my-2`} onChange={handleCategoryChange} value={selectedCategoryId}>
                <option value="">Select a Category</option> {/* Placeholder option */}
                {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                        {category.name}
                    </option>
                ))}
            </select>
            <div className="position-absolute bottom-0 mb-4 ms-5">
                {message && (
                    <p className={`text-center ${message === "name is already used" ? "text-danger" : "text-success"} fw-bold`}>
                        {message}
                    </p>
                )}

                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`} onClick={() => setVisible(false)}>
                    <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Cancel
                </button>

                <button className={`${css.btnBoth}`} onClick={Submit}>
                    <svg width="25" className="me-2" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Submit
                    {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    );
}
