import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import axiosInstance from "../../../../api/axiosInstance";
import { UserContext } from "../../../../Context/UserInof";
import "./otpStyle.scss";
import { motion } from "framer-motion";

const OtpPopup = ({ orderId, orderData, close }) => {
  let [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userGlobalInfo } = useContext(UserContext);
  const handelSubmit = async (values) => {
    setLoading(true);
    const body = {
      ...values,
    };
    try {
      const req = await axiosInstance.post("/order/verify", body, {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      if (req.message === "Invalid Code!") {
        setErrorMessage("Invalid Code!");
      } else {
        close();
        navigate(`/myorders/${orderId}`);
      }
    } catch (error) {
      setErrorMessage("Failed to fetch Otp", error);
    }
    setLoading(false);
  };

  let validationSchema = yup.object({
    otpCode: yup
      .string()
      .required("OTP Code is required")
      .max(30, " max numbers is 30 "),
  });

  const OtpForm = useFormik({
    initialValues: {
      otpCode: "",
    },
    onSubmit: handelSubmit,
    validationSchema,
  });
  return (
    <div>
      <motion.div
        initial={{ y: "-400px" }}
        animate={{
          y: "0px",
          transition: { delay: 0, duration: 0.8, type: "spring" },
        }}
        className="optPopup"
      >
        <div className="popup">
          <form onSubmit={OtpForm.handleSubmit} className="popupContent">
            <span className="close" onClick={close}>
              <i class="fa-solid fa-xmark"></i>
            </span>
            <h1>Verify your account</h1>
            <p>
              We have sent a 6-digit verification code to{" "}
              <br/>
              <span>{orderData?.guestUser?.phone}</span>
            </p>

            <div className="inputContainer">
              <label htmlFor="otpCode"> Enter OTP Code : </label>
              <input
                type="tel"
                id="otpCode"
                name="otpCode"
                placeholder="Enter OTP"
                onChange={OtpForm.handleChange}
                onBlur={OtpForm.handleBlur}
              />

              {OtpForm.errors.otpCode && OtpForm.touched.otpCode && (
                <span className="error">{OtpForm.errors.otpCode}</span>
              )}
            </div>
            <div className="submitBtn">
              <button type="submit" disabled={loading}>
                {loading ? (
                  <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  "Verify OTP"
                )}
              </button>
            </div>

            {errorMessage && <span className="error">{errorMessage}</span>}
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default OtpPopup;
