import React, { useEffect, useState } from 'react';
import css from '../style.module.css';
import Search from '../Search';
import PermissiongroupsNav from '../../Navs/SideNav/PermissiongroupsNav';
import { useContext } from 'react';
import { PermissionContext } from '../../../Context/Permission';

export default function Permissiongroups() {
    const { permission, deletePermission } = useContext(PermissionContext);
    const [openIndexes, setOpenIndexes] = useState([]);
    const [visible, setVisible] = useState(0);
    const [permissionData, setPermissionData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (Object.keys(permission).length !== 0) {
            console.log("permissions: ", permission.permissionGroups[0].permissions);
            setPermissionData(permission.permissionGroups);
            setIsLoading(false);
        }
    }, [permission]);

    // Show loading indicator if still loading
    if (isLoading) {
        return (
            <p>
                Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
            </p>
        );
    }

    const toggleOpenIndex = (index) => {
        // If the index is already open, remove it from the array; otherwise, add it
        setOpenIndexes(prevIndexes =>
            prevIndexes.includes(index) ? prevIndexes.filter(i => i !== index) : [...prevIndexes, index]
        );
    };

    // delete  
    const deleted = async (id) => {
        console.log(id);
        try {
            const data = await deletePermission(id);
            console.log(data);
            if (data.status === 200) {
                setPermissionData((prevData) => prevData.filter((item) => item._id !== id));
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {/* Main content */}
            <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
                <Search />
                <div className='fadeInAnimate'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <p className={`${css.pad} ${css.textProp} inter m-0`}>Permission Groups</p>
                            <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                        </div>
                        <button className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white  d-flex justify-content-center align-items-center border-0`} onClick={() => setVisible(1)}>
                            <i className={`fas fa-plus fa-fw py-auto `}></i>
                            ADD NEW
                        </button>
                    </div>
                    <div className="row">
                        {permissionData.map((item, firstIndex) => (
                            <div
                                key={firstIndex}
                                className={`${css.permissionGroupsProp} bg-white my-2 cursor`}
                                onClick={() => toggleOpenIndex(firstIndex)} // Toggle visibility
                            >
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className={`inter ${css.permissionGroupsText} m-0`}>{item.name}</p>
                                    {openIndexes.includes(firstIndex) ? (
                                        <i className="fa-solid fa-angle-down"></i>
                                    ) : (
                                        <i className="fa-solid fa-angle-up"></i>
                                    )}
                                </div>
                                <div
                                    className='slidingContentAnimate'
                                    style={{
                                        maxHeight: openIndexes.includes(firstIndex) ? '200px' : '0px', // Adjust maxHeight for animation
                                        opacity: openIndexes.includes(firstIndex) ? 1 : 0,
                                        transition: 'max-height 0.4s ease, opacity 0.4s ease', // Smooth transition

                                    }}
                                >
                                    <div className="row">
                                        {Object.entries(item.permissions)
                                            .filter(([, value]) => value === true)
                                            .length > 0 ? (
                                            Object.entries(item.permissions)
                                                .filter(([, value]) => value === true)
                                                .map(([key], index) => (
                                                    <div className="col-md-3" key={index}>
                                                        <p className={`inter ${css.Internalwriting} inter m-0 mt-3`}>
                                                            {key}
                                                        </p>
                                                    </div>
                                                ))
                                        ) : (
                                            <div className="col-md-3">
                                                <p className={`inter ${css.Internalwriting} inter m-0 mt-3`}>
                                                    No Permissions
                                                </p>
                                            </div>
                                        )
                                        }
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center mt-3'>
                                        <svg width="28" height="28" className='me-3' viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => { e.stopPropagation() }}>
                                            <rect width="28" height="28" rx="8" fill="#8B442A" />
                                            <path d="M14 19.3334H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17 8.33322C17.2652 8.068 17.6249 7.91901 18 7.91901C18.1857 7.91901 18.3696 7.95559 18.5412 8.02666C18.7128 8.09773 18.8687 8.2019 19 8.33322C19.1313 8.46454 19.2355 8.62044 19.3066 8.79202C19.3776 8.9636 19.4142 9.1475 19.4142 9.33322C19.4142 9.51894 19.3776 9.70284 19.3066 9.87442C19.2355 10.046 19.1313 10.2019 19 10.3332L10.6667 18.6666L8 19.3332L8.66667 16.6666L17 8.33322Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => { e.stopPropagation(); deleted(item._id) }}>
                                            <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#8B442A" />
                                            <path d="M8 10H9.33333H20" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.3334 10V8.66671C11.3334 8.31309 11.4739 7.97395 11.7239 7.7239C11.9739 7.47385 12.3131 7.33337 12.6667 7.33337H15.3334C15.687 7.33337 16.0261 7.47385 16.2762 7.7239C16.5262 7.97395 16.6667 8.31309 16.6667 8.66671V10M18.6667 10V19.3334C18.6667 19.687 18.5262 20.0261 18.2762 20.2762C18.0261 20.5262 17.687 20.6667 17.3334 20.6667H10.6667C10.3131 20.6667 9.97395 20.5262 9.7239 20.2762C9.47385 20.0261 9.33337 19.687 9.33337 19.3334V10H18.6667Z" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.6666 13.3334V17.3334" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.3334 13.3334V17.3334" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* SideBar */}
            {visible && (
                <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'} vh-100`}>
                    <PermissiongroupsNav setVisible={setVisible} permissionData={permissionData} setPermissionData={setPermissionData} />
                </div>
            )}
        </>
    );
}
