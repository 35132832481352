import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";

export const adminContext = createContext(null);

export default function AdminProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [admin, setAdmin] = useState({});

  const getadmin = async () => {
    try {
      const { data } = await axios.get("https://api.yafa-brand.com/admin", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      console.log(data);

      setAdmin(data);
    } catch (error) {
      console.error("Failed to fetch admin", error);
    }
  };

  const createadmin = async (Newadmin) => {
    console.log("Newadmin");
    console.log(Newadmin);

    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/admin",
        Newadmin,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch admin", error);
    }
  };
  const deleteAdmin = async (id) => {
    try {
      const response = await axios.delete(`https://api.yafa-brand.com/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Failed to fetch admin", error);
    }
  };
  const updateAdmin = async (id, data) => {
    console.log("id is ");
    console.log(id);

    console.log("dataEdittion is");
    console.log(data);

    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/admin/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch admin", error);
    }
  };

  useEffect(() => {
    if (userGlobalInfo?.token) {
      getadmin();
    }
  }, [userGlobalInfo]);
  return (
    <adminContext.Provider value={{ admin, createadmin, deleteAdmin,updateAdmin }}>
      {children}
    </adminContext.Provider>
  );
}
