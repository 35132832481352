import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { UserContext } from "./UserInof";
import { useEffect } from "react";

export const countriesContext = createContext(null);
export default function CountriesProvider({ children }) {
  //  name[contains]=United Kingdoms
  const { userGlobalInfo } = useContext(UserContext);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const getCountries = async () => {
    try {
      const { data } = await axios.get("https://api.yafa-brand.com/country", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      setCountries(data.countries);
    } catch (error) {
      console.log(error);
    }
  };
  // cities
  const getCity = async () => {
    try {
      const { data } = await axios.get("https://api.yafa-brand.com/city", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      setCities(data.cities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userGlobalInfo) {
      getCountries();
      getCity();
    }
  }, [userGlobalInfo]);

  const addCountry = async (country) => {
    if (userGlobalInfo) {
      const response = await axios.post(
        "https://api.yafa-brand.com/country",
        country,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    }
  };

  const addCity = async (city) => {
    if (userGlobalInfo) {
      const response = await axios.post(
        "https://api.yafa-brand.com/city",
        city,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    }
  };

  const updateCountry = async (name, code, countryId) => {
    if (userGlobalInfo) {
      await axios.patch(
        `https://api.yafa-brand.com/country/${countryId}`,
        { name, code },
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
    }
  };

  const updateCity = async (name, cityId) => {
    console.log(name);
    console.log(cityId);
    if (userGlobalInfo) {
      await axios.patch(
        `https://api.yafa-brand.com/city/${cityId}`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
    }
  };

  const deleteCountry = async (id) => {
    if (userGlobalInfo) {
      const result = await axios.delete(
        `https://api.yafa-brand.com/country/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return result;
    }
  };
  const deleteCity = async (id) => {
    if (userGlobalInfo) {
      await axios.delete(`https://api.yafa-brand.com/city/${id}`, {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
    }
  };
  return (
    <countriesContext.Provider
      value={{
        countries,
        setCountries,
        addCountry,
        updateCountry,
        updateCity,
        deleteCountry,
        deleteCity,
        cities,
        setCities,
        addCity,
      }}
    >
      {children}
    </countriesContext.Provider>
  );
}
