import React, { useState } from 'react';
import css from '../style.module.css';
import Search from '../Search';
import CreateDataNav from '../../Navs/SideNav/CreateDataNav';
import SubCategoriseNav from '../../Navs/SideNav/subCategoriseNav';
import Categories from './Categories';
import Colors from './Colors';
import Size from './Size';
import { getFormattedDate } from '../../dateFuc';
import Sub from './Sub';
import CreateDataEdition from '../../Navs/SideNav/Edition/CreateDataEdition';
import ColorNav from '../../Navs/SideNav/ColorNav';

export default function CreateData() {
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleColorNav, setVisibleColorNav] = useState(false);
    const [activePage, setActivePage] = useState('Categories');
    const [editCategory, setEditCategory] = useState({})

    const handleNavigation = (page) => {
        setActivePage(page);
    };

    const AddnewFunction = () => {
        setVisible(true);
        setVisibleEdit(false); // Ensures CreateDataEdition is not shown
    };

    const editNewFunction = () => {
        setVisible(false);
        setVisibleEdit(true);
    };


    return (
        <>
            {/* Main content */}
            <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
                <Search />
                <div className='fadeInAnimate'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <p className={`${css.pad} ${css.textProp} inter m-0`}>Create Data</p>
                            <p className={`${css.dateProp}`}>{getFormattedDate()}</p>
                        </div>
                        <button
                            className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white d-flex justify-content-center align-items-center border-0`}
                            onClick={AddnewFunction}
                        >
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 8.5C14 5.1875 11.3125 2.5 8 2.5C4.6875 2.5 2 5.1875 2 8.5C2 11.8125 4.6875 14.5 8 14.5C11.3125 14.5 14 11.8125 14 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                                <path d="M8 6V11V6ZM10.5 8.5H5.5H10.5Z" fill="white" />
                                <path d="M8 6V11M10.5 8.5H5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            ADD NEW
                        </button>
                    </div>
                    <div className={`row bg-white ${css.mediaPages} d-flex`}>
                        <div onClick={() => handleNavigation('Categories')} className={`col-md-4 cursor my-auto d-flex justify-content-center ${activePage === 'Categories' ? `${css.activeMediaPages}` : ''}`}>
                            <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Categories' ? ` text-white fst-normal` : ''}`}>Categories</p>
                        </div>
                        <div onClick={() => handleNavigation('subCategories')} className={`col-md-4 cursor my-auto d-flex justify-content-center ${activePage === 'subCategories' ? `${css.activeMediaPages}` : ''}`}>
                            <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'subCategories' ? ` text-white fst-normal` : ''}`}>SubCategories</p>
                        </div>
                        <div onClick={() => handleNavigation('Colors')} className={`col-md-4 cursor my-auto d-flex justify-content-center ${activePage === 'Colors' ? `${css.activeMediaPages}` : ''}`}>
                            <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Colors' ? ` text-white fst-normal` : ''}`}>Colors</p>
                        </div>
                        {/* <div onClick={() => handleNavigation('Sizes')} className={`col-md-3 cursor my-auto d-flex justify-content-center ${activePage === 'Sizes' ? `${css.activeMediaPages}` : ''}`}>
                            <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Sizes' ? ` text-white fst-normal` : ''}`}>Sizes</p>
                        </div> */}
                    </div>

                    {activePage === "Categories" ? <Categories setEditCategory={setEditCategory} editNewFunction={editNewFunction} /> : activePage === "Colors" ? <Colors /> : activePage === "subCategories" && <Sub />}
                </div>
            </div>
            {/* SideBar for CreateDataNav */}
            {visible && activePage === "Categories" && (
                <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'}`}>
                    <CreateDataNav setVisible={setVisible} />
                </div>
            )}
            {visible && activePage === "subCategories" && (
                <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'}`}>
                    <SubCategoriseNav setVisible={setVisible} />
                </div>
            )}
            {visible && activePage === "Colors" && (
                <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'}`}>
                    <ColorNav setVisible={setVisible} />
                </div>
            )}
            {/* Edit Section for CreateDataEdition */}
            {visibleEdit && activePage === "Categories" && (
                <div className={`p-0 displayAnimation ${visibleEdit ? 'show' : 'hide'}`}>
                    <CreateDataEdition editCategory={editCategory} setVisibleEdit={setVisibleEdit} />
                </div>
            )}

        </>
    );
}
