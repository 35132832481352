import React, { useContext, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import css from "./style.module.css";
import { categoriesContext } from "../../Context/categories";
import { useEffect } from "react";
import ProductCard from "./ui/cards/ProductCard";
import "./styles/categoryStyle.scss";
import Loding from "../Loding/Loding";
import Pagination from "./ui/pagination/Pagination";

export default function Category() {
  const location = useLocation();
  const { getcategorywithitsproducts } = useContext(categoriesContext);
  const { imgs, text } = location.state || {};
  const { categoryId } = useParams();
  const [lodaing, setlodaing] = useState(false);
  const [category, setCategory] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [otherCategories, setOtherCategories] = useState(null);
  let [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getDataById(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataById = async (pageNum = 1) => {
    setlodaing(true);
    try {
      if (categoryId) {
        const data = await getcategorywithitsproducts(categoryId);
        setTotalPages(data.pagination.totalPages);
        setCategory(data?.category);
        setOtherCategories(data?.otherCategories);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setlodaing(false);
    }
  };

  useEffect(() => {
    getDataById();
  }, [categoryId]);

  return (
    <>
      {lodaing ? (
        <Loding />
      ) : (
        <>
          <section className="" style={{ marginTop: "131px" }}>
            <div className="container-fluid">
              <div className="pageTitle">
                <h1> {category?.name}</h1>
                <p>{category?.description}</p>
              </div>
              <div className="">
                <div className="sectionContainerow">
                  <div className="cardContainer">
                    {category?.products?.slice(0, 4).map((product, index) => (
                      <div id="ProductImg" key={index} className="">
                        <div
                          state={{ imgs, text }}
                          className="text-decoration-none text-dark"
                        >
                          <ProductCard productData={product} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="cardContainerPlus">
                    {category?.products?.slice(4, 10).map((product, index) => (
                      <div id="ProductImg" key={index} className=" ">
                        <div
                          state={{ imgs, text }}
                          className="text-decoration-none text-dark"
                        >
                          <ProductCard productData={product} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <p className={`${css.categoryHeader} text-center archivo mt-5`}>
                  You May Also Like
                </p>
                <div className="container">
                  <div className="allCategoryNames ">
                    {otherCategories?.map((other, indx) => (
                      <Link
                        to={`/category/${other._id}`}
                        key={indx}
                        className="categoryBox"
                      >
                        <p>{other.name}</p>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="sectionContainer">
                  <div className="cardContainer">
                    {category?.products?.slice(10, 14).map((product, index) => (
                      <div id="ProductImg" key={index} className="">
                        <div
                          state={{ imgs, text }}
                          className="text-decoration-none text-dark"
                        >
                          <ProductCard productData={product} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="cardContainerPlus">
                    {category?.products?.slice(14).map((product, index) => (
                      <div id="ProductImg" key={index} className=" ">
                        <div
                          state={{ imgs, text }}
                          className="text-decoration-none text-dark"
                        >
                          <ProductCard productData={product} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {totalPages > 1 && (
        <Pagination totalPages={totalPages} onPageChange={handlePageChange} />
      )}
    </>
  );
}
