import React, { useState } from 'react';
import css from './style.module.css';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function OTPVerification() {
    const navigate = useNavigate();
    const [code, setCode] = useState(Array(6).fill(''));
    const [spinner, setSpinner] = useState(false)
    // const [error, setError] = useState(false);

    const handleClick = () => {
        // Convert array of codes to a string
        const otpCode = code.join('');
        localStorage.setItem("code", JSON.stringify(otpCode));
        console.log('Final OTP Code:', otpCode);
        navigate('/createpassword');
    };

    const getCode = (e, index) => {
        const value = e.target.value;
        // Only allow numbers (or single characters) in the input
        if (value.match(/^[0-9]*$/)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
        }
    };
    return (
        <section className={css.bgLogin}>
            <div className={`${css.layer} `}>
                <Logo />
                <div className={`position-absolute top-50 start-50 translate-middle fadeInAnimate`}>
                    <div className={`${css.customizeMargin} mx-5 `}>
                        <p className={`prata-regular ${css.loginFontAtrr} text-center text-white mb-0 `}>
                            OTP Verification
                        </p>
                        <p className={`work-sans ${css.WelcomFontAtrr} text-white mt-2 ms-4`}>
                            Enter the verification code we just sent on your <br />email address.
                        </p>
                    </div>
                    <div className='work-sans d-flex justify-content-center align-items-center'>
                        <div className=' d-flex'>
                            {Array(6).fill().map((_, index) => (
                                <input
                                    key={index}
                                    type='text'
                                    maxLength={1}  // Limit to 1 character per input
                                    value={code[index]}  // Controlled input
                                    placeholder='X'
                                    onChange={(e) => getCode(e, index)}  // Pass index to update specific input
                                    className={css.verificationInput}
                                />
                            ))}
                        </div>
                        <div>
                            <button className='bg-transparent text-white rounded-0 text-center px-4 m-0' onClick={handleClick}>
                                Verify
                                {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
