import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./styles/contactStyle.scss";
import axiosInstance from "../../api/axiosInstance";
export default function ContactUs() {
  let [errorMessage, setErrorMessage] = useState("");
  let [loding, setLoding] = useState(false);
  let [metaData, setMetaData] = useState({});
  const initialValues = {
    contactno: "",
    country_code: "",
    dialCode: "",
    country_Name: "",
  };

  const [phoneData, setPhoneData] = useState(initialValues);

  const submitData = async (values) => {
    setLoding(true);
    values.phone = phoneData.contactno;
    try {
      let { data } = await axiosInstance.post("/contactUs/contact", values);
      if (typeof window.fbq !== "undefined") {
        window.fbq("track", "Contact");
      }
      alert(data.message);

      setErrorMessage("");
    } catch (err) {
      setErrorMessage("Failed to submit form, please try again");
      setLoding(false);
      return;
    }

    setLoding(false);
  };

  const handelMetaData = async () => {
    try {
      const { data } = await axiosInstance.get(`/metaData`);
      setMetaData({
        phone: data.contactInfo.phone,
        email: data.contactInfo.email,
        website: data.contactInfo.website,
      });
    } catch (e) {
      setMetaData({});
    }
  };

  let validationSchema = yup.object({
    firstName: yup
      .string()
      .required("Name is required")
      .min(3, "Min char is 3")
      .max(20, "Max char is 20"),
    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Min char is 3")
      .max(20, "Max char is 20"),
    email: yup
      .string()
      .required("Email is required")
      .email("Enter valid email"),
    message: yup
      .string()
      .required("Message is required")
      .max(500, "Max char is 500"),
  });

  let contactForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    onSubmit: submitData,
    validationSchema,
  });

  const handleChangePhone = (e, value, name) => {
    if (name === "contactno") {
      const splitMobile = e.split(value.dialCode);

      setPhoneData({
        ...phoneData,
        country_code: value.dialCode,
        contactno: splitMobile?.[1] || "",
        dialCode: value.countryCode,
        country_Name: value.name,
      });
    } else {
      setPhoneData({
        ...phoneData,
        [e.target?.name]: [e.target?.value],
      });
    }
  };
  useEffect(() => {
    handelMetaData();
  }, []);
  return (
    <section className="" style={{ marginTop: "130px" }}>
      <div className="container">
        <div className="row ">
          <form
            onSubmit={contactForm.handleSubmit}
            className={`col-md-8 ${css.rightSideBorder}`}
          >
            <p className={`${css.categoryHeader} archivo  `}>Contact Us</p>
            {/* <p className="nunito p-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p> */}
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="">
                  <label
                    htmlFor="firstName"
                    className={`d-block ${css.labelCheckOut} mb-2`}
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    placeholder="Add"
                    className={`form-control ${css.inputCheckOut}`}
                    name="firstName"
                    id="firstName"
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                  />
                  {contactForm.errors.firstName &&
                    contactForm.touched.firstName && (
                      <span className="error">
                        {contactForm.errors.firstName}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="">
                  <label
                    htmlFor="lastName"
                    className={`d-block ${css.labelCheckOut} mb-2`}
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    placeholder="Add"
                    className={`form-control ${css.inputCheckOut}`}
                    name="lastName"
                    id="lastName"
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                  />
                  {contactForm.errors.lastName &&
                    contactForm.touched.lastName && (
                      <span className="error">
                        {contactForm.errors.lastName}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="">
                  <label
                    htmlFor="email"
                    className={`d-block ${css.labelCheckOut} mb-2`}
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder="you@company.com"
                    className={`form-control ${css.inputCheckOut}`}
                    name="email"
                    id="email"
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                  />
                  {contactForm.errors.email && contactForm.touched.email && (
                    <span className="error">{contactForm.errors.email}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="">
                  <label
                    htmlFor="phone"
                    className={`d-block ${css.labelCheckOut} mb-2`}
                  >
                    Phone number
                  </label>
                  <PhoneInput
                    country={"eg"}
                    value={`${phoneData.country_code}${phoneData.contactno}`}
                    onChange={(e, phone) => {
                      handleChangePhone(e, phone, "contactno");
                    }}
                    inputStyle={{ height: "50px", width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="">
                  <label
                    htmlFor="message"
                    className={`d-block ${css.labelCheckOut} mb-2`}
                  >
                    Message
                  </label>
                  <textarea
                    placeholder="Add"
                    rows={4}
                    className={`form-control ${css.inputCheckOut}`}
                    name="message"
                    id="message"
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                  ></textarea>
                  {contactForm.errors.message &&
                    contactForm.touched.message && (
                      <span className="error">
                        {contactForm.errors.message}
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center ">
              <button
                className={`${css.btnContinue} text-center  `}
                style={{ padding: "12px 150px" }}
                disabled={loding}
              >
                {loding ? (
                  <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            {errorMessage && (
              <span className="error text-center" style={{ fontSize: "16px" }}>
                {errorMessage}
              </span>
            )}
          </form>
          <div className="col-md-4 ">
            <div className="row">
              <div
                className={`col-md-12 ${css.bottomSideBorder} `}
                style={{ padding: "70px 25px" }}
              >
                <div>
                  <p className={css.account}>Email</p>
                  <p>Our friendly team is here to help.</p>
                  <p className={css.props}>{metaData.email}</p>
                </div>
              </div>
              <div className={`col-md-12  `} style={{ padding: "70px 25px" }}>
                <div>
                  <p className={css.account}>Phone</p>
                  <p>Mon-Fri from 8am to 5pm.</p>
                  <p className={css.props}>{metaData.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
