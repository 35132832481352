import React, { useContext } from "react";
import css from "./style.module.css";
import Edit from "../../Images/edit.svg";
import Avatar from "../../Images/Female Avatar 03.png";
import { UserContext } from "../../Context/UserInof";

export default function MyPersonalData() {
  const { userGlobalInfo } = useContext(UserContext);
  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <div className="row">
          <div className="cartText">
            <p
              className={`${css.categoryHeader} archivo text-center text-md-start`}
            >
              My Personal Data
            </p>
            <p className="nunito p-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className={`${css.cartBlock} py-4 px-4 mt-3 d-none d-md-block`}>
            <div className="row">
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <div className={`${css.profileImg} me-3`}>
                    <img src={Avatar} className="w-100" alt="" />
                  </div>
                  <div>
                    <p className="m-0 archivo">Email</p>
                    <p className={`m-0 ${css.Message}`}>
                      {userGlobalInfo?.user?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div>
                  <p className="m-0 archivo">First Name</p>
                  <p className={`m-0 ${css.Message}`}>
                    {userGlobalInfo?.user?.firstName}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div>
                  <p className="m-0 archivo">Last Name</p>
                  <p className={`m-0 ${css.Message}`}>
                    {userGlobalInfo?.user?.lastName}
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-end">
                <div className="d-flex justify-content-evenly align-items-center">
                  <img src={Edit} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className={`${css.cartBlock} py-4 px-4 mt-3 d-md-none`}>
            <div className="row">
              {/* Centered First Image */}
              <div className="col-7 d-flex justify-content-end">
                <div className={`${css.profileImg}`}>
                  <img src={Avatar} className="w-100" alt="" />
                </div>
              </div>
              {/* Second Image at the end */}
              <div className="col-5 d-flex justify-content-end">
                <div className={`${css.profileImg} me-3`}>
                  <img src={Edit} alt="" />
                </div>
              </div>
            </div>

            {/* Email Row */}
            <div className="row mt-3">
              <div className="col-12">
                <p className="m-0 archivo">Email</p>
                <p className={`m-0 ${css.Message}`}>
                  {userGlobalInfo?.user?.email}
                </p>
              </div>
            </div>

            {/* First Name and Last Name Row */}
            <div className="row mt-3">
              <div className="col-6 d-flex justify-content-start">
                <div>
                  <p className="m-0 archivo">First Name</p>
                  <p className={`m-0 ${css.Message}`}>{userGlobalInfo?.user?.firstName}</p>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div>
                  <p className="m-0 archivo">Last Name</p>
                  <p className={`m-0 ${css.Message}`}>{userGlobalInfo?.user?.lastName}</p>
                </div>
              </div>
            </div>
          </div>

          <p className={`${css.categoryHeader} archivo mt-3`}>
            Change Password
          </p>
          <div className={`${css.cartBlock} py-4 px-4 mt-3`}>
            <div className="row">
              <div className="col-10 col-md-10">
                <div cla>
                  <p className="m-0">
                    D/4, Amrut, Runwala Park Chembur Naka, Chembur, Mumbai,
                    Maharashtra - 400071
                  </p>
                </div>
              </div>
              <div className="col-2 col-md-2 ">
                <div className="d-flex justify-content-end align-items-center ">
                  <img src={Edit} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
