import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof"; // Corrected import
import axios from "axios";

export const categoriesContext = createContext(null);

export default function CategoriesProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [categories, setCategories] = useState([]); // Initialized as empty array
  const [subCategories, setSubCategories] = useState([]); // Correct naming
  // const [categoryProducts, setCategoryProducts] = useState([]); // Correct naming

  const getCategories = async () => {
    try {
      const { data } = await axios.get(
        "https://api.yafa-brand.com/product/categories",
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      setCategories(data.categories);
    } catch (error) {
      console.error("Failed to fetch categories", error); // More descriptive
    }
  };
  const getcategorywithitsproducts = async (categoryId, pageNum) => {
    try {
      const { data } = await axios.get(
        `https://api.yafa-brand.com/product/categories/${categoryId}`,
        {
          params: {
            page: pageNum,
            perPage: 20,
          },
        }
      );
      console.log(data);
      
      return data;
    } catch (error) {
      console.error("Failed to fetch categories", error); // More descriptive
    }
  };

  const addCategories = async (newCategories) => {
    try {
      const { data } = await axios.post(
        "https://api.yafa-brand.com/product/addCategory",
        newCategories,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return data; // Consider returning relevant data
    } catch (error) {
      console.error("Failed to fetch categories", error); // More descriptive
    }
  };


  const getSubCategories = async () => {
    try {
      const { data } = await axios.get(
        "https://api.yafa-brand.com/product/subcategories",
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      setSubCategories(data);
    } catch (error) {
      console.error("Failed to fetch subcategories", error); // More descriptive
    }
  };

  const addSubCategories = async (newSub) => {
    try {
      const { data } = await axios.post(
        "https://art-commerce.onrender.com/product/addSubCategory",
        newSub,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return data; // Consider returning relevant data
    } catch (error) {
      console.error("Failed to fetch subcategories", error); // More descriptive
    }
  };

  useEffect(() => {
    if (userGlobalInfo?.token) {
      getCategories();
      getSubCategories();
    }
  }, [userGlobalInfo]);

  return (
    <categoriesContext.Provider
      value={{
        categories,
        setCategories,
        addCategories,
        subCategories, // Corrected naming
        addSubCategories,
        getcategorywithitsproducts,
      }}
    >
      {children}
    </categoriesContext.Provider>
  );
}
