import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MainSideNav from "../Navs/MainSideNav/MainSideNav";
import { useLocation } from "react-router-dom";
import SuperNav from "../Navs/MainSideNav/SuperNav";
import Nav from "../Home/Nav";
import Footer from "../Footer/Footer";
import Login from "../Home/ui/forms/Login";
import Register from "../Home/ui/forms/Register";
import NewPass from "../Home/ui/forms/NewPass";
import OtpForm from "../Home/ui/forms/OtpForm";
import ForgetPass from "../Home/ui/forms/ForgetPass";
import { UserContext } from "../../Context/UserInof";
import { motion } from "framer-motion";

export default function LayOuts() {
  const { currentForm } = useContext(UserContext);
  const location = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 991px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    const handleMediaChange = (event) => {
      setIsSmallScreen(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const CurrentFrom = () => {
    if (currentForm === "") return null;
    switch (currentForm) {
      case "login":
        return (
          <>
          
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              style={{
                position: "fixed",
                inset: "0",
                zIndex: "99999",
              }}
            >
              <Login />;
            </motion.div>
          </>
        );
      case "backToLogin":
        return <Login />;
      case "register":
        return <Register />;
      case "newPass":
        return <NewPass />;
      case "otpForm":
        return <OtpForm />;
      case "forgetPass":
        return <ForgetPass />;
      default:
        return null;
    }
  };

  if (
    location.pathname.split("/")[1] === "adminpanel" ||
    location.pathname.split("/")[1] === "AdminPanel"
  ) {
    return (
      <section className={`bgHome`}>
        <div className="container-fluid pe-2 ps-0 overflow-hidden ">
          <div className="d-flex">
            {/* Sidebar */}
            <div className="p-0 ">
              <MainSideNav />
            </div>
            {/* Main content */}
            <Outlet />
          </div>
        </div>
      </section>
    );
  }
  if (
    location.pathname.split("/")[1] === "superpanal" ||
    location.pathname.split("/")[1] === "SuperPanal"
  ) {
    return (
      <section className={`bgHome`}>
        <div className="container-fluid pe-2 ps-0 overflow-hidden ">
          <div className="d-flex">
            {/* Sidebar */}
            <div className="p-0 ">
              <SuperNav />
            </div>
            {/* Main content */}
            <Outlet />
          </div>
        </div>
      </section>
    );
  }

  if (
    location.pathname.split("/")[1] !== "login" &&
    location.pathname.split("/")[1] !== "superpanal" &&
    location.pathname.split("/")[1] !== "SuperPanal" &&
    location.pathname.split("/")[1] !== "adminpanel" &&
    location.pathname.split("/")[1] !== "AdminPanel" &&
    location.pathname.split("/")[1] !== "swiper"
  ) {
    return (
      <>
        <Nav />
        <Outlet />
        {location.pathname.split("/")[1] !== "productdetails" ? (
          <Footer />
        ) : isSmallScreen === false ? (
          <Footer />
        ) : null}

        <CurrentFrom />
      </>
    );
  }
  return (
    <>
      <Outlet />
    </>
  );
}
