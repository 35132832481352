import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "./UserInof";

export const mediaContext = createContext(null);

export default function MediaProvider({ children }) {
  const [media, setMedia] = useState([]);
  const { userGlobalInfo } = useContext(UserContext);

  const getMedia = async () => {
    try {
      const response = await axios.get("https://api.yafa-brand.com/metaData");
      if (response.status === 200) {
        // Explicit status check
        setMedia(response.data);
        console.log("response saccs");
        console.log(response);
        
      }
    } catch (error) {
      console.error("Failed to fetch media", error);
    }
  };

  const updateMedia = async (updatedMetaData) => {
    try {
      const response = await axios.patch(
        "https://api.yafa-brand.com/metaData",
        updatedMetaData,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      console.log("updated file ");
      console.log(response);
    return response
    } catch (error) {
      console.error("Failed to fetch media", error);
    }
  };

  useEffect(() => {
    getMedia();
  }, []);

  return (
    <mediaContext.Provider value={{ media, setMedia, updateMedia }}>
      {children}
    </mediaContext.Provider>
  );
}
