import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";

export const PopupContext = createContext(null);

export default function PopupProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [popup, setPopup] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  const getSubscribers = async () => {
    try {
      const { data } = await axios.get(
        "https://api.yafa-brand.com/popup/getsubscribers",
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      setSubscribers(data);
    } catch (error) {
      console.error("Failed to fetch subscribers", error);
    }
  };

  const getPopup = async () => {
    try {
      const { data } = await axios.get("https://api.yafa-brand.com/popup", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      });
      console.log("pup : ", data);

      setPopup(data);
    } catch (error) {
      console.error("Failed to fetch popup data", error);
    }
  };

  const addPopup = async (body) => {
    console.log(body);

    const data = axios.put("https://api.yafa-brand.com/popup", body);
    return data;
  };

  useEffect(() => {
    getSubscribers();
    getPopup();
  }, []);

  return (
    <PopupContext.Provider
      value={{
        popup,
        subscribers,
        setSubscribers,
        addPopup,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
}
