import { Link } from "react-router-dom";
import "./formsStyle.scss";
import formPhoto from "../../../../Images/formBg.png";
import { UserContext } from "../../../../Context/UserInof";
import { useContext, useState } from "react";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../api/axiosInstance";

const OtpForm = () => {
  const { setCurrentForm, setCode } = useContext(UserContext);
  let [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handelSubmit = async (Val) => {
    setLoading(true);
    setCode(Val);
    setCurrentForm("newPass");
    setLoading(false);
  };

  let validationSchema = yup.object({
    code: yup.string().required("OTP Code is required"),
  });

  const OtpForm = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: handelSubmit,
    validationSchema,
  });

  return (
    <div className="formPage">
      <div className="blurLay"></div>
      <div className="formContainer">
        <div className="imageSide">
          <img src={formPhoto} alt="formPhoto" />
        </div>
        <form onSubmit={OtpForm.handleSubmit} className="formSide">
          <motion.div
            initial={{ y: "-400px" }}
            animate={{
              y: "0px",
              transition: { delay: 0.1, duration: 0.8, type: "spring" },
            }}
            className="formTilte"
          >
            <h1>OTP Verification</h1>
            <p>
              Enter the verification code we just sent to your email address.
            </p>
          </motion.div>
          <span
            className="close"
            onClick={() => {
              setCurrentForm("");
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          <motion.div
            initial={{ x: "-600px" }}
            animate={{
              x: "0px",
              transition: { delay: 0.2, duration: 1, type: "spring" },
            }}
            className="inputContainer"
          >
            <input
              type="text"
              name="code"
              id="code"
              placeholder="Enter Verification Code"
              value={OtpForm.values.code} // Link input value to Formik
              onChange={OtpForm.handleChange}
              onBlur={OtpForm.handleBlur}
            />
            {OtpForm.errors.code && OtpForm.touched.code && (
              <span className="error">{OtpForm.errors.code}</span>
            )}
          </motion.div>

          <motion.div
            initial={{ x: "600px" }}
            animate={{
              x: "0px",
              transition: { delay: 0.3, duration: 1, type: "spring" },
            }}
            className="submitBtn"
          >
            <button type="submit" disabled={loading}>
              {loading ? (
                <i className="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Verify"
              )}
            </button>
          </motion.div>
          {errorMessage && <span className="error">{errorMessage}</span>}
        </form>
      </div>
    </div>
  );
};

export default OtpForm;
