import React from 'react'
import css from './style.module.css'
export default function Search() {
    return (
        <div className={`${css.search} d-flex justify-content-between align-items-center`}>
            <div className='d-flex flex-grow-1'>
                <i className="fa fa-search me-1 fa-fw  my-auto text-white"></i>
                <input type="text" className='bg-transparent form-control border-0 ' placeholder="Search" />
            </div>
            <div className='d-flex justify-content-between align-items-center '>
                <div className='position-relative m-3'>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_202_1018)">
                            <path d="M5.56799 16.25C5.85874 16.9185 6.33841 17.4876 6.94811 17.8873C7.5578 18.287 8.27096 18.5 8.99999 18.5C9.72903 18.5 10.4422 18.287 11.0519 17.8873C11.6616 17.4876 12.1412 16.9185 12.432 16.25H5.56799Z" fill="white" />
                            <path d="M16.7941 9.91176L15.4921 5.61951C15.0752 4.11827 14.1686 2.79934 12.9165 1.87213C11.6644 0.94493 10.1384 0.462597 8.58079 0.501717C7.02321 0.540837 5.52334 1.09917 4.31933 2.08806C3.11531 3.07696 2.27616 4.43973 1.9351 5.96001L0.9241 10.1128C0.789725 10.6646 0.782445 11.2397 0.902808 11.7947C1.02317 12.3498 1.26803 12.8702 1.61892 13.3168C1.9698 13.7634 2.41754 14.1245 2.92834 14.3727C3.43914 14.621 3.99966 14.75 4.5676 14.75H13.2053C13.7909 14.75 14.3683 14.6129 14.8914 14.3497C15.4144 14.0864 15.8685 13.7044 16.2174 13.2341C16.5662 12.7638 16.8001 12.2183 16.9002 11.6414C17.0004 11.0645 16.964 10.4721 16.7941 9.91176Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_202_1018">
                                <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className={`position-absolute text-center d-flex align-items-center bottom-50 start-50 ${css.notficationProp}`}>
                        <span className={`inter ${css.notficationNum} text-white`}>4</span>
                    </div>
                </div>
                <div className='m-3'>
                    <p className={`m-0 public-sans ${css.user}`}>Omar Hany</p>
                    <p className={`m-0 public-sans ${css.role}`}>Admin</p>
                </div>
                <div className='m-1'>
                    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.31051 0.75H9.68951C9.83782 0.750032 9.9828 0.794035 10.1061 0.876448C10.2294 0.958861 10.3255 1.07598 10.3823 1.21301C10.439 1.35003 10.4539 1.50081 10.4249 1.64627C10.396 1.79174 10.3246 1.92536 10.2198 2.03025L6.03026 6.21974C5.88961 6.36034 5.69888 6.43933 5.50001 6.43933C5.30114 6.43933 5.1104 6.36034 4.96976 6.21974L0.780259 2.03025C0.675402 1.92536 0.603997 1.79174 0.575072 1.64627C0.546146 1.50081 0.560998 1.35003 0.617751 1.21301C0.674503 1.07598 0.770608 0.958861 0.893915 0.876448C1.01722 0.794035 1.1622 0.750032 1.31051 0.75Z" fill="white" />
                    </svg>

                </div>
            </div>
        </div>
    )
}
