import React, { useState } from 'react';
import css from './style.module.css';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function ForgetPassword() {
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();
    const [userEmail, SetUserEmail] = useState({})
    const [spinner, setSpinner] = useState(false)
    const [error, setError] = useState(false)

    const handleClick = () => {
        setAnimate(true); // Trigger the fade-out animation
        navigate('/Verification');
    };

    const getUserEmail = (e) => {
        const { name, value } = e.target;
        SetUserEmail({ [name]: value });
    };
    const login = async () => {
        setSpinner(true)
        try {
            const response = await axios.post('https://api.yafa-brand.com/admin/forgotPassword', userEmail)
            setSpinner(false);
            if (response.status === 200) {
                handleClick();
            }
            else {
                console.log(response.data);
            }
        } catch (error) {
            setSpinner(false);
            setError(error.response.data.message)
        }
    }
    return (
        <section className={css.bgLogin}>
            <div className={`${css.layer}`}>
                <Logo />
                {/* Add fade-out animation on the main div when the button is clicked */}
                <div className={`position-absolute top-50 start-50 translate-middle  ${animate ? 'fadeOutAnimate' : ""}`}>
                    <div className={`${css.customizeMargin} mx-5 `}>
                        <p className={`prata-regular ${css.loginFontAtrr} text-center text-white mb-0 fadeInAnimate`}>
                            Forgot Password?
                        </p>
                        <p className={`work-sans ${css.WelcomFontAtrr} text-white mt-2 ms-4`}>
                            Don't worry! It occurs. <br />
                            Please enter the email address linked with your account.
                        </p>
                    </div>
                    <div className='  '>
                        <div className="work-sans d-flex justify-content-center align-items-start">
                            <div className='me-2 w-75'>
                                <input
                                    type='email'
                                    name='email'
                                    onChange={(e) => getUserEmail(e)}
                                    placeholder='Email Address'
                                    className={`inputWidthAnimate ${css.inputProps} `}
                                />
                            </div>
                            <div>
                                <button
                                    className='bg-transparent text-white rounded-0 text-center px-3'
                                    onClick={login}
                                >
                                    Send Code {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                                </button>
                            </div>
                        </div>

                        {/* Center the error message */}
                        {error && (
                            <div className="d-flex justify-content-center mt-2 px-4">
                                <p className='w-100 text-danger bg-danger-subtle rounded-1 p-2 fw-semibold text-center'>
                                    {error}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
