import React from 'react'
import Search from '../Search'
import css from '../style.module.css';
import BarChart from '../Charts/BarChart';
import PieChart from '../Charts/PieChart';
import RevenueTable from './RevenueTable';



export default function Revenue() {
    return (
        <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
            <Search />
            <div className='fadeInAnimate'>
                <div>
                    <p className={`${css.pad} ${css.textProp} inter m-0`}>Revenue</p>
                    <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                </div>
                {/* Charts */}
                <div className='d-flex justify-content-center align-items-center '>
                    <div style={{ width: "65%" }} className=' me-3'>
                        <BarChart />
                    </div>
                    <div style={{ width: "35%" }} className=''>
                        <PieChart />
                    </div>
                </div>
                {/* table */}
               <RevenueTable/>
            </div>

        </div>
    )


}
