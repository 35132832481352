import { Link } from "react-router-dom";
import "./formsStyle.scss";
import formPhoto from "../../../../Images/formBg.png";
import { useContext, useState } from "react";
import { UserContext } from "../../../../Context/UserInof";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../api/axiosInstance";

const ForgetPass = () => {
  const { setCurrentForm, setEmail } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");
  const handelSubmit = async (Val) => {
    setLoading(true); // Start loading when submission begins
    setEmail(Val.email);
    const req = await axiosInstance.post("/user/forgot", Val);
    if (req.status === 200 || req.status === 201) {
      setCurrentForm("otpForm");
    } else {
      setErrorMessage(req.data.message);
    }
    setLoading(false); // Stop loading when submission ends
  };

  let validationSchema = yup.object({
    email: yup
      .string()
      .required("Email Is Required")
      .email("Enter Valid Email"),
  });

  const forgetForm = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: handelSubmit,
    validationSchema,
  });
  return (
    <>
      <div className="formPage">
        <div className="blurLay"></div>
        <div className="formContainer">
          <div className="imageSide">
            <img src={formPhoto} alt="formPhoto" />
          </div>
          <form onSubmit={forgetForm.handleSubmit} className="formSide">
            <motion.div
              initial={{ y: "-400px" }}
              animate={{
                y: "0px",
                transition: { delay: 0.1, duration: 0.8, type: "spring" },
              }}
              className="formTilte"
            >
              <h1>Forget Password?</h1>
              <p>
                Don't worry! It occurs. Please enter the email address linked
                with your account.
              </p>
            </motion.div>
            <span
              className="close"
              onClick={() => {
                setCurrentForm("");
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
            <span
              className="back"
              onClick={() => {
                setCurrentForm("backToLogin");
              }}
            >
              <i class="fa-solid fa-arrow-left"></i>
            </span>
            <motion.div
              className="inputContainer"
              initial={{ x: "-600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.2, duration: 1, type: "spring" },
              }}
            >
              <label htmlFor="email">Email : </label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Enter Email"
                onChange={forgetForm.handleChange}
                onBlur={forgetForm.handleBlur}
              />
              {forgetForm.errors.email && forgetForm.touched.email && (
                <span className="error">{forgetForm.errors.email}</span>
              )}
            </motion.div>
            <motion.div
              initial={{ x: "600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.3, duration: 1, type: "spring" },
              }}
              className="submitBtn"
            >
              <button type="submit" disabled={loading}>
                {loading ? (
                  <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  "Send Code"
                )}
              </button>
            </motion.div>
            {errorMessage && <span className="error">{errorMessage}</span>}
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetPass;
