import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import axiosInstance from "../../api/axiosInstance";

export default function AboutUs() {
  const names = ["Mission", "vision", "Core Value", "Store History"];
  const [data, setData] = useState([]);
  const getData = async () => {
    const res = await axiosInstance.get("/metaData");
    setData(res.data);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <div className="row">
          <div className={``}>
            <p className={`${css.categoryHeader} archivo text-center`}>
              About Us
            </p>
          </div>

          <div className={`${css.cartBlock} py-4 px-4 mt-3 `}>
            <div className="row">
              <div className="col-md-12 ">
                <div>
                  <p
                    className={`${css.categoryHeader} archivo  fs-6 m-0text-center`}
                  >
                    {names[0]}
                  </p>
                </div>
                <div className="text-center">
                  <p className="m-0">{data.mission}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${css.cartBlock} py-4 px-4 mt-3 text-center`}>
            <div className="row">
              <div className="col-md-12 ">
                <div>
                  <p
                    className={`${css.categoryHeader} archivo  fs-6 m-0 text-center`}
                  >
                    {names[1]}
                  </p>
                </div>
                <div className="">
                  <p className="m-0">{data.vision}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${css.cartBlock} py-4 px-4 mt-3 text-center`}>
            <div className="row">
              <div className="col-md-12 ">
                <div>
                  <p
                    className={`${css.categoryHeader} archivo  fs-6 m-0 text-center`}
                  >
                    {names[2]}
                  </p>
                </div>
                <div className="">
                  <p className="m-0">{data.coreValues}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${css.cartBlock} py-4 px-4 mt-3 text-center`}>
            <div className="row">
              <div className="col-md-12 ">
                <div>
                  <p
                    className={`${css.categoryHeader} archivo  fs-6 m-0 text-center`}
                  >
                    {names[3]}
                  </p>
                </div>
                <div className="text-center">
                  <p className="m-0">{data.storeHistory}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
