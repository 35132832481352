import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import css from '../style.module.css';

const data = [
  { name: 'Jan', store1: 75000, store2: 100000 },
  { name: 'Feb', store1: 60000, store2: 72000 },
  { name: 'Mar', store1: 30000, store2: 125000 },
  { name: 'Apr', store1: 78000, store2: 39080 },
  { name: 'May', store1: 80000, store2: 90000 },
  { name: 'June', store1: 120000, store2: 250000 },
  { name: 'July', store1: 78000, store2: 80000 },
  { name: 'Aug', store1: 80000, store2: 125000 },
  { name: 'Sep', store1: 80000, store2: 90000 },
  { name: 'Oct', store1: 80000, store2: 100000 },
  { name: 'Nov', store1: 80000, store2: 110000 },
  { name: 'Dec', store1: 80000, store2: 80000 },
];

const getDynamicDomain = (data) => {
  const minValue = Math.floor(Math.min(...data.map(item => item.store2)) / 25000) * 25000; // Round down to nearest 25,000
  const maxValue = Math.ceil(Math.max(...data.map(item => item.store2)) / 25000) * 25000; // Round up to nearest 25,000
  return [minValue, maxValue];
};

const getTicks = (min, max) => {
  const ticks = [];
  for (let i = min; i <= max; i += 25000) {
    ticks.push(i);
  }
  return ticks;
};


export default function CustomBarChart() {
  const [minDomain, maxDomain] = getDynamicDomain(data); // Get dynamic domain values
  const yTicks = getTicks(minDomain, maxDomain); // Generate tick values

  return (
    <div className={`${css.barChart}`}>
      <div>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <p className={`m-0 lato ${css.headerFStyle} `}>Sales Analytics</p>
            <div className='d-flex justify-content-between '>
              <p className='m-0 '>
                <i className={`fas fa-circle fa-fw ${css.store1} me-1`}></i>
                <span className={`abeezee ${css.stresText}`}>Store1</span>
              </p>

              <p className='m-0 '>
                <i className={`fas fa-circle fa-fw  ${css.store2} me-1`}></i><span className={`abeezee  ${css.stresText}`}>Store2</span>
              </p>
            </div>
          </div>
          <div className={`d-flex align-items-center justify-content-between inter ${css.periodText}`}>
            <p className='px-2'>Daily</p>
            <p className='px-2'>Weekly</p>
            <p className='px-2'>Monthly</p>
            <p className={css.period}>Yearly</p>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart data={data}>
          <CartesianGrid vertical={false} horizontal={true} strokeDasharray="3 3" stroke="#ccc" />
          <XAxis dataKey="name" axisLine={false} stroke="#333" tick={{ fill: '#898989' }} />
          <YAxis
            domain={[minDomain, maxDomain]} // Set the Y-axis range dynamically
            ticks={yTicks} // Use dynamic tick values
            axisLine={false}
            tick={{ fill: '#898989' }} // Set the text color for the Y-axis labels
          />
          <Tooltip />
          <Bar dataKey="store1" fill="#8B442A" barSize={10} radius={[8, 8, 0, 0]} />
          <Bar dataKey="store2" fill="#BEAE99" barSize={10} radius={[8, 8, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
