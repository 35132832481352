import React from 'react';
import { motion } from 'framer-motion';

const Ticker = () => {
  const tickerItems = [
    'Breaking News!',
    'Stock Market Update',
    'Sports Scores',
    'Weather Forecast',
    'New Product Launch',
  ];

  const duplicatedItems = [...tickerItems, ...tickerItems]; // Duplicate content for seamless loop

  return (
    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      <motion.div
        className="ticker-track"
        style={{ display: 'inline-block' }}
        animate={{ x: ['100%', '-100%'] }} // Move from 100% to -100%
        transition={{
          repeat: Infinity,
          ease: 'linear',
          duration: 20, // Adjust speed
        }}
      >
        {duplicatedItems.map((item, index) => (
          <span key={index} style={{ marginRight: '50px' }}>
            {item}
          </span>
        ))}
      </motion.div>
    </div>
  );
};

export default Ticker;



