import { Link } from "react-router-dom";
import "./formsStyle.scss";
import formPhoto from "../../../../Images/formBg.png";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../Context/UserInof";
import { useFormik } from "formik";
import * as yup from "yup";
import { motion } from "framer-motion";
import axiosInstance from "../../../../api/axiosInstance";

const NewPass = () => {
  const { setCurrentForm, Code, setCode, email, setEmail } =
    useContext(UserContext);
  let [errorMessage, setErrorMessage] = useState("");
  let [loading, setLoading] = useState(false); // Set false initially
  let [theCode, setTheCode] = useState("");
  let [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    if (Code) {
      setTheCode(Code.code); // Set the code from context
      setUserEmail(email); // Set the email from context
    }
  }, [Code, email]);

  const handelSubmit = async (Val) => {
    setLoading(true); // Start loading when submission begins
    const finalData = {
      email: userEmail, // Email is now being sent from context
      password: Val.password, // Password is entered by the user in the form
    };
    let code = theCode;
    try {
      const req = await axiosInstance.patch(`/user/reset/${code}`, finalData); // Send data to API
      if (req.status === 200 || req.status === 201) {
        setCurrentForm("login");
        newPassForm.resetForm(); // Reset form after successful registration
        setEmail(""); // Clear email from context
        setCode(""); // Clear code from context
      } else {
        setErrorMessage("Reset failed. Please try again.");
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading after API call completes
    }
  };

  // Validation schema using Yup
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("password is required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "enter valid passowrd"
      ),
  });

  let newPassForm = useFormik({
    initialValues: {
      email: userEmail, // The email value is set from context here
      password: "",
    },
    validationSchema: validationSchema, // Add validation schema
    onSubmit: handelSubmit,
  });

  return (
    <>
      <div className="formPage">
        <div className="blurLay"></div>
        <div className="formContainer">
          <div className="imageSide">
            <img src={formPhoto} alt="formPhoto" />
          </div>
          <form onSubmit={newPassForm.handleSubmit} className="formSide">
            <motion.div
              initial={{ y: "-400px" }}
              animate={{
                y: "0px",
                transition: { delay: 0.1, duration: 0.8, type: "spring" },
              }}
              className="formTilte"
            >
              <h1>Create New Password</h1>
              <p>
                Your new password must be unique from those previously used.
              </p>
            </motion.div>
            <span
              className="close"
              onClick={() => {
                setCurrentForm("");
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </span>

            <motion.div
              initial={{ x: "-600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.3, duration: 1, type: "spring" },
              }}
              className="inputContainer"
            >
              <label htmlFor="email">Email: </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Email"
                value={userEmail}
                readOnly // Email is read-only since it's passed from context
              />
            </motion.div>

            <motion.div
              initial={{ x: "-600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.2, duration: 1, type: "spring" },
              }}
              className="inputContainer"
            >
              <label htmlFor="password">Password: </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Enter Password"
                onChange={newPassForm.handleChange}
                onBlur={newPassForm.handleBlur}
                value={newPassForm.values.password}
              />
              {newPassForm.touched.password && newPassForm.errors.password && (
                <div className="error">{newPassForm.errors.password}</div>
              )}
            </motion.div>

            <motion.div
              initial={{ x: "600px" }}
              animate={{
                x: "0px",
                transition: { delay: 0.4, duration: 1, type: "spring" },
              }}
              className="submitBtn"
            >
              <button type="submit" disabled={loading}>
                {loading ? (
                  <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  "Reset"
                )}
              </button>
            </motion.div>
            {errorMessage && <div className="error">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </>
  );
};

export default NewPass;
