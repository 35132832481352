import React, { useEffect, useState } from "react"
import css from "./style.module.css"
import "./styles/orderStyle.scss"
import axiosInstance from "../../api/axiosInstance"
import { useParams } from "react-router-dom"

export default function MyOrders() {
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState([])
  const { id } = useParams()

  const getOrders = async () => {
    if (id !== "user") {
      try {
        const req = await axiosInstance(`/order/${id}`)
        setOrder(req?.data)
        
      } catch (e) {
        setOrder(null)
      }
    } else {
      try {
        const req = await axiosInstance(`/order`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        })
        setOrders(req?.data?.orders)
      } catch (e) {
        setOrders([])
      }
    }
  }

  useEffect(() => {
    getOrders()
  }, [id])

  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <div className={`col-md-12 pe-5 desktop-padding-0 `}>
          <div className="cartText">
            <p
              className={`${css.categoryHeader} archivo text-center text-md-start`}
            >
              My Orders
            </p>
            {/* <p className="nunito p-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p> */}
          </div>
          <div className="ordersContainer">
            {orders?.length > 0 ? (
              <>
                {orders?.map((order, index) => (
                  <div key={index} className={`orderBox`}>
                    <div className="leftSide">
                      <div>
                        <img
                          src={
                            order?.items[0]?.productDetails?.variant
                              ?.images[0] &&
                            order?.items[0]?.productDetails?.variant?.images[0]
                          }
                          className={`${css.cartBlockImg}`}
                          alt=""
                        />
                      </div>
                      <div className="p-2">
                        <p className={`${css.categoryHeader}  fs-6 fw-normal`}>
                          Date of Purchase :{" "}
                          {new Date(order?.submittedAt).toLocaleDateString()}
                        </p>
                        <p className={`${css.categoryHeader}  fs-7 fw-normal`}>
                          {Number(order?.finalAmount).toFixed(3)}
                        </p>
                      </div>
                    </div>
                    <div className="trankingBox">
                      <span className="status">
                        <div
                          className={`statusIcon ${
                            order?.status === "pending"
                              ? "pending"
                              : order?.status === "confirmed"
                              ? "confirmed"
                              : "delivred"
                          }`}
                        ></div>
                        {order?.status === "pending"
                          ? "pending"
                          : order?.status === "confirmed"
                          ? "confirmed"
                          : "delivred"}
                      </span>
                      <span className="statusText">
                        Your item has been Shipped
                      </span>
                      <span className="rating">
                        <i className="fa-solid fa-star"></i> Rate & Review
                        Product{" "}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : order != null ? (
              <>
                <div className={`orderBox`}>
                  <div className="leftSide">
                    <div>
                      {order?.items?.length > 0 && (
                        <img
                          src={
                            order?.items[0]?.productDetails?.variant
                              ?.images[0] &&
                            order?.items[0]?.productDetails?.variant?.images[0]
                          }
                          className={`${css.cartBlockImg}`}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="p-2">
                      <p className={`${css.categoryHeader}  fs-6 fw-normal`}>
                        Date of Purchase :{" "}
                        {new Date(order?.submittedAt).toLocaleDateString()}
                      </p>
                      <p
                        className={`${css.categoryHeader}  fs-7 fw-normal`}
                        style={{ textAlign: "start" }}
                      >
                        {Number(order?.finalAmount).toFixed(3)}
                      </p>
                    </div>
                  </div>
                  <div className="trankingBox">
                    <span className="status">
                      <div
                        className={`statusIcon ${
                          order?.status === "pending"
                            ? "pending"
                            : order?.status === "confirmed"
                            ? "confirmed"
                            : "delivred"
                        }`}
                      ></div>
                      {order?.status === "pending"
                        ? "pending"
                        : order?.status === "confirmed"
                        ? "confirmed"
                        : "delivred"}
                    </span>
                    <span className="statusText">
                      Your item has been Shipped
                    </span>
                    <span className="rating">
                      <i className="fa-solid fa-star"></i> Rate & Review Product{" "}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <h1 style={{ width: "100%", textAlign: "center" }}>
                {" "}
                you don't have orders
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
