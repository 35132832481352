// src/api/axiosInstance.js
import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://api.yafa-brand.com", // Replace with your actual base URL
  timeout: 10000, // Optional: sets a timeout for requests
});

export default axiosInstance;
