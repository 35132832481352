import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import css from "./style.module.css";
import YoumayAlsoLike from "./YoumayAlsoLike";

export default function SelectAddress() {
  const location = useLocation(); // Get the location object
  const orderData = location.state?.orderData; // Access the order data passed from the previous page
  const [selectedOption, setSelectedOption] = useState("");
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <p className={`${css.categoryHeader} archivo `}>Check Out</p>
        <p className="nunito p-0">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <div>
          <p className={`${css.categoryHeader} archivo `}>
            Contact Information
          </p>
          <div className="w-50">
            <label htmlFor="email" className={`d-block ${css.labelCheckOut}`}>
              Email Address
            </label>
            <input
              type="text"
              placeholder="Your Email"
              className={`form-control ${css.inputCheckOut}`}
              name="email"
              id="email"
              value={orderData?.guestUser.email || ""} // Display email from order data if available
              readOnly // Optional: Make it read-only if you don't want to allow editing
            />
          </div>
        </div>
        <div>
          <p className={`${css.categoryHeader} archivo mb-3 mt-5 `}>
            Shipping Address
          </p>
          <Link className={css.SelectAddress} to={"/checkout"}>
            <p>Add New Address</p>
          </Link>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="mySelect">Address</label>
              <select
                id="mySelect"
                value={selectedOption}
                onChange={handleChange}
                className={`form-control ${css.inputCheckOut}`}
                name="country"
              >
                <option value="">Select</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
            </div>
            <div className="col-md-6">
              <div className="mt-1">
                <label
                  htmlFor="phone"
                  className={`d-block ${css.labelCheckOut}`}
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  placeholder="Add"
                  className={`form-control ${css.inputCheckOut}`}
                  name="phone"
                  id="phone"
                  value={orderData?.guestUser.phone || ""} // Display phone from order data if available
                  readOnly // Optional: Make it read-only if you don't want to allow editing
                />
              </div>
            </div>
            <Link
              to="/payment"
              state={{ orderData, selectedAddress: selectedOption }} // Pass the state to the payment route
              className="text-decoration-none text-dark text-end my-3"
            >
              <button className={`${css.btnContinue} text-center`}>
                Continue
              </button>
            </Link>
          </div>
        </div>
      </div>
      <YoumayAlsoLike />
    </section>
  );
}
