import React from 'react'
import css from './style.module.css';
import Edit from "../../Images/edit.svg";
import Delete from "../../Images/Delete.svg";
export default function MyAddress() {
    return (
        <section className='' style={{ marginTop: "120px" }}>
            <div className='container'>
                <div className="row">
                    <div className='cartText'>
                            <p className={`${css.categoryHeader} archivo text-center text-md-start`}>My Address</p>
                            <p className='nunito p-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    {Array(3).fill(null).map((_, index) => (
                        <div key={index} className={`${css.cartBlock} py-4 px-4 mt-3 `}>
                            <div className="row">
                                <div className="col-10 col-md-10">
                                    <div cla>
                                        <p className='m-0'>D/4, Amrut, Runwala Park Chembur Naka, Chembur, Mumbai, Maharashtra - 400071</p>
                                    </div>
                                </div>
                                <div className="col-2 col-md-2 ">
                                    <div className='d-flex justify-content-evenly align-items-center '>
                                        <img src={Edit} className='me-3' alt="" />
                                        <img src={Delete} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
