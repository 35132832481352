import React, { useContext, useMemo, useState, useEffect } from 'react';
import css from "../../style.module.css";
import { PermissionContext } from '../../../../Context/Permission';
import { adminContext } from '../../../../Context/Admin';

export default function AdminEdition({ setVisibleEdit, editAdmin }) {
    const { permission } = useContext(PermissionContext);
    const { updateAdmin } = useContext(adminContext);


    // Initialize state with the admin's current details
    const [data, setData] = useState({});
    const [selectedPermissionId, setSelectedPermissionId] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [dataEdittion, setDataEdittion] = useState({});

    // Effect to set initial values from editAdmin
    useEffect(() => {
        setData(editAdmin);
    }, [editAdmin]);

    const getCountryInfo = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setDataEdittion(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Memoizing country options

    // Memoizing permission options
    const permissionOptions = useMemo(() => {
        const permissions = permission?.permissionGroups || [];
        return permissions.map((permission) => (
            <option key={permission._id} value={permission._id}>
                {permission.name}
            </option>
        ));
    }, [permission]);

  
    const handleChangePermission = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setSelectedPermissionId(value);
        setData(prevState => ({
            ...prevState,
            [name]: value, // Update with permission group ID
        }));
        setDataEdittion(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const Submit = async (id) => {
      
        try {
            const response = await updateAdmin(id, dataEdittion); // Submit updated admin data
            if (response.status === 200) {
                setIsLoading(false);
                setMessage(response.data.message);
            }
            console.log(response.data);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            console.log(error.data.message || "error");
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Edit Admin</p>

            <input
                onChange={getCountryInfo}
                type="text"
                name="name"
                value={data.name || ''} // Use the data state
                className={`${css.adminInput} work-sans my-2`}
                placeholder="Name"
            />

            <input
                onChange={getCountryInfo}
                type="tel"
                className={`${css.adminInput} work-sans my-2`}
                placeholder="Phone Number"
                name='phone'
                pattern="[0-9]*"
                value={data.phone || ''} // Use the data state
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
            />


            <input
                onChange={getCountryInfo}
                type="email"
                name='email'
                value={data.email || ''} // Use the data state
                className={`${css.adminInput} work-sans my-2`}
                placeholder="E-mail"
            />

            <select
                value={selectedPermissionId}
                name='permissionGroup'
                onChange={handleChangePermission}
                className={`${css.adminInput} work-sans my-2`}
            >
                <option value="">{editAdmin.permissionGroup?.name || 'Select a permission group'}</option>
                {permissionOptions}
            </select>

            {message && <p className='text-center mt-3 text-danger fw-bold'>{message}</p>}

            {/* buttons */}
            <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="position-absolute" style={{ bottom: "6%" }}>
                    <button
                        className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`}
                        onClick={() => setVisibleEdit(false)}
                    >
                        <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Cancel
                    </button>
                    <button className={`${css.btnBoth} text-white`} onClick={()=> {Submit(editAdmin._id)}}>
                        <svg width="25" className="me-2" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Submit
                        {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                    </button>
                </div>
            </div>
        </nav>
    );
}
