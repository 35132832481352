import React, { useContext, useEffect, useState } from 'react'
import css from '../style.module.css';
import Search from '../Search';
import productImg from '../../../Images/Rectangle 7.png'
import ProductDetails from './ProductDetails';
import ProductNav from '../../Navs/SideNav/ProductNav';
import { productContext } from '../../../Context/product';
export default function Products() {
  const { product } = useContext(productContext)
  const [productData, setProductData] = useState({})
  const [details, setDetails] = useState(0)
  const [visible, setVisible] = useState(false);
  const [activePage, setActivePage] = useState('Category1');
  const [isLoading, setIsLoading] = useState(true);
  const [saleNav, setSaleNav] = useState(true);
  const [pagination, setPagination] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleNavigation = (page) => {
    setActivePage(page); // Set the active page based on the clicked link
  };


  console.log("product : ");
  console.log(product.products);

  useEffect(() => {
    if (product && product.products && Array.isArray(product.products) && product.products.length > 0) {
      setIsLoading(false);
      setProductData(product.products);
      setPagination(product.pagination || {}); // Fallback to an empty object if pagination is not defined
    }
  }, [product]);

  if (isLoading) {
    return (
      <p>
        Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
      </p>
    );
  }

  return (
    <>
      {/* Main content */}
      <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
        <Search />
        {details === 0 ? (
          <div className='fadeInAnimate'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <p className={`${css.pad} ${css.textProp} inter m-0`}>Products</p>
                <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
              </div>
              <button
                className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white d-flex justify-content-center align-items-center border-0`}
                onClick={() => setVisible(true)}
              >
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  {/* SVG content */}
                </svg>
                ADD NEW
              </button>
            </div>
            <div className={`bg-white ${css.mediaPages} w-100 d-flex overflow-hidden`}>
              <div onClick={() => handleNavigation('Category1')} className={`col-md-4 cursor my-auto d-flex justify-content-center ${activePage === 'Category1' ? css.activeMediaPages : ''}`}>
                <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Category1' ? 'text-white fst-normal' : ''}`}>Category1</p>
              </div>
              <div onClick={() => handleNavigation('Category2')} className={`col-md-4 cursor my-auto d-flex justify-content-center ${activePage === 'Category2' ? css.activeMediaPages : ''}`}>
                <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Category2' ? 'text-white fst-normal' : ''}`}>Category2</p>
              </div>
              <div onClick={() => handleNavigation('Category3')} className={`col-md-4 cursor my-auto d-flex justify-content-center ${activePage === 'Category3' ? css.activeMediaPages : ''}`}>
                <p className={`m-0 archivo ${css.mediaPagesFont} ${activePage === 'Category3' ? 'text-white fst-normal' : ''}`}>Category3</p>
              </div>
            </div>
            <div className="container-fluid mt-2">
              <div className="row">
                {productData.map((item, index) => (
                  <div key={index} className="col-md-6 px-3 my-2" onClick={() => { setSelectedProduct(item); setDetails(1); }}>
                    <div className={`row ${css.productContainer} h-100`}>
                      <div className='col-md-4 '>
                        <img src={item.images[0]} alt="" className='w-100' />
                      </div>
                      <div className='col-md-8 position-relative'>
                        <p className={`inter ${css.Internalwriting}`}>Name <span className='fw-bold'>{item.name}</span></p>
                        <p className={`inter ${css.Internalwriting}`}>Category <span className='fw-bold'>{item.category ? item.category.name : "N/A"}</span></p>
                        <p className={`inter ${css.Internalwriting}`}>Price <span className='fw-bold'>{item.price} EGP</span></p>
                        <button className={`position-absolute bottom-0 end-0 mt-1 ${css.btnProduct}`} onClick={()=>setSaleNav(true)}>
                          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.21318 10.68C6.08651 10.68 5.95984 10.6334 5.85984 10.5334C5.66651 10.34 5.66651 10.02 5.85984 9.82669L10.2265 5.46003C10.4198 5.2667 10.7398 5.2667 10.9332 5.46003C11.1265 5.65337 11.1265 5.97336 10.9332 6.1667L6.56651 10.5334C6.46651 10.6334 6.33984 10.68 6.21318 10.68Z" fill="white" />
                            <path d="M6.487 7.40669C5.76033 7.40669 5.16699 6.81337 5.16699 6.0867C5.16699 5.36003 5.76033 4.76669 6.487 4.76669C7.21367 4.76669 7.80699 5.36003 7.80699 6.0867C7.80699 6.81337 7.21367 7.40669 6.487 7.40669ZM6.487 5.77337C6.31367 5.77337 6.16699 5.91337 6.16699 6.09337C6.16699 6.27337 6.307 6.41336 6.487 6.41336C6.667 6.41336 6.80699 6.27337 6.80699 6.09337C6.80699 5.91337 6.66033 5.77337 6.487 5.77337Z" fill="white" />
                            <path d="M10.8464 11.2267C10.1197 11.2267 9.52637 10.6333 9.52637 9.90666C9.52637 9.17999 10.1197 8.58667 10.8464 8.58667C11.573 8.58667 12.1664 9.17999 12.1664 9.90666C12.1664 10.6333 11.573 11.2267 10.8464 11.2267ZM10.8464 9.59334C10.673 9.59334 10.5264 9.73333 10.5264 9.91333C10.5264 10.0933 10.6664 10.2333 10.8464 10.2333C11.0264 10.2333 11.1664 10.0933 11.1664 9.91333C11.1664 9.73333 11.0264 9.59334 10.8464 9.59334Z" fill="white" />
                            <path d="M8.49967 15.1667C4.54634 15.1667 1.33301 11.9533 1.33301 8.00001C1.33301 4.04668 4.54634 0.833344 8.49967 0.833344C12.453 0.833344 15.6663 4.04668 15.6663 8.00001C15.6663 11.9533 12.453 15.1667 8.49967 15.1667ZM8.49967 1.83334C5.09967 1.83334 2.33301 4.60001 2.33301 8.00001C2.33301 11.4 5.09967 14.1667 8.49967 14.1667C11.8997 14.1667 14.6663 11.4 14.6663 8.00001C14.6663 4.60001 11.8997 1.83334 8.49967 1.83334Z" fill="white" />
                          </svg>
                        </button>
                      </div>
                      <div className={`d-flex align-items-center ${css.productBanar} mt-2`}>
                        <div className='w-50 d-flex justify-content-between align-items-center'>
                          <p className='m-0'>Sales</p>
                          <p className='d-flex align-items-center my-auto'>
                            <svg width="11" height="12" className='me-2' viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.25 5.625L5.75 1.125L10.25 5.625M5.75 1.75V10.875" stroke="#FFA52F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {item.sale_percentage || 0} %
                          </p>
                          <span className={css.middelBar}></span>
                        </div>
                        <div className='w-50'>
                          <div className='d-flex justify-content-between align-items-center'>
                            <p className='m-0'>Remaining</p>
                            <p className='d-flex align-items-center my-auto'>
                              <div className={css.counterbar}>
                                <p style={{ width: "70%", height: "100%", backgroundColor: "#FFA52F", borderRadius: "8px" }}></p>
                              </div>
                              {item.variants.reduce((total, variant) => total + variant.stock, 0)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className='fadeInAnimate'>
            <ProductDetails setDetails={setDetails} productData={selectedProduct} />
          </div>
        )}
      </div>
      {/* SideBar */}
      {visible && (
        <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'}`}>
          <ProductNav setVisible={setVisible} visible={visible} />
        </div>
      )}
    </>

  )
}


/*
   
*/ 