import "./storiesStyle.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import axiosInstance from "../../../../api/axiosInstance"
import { useEffect, useState } from "react"
const Stories = () => {
  const [stories, setStories] = useState()
  const getStories = async () => {
    const req = await axiosInstance.get("/story")
    setStories(req?.data)
  }

  useEffect(() => {
    getStories()
  }, [])

  return (
    <>
      <div className="stories w-100">
        <div className="text-center mb-4">
          <h2 className="mb-2 mainTitle">Stories</h2>
          <p>Discover our latest products and experiences</p>
        </div>
        <div>
          <div>
            <Swiper
              className="py-2 mobileFont"
              direction="horizontal"
              spaceBetween={10}
              //   breakpoints={breakpoints}
              slidesPerView={4}
              loop={true}
              centeredSlides={true}
              grabCursor={true}
            >
              {stories &&
                stories.map((story, index) => (
                  <>
                    <SwiperSlide key={index}>
                      <img
                        style={{
                          width: "100%",
                          aspectRatio: "1.5/2",
                        }}
                        src={story.media}
                        alt="story"
                      />
                    </SwiperSlide>
                  </>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="storiesTwo w-100">
        <div className="text-center mb-1">
          <h6
            className=""
            style={{ textAlign: "left", fontWeight: "700", color: "#8B442A" }}
          >
            Stories
          </h6>
          {/* <p>Discover our latest products and experiences</p> */}
        </div>
        <div>
          <div>
            <Swiper
              className="py-2 mobileFont"
              // direction="horizontal"
              spaceBetween={10}
              //   breakpoints={breakpoints}
              slidesPerView={2}
              loop={true}
              // centeredSlides={true}
              grabCursor={true}
            >
              {stories &&
                stories.map((story, index) => (
                  <>
                    <SwiperSlide key={index}>
                      <img
                        style={{
                          width: "100%",
                          aspectRatio: "1.5/2",
                        }}
                        src={story.media}
                        alt="story"
                      />
                    </SwiperSlide>
                  </>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default Stories
