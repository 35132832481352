import React, { createContext, useContext, useEffect, useState } from "react"
import Search from "../Search"
import css from "../style.module.css"
import axiosInstance from "../../../api/axiosInstance"
import { UserContext } from "../../../Context/UserInof" // Corrected import
// import moment from "moment";

export const categoriesContext = createContext(null)

export default function Users() {
  const { userGlobalInfo } = useContext(UserContext)
  const [openIndexes, setOpenIndexes] = useState([])
  const [users, setUsers] = useState([])

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/admin/users", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      })
      setUsers(response.data.users)
    } catch (error) {
      console.error("Error fetching users:", error)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const toggleOpenIndex = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    )
  }

  // const formatDate = (dateString) => {
  //     return moment(dateString).format("MMM DD, YYYY");
  // }

  const handleBanUser = async (userId) => {
    try {
      const response = await axiosInstance.post(
        `/admin/banUser/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      )
    } catch (error) {
      console.error("Error banning user:", error)
      // Handle error (e.g., show a notification to the user)
    }
  }

  return (
    <div
      className="flex-grow-1 px-3"
      style={{ transition: "margin-left 0.3s" }}
    >
      <Search />
      <div className="fadeInAnimate">
        <div>
          <p className={`${css.pad} ${css.textProp} inter m-0`}>Users</p>
          <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
        </div>
        <div className="row">
          {users.map((user, firstIndex) => (
            <div
              key={firstIndex}
              className={`${css.permissionGroupsProp} bg-white my-2 cursor`}
              onClick={() => toggleOpenIndex(firstIndex)} // Toggle visibility
            >
              <div className="d-flex justify-content-between align-items-center ">
                <p
                  className={`inter ${css.permissionGroupsText} m-0 inter fw-semibold`}
                  style={{ lineHeight: "19.36px" }}
                >
                  {user.firstName} {user.lastName}
                </p>
                {openIndexes.includes(firstIndex) ? (
                  <i className="fa-solid fa-angle-down"></i>
                ) : (
                  <i className="fa-solid fa-angle-up"></i>
                )}
              </div>
              <div
                className="slidingContentAnimate"
                style={{
                  maxHeight: openIndexes.includes(firstIndex) ? "300px" : "0px", // Adjust max height
                  opacity: openIndexes.includes(firstIndex) ? 1 : 0,
                  transition: "max-height 0.4s ease, opacity 0.4s ease",
                  overflow: "hidden",
                }}
              >
                <div className="row mt-2">
                  <div className="col-md-6">
                    <p className={`inter ${css.Internalwriting}`}>
                      Email <span className="fw-bold">{user.email}</span>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className={`inter ${css.Internalwriting}`}>
                      Address <span className="fw-bold">Egypt, Cairo</span>
                    </p>
                  </div>
                  {user.orders.length === 0 ? (
                    <div className="col-12">
                      <p
                        className={`inter ${css.Internalwriting} fw-bold text-center`}
                      >
                        No orders yet
                      </p>
                    </div>
                  ) : (
                    user.orders.map((order, index) => (
                      <React.Fragment key={index}>
                        <div className="col-md-3">
                          <p className={`inter ${css.Internalwriting}`}>
                            Order ID{" "}
                            <span className="fw-bold">{order._id}</span>
                          </p>
                        </div>
                        {/* <div className="col-md-3"><p className={`inter ${css.Internalwriting}`}>Date <span className='fw-bold'>{formatDate(order.submittedAt)}</span></p></div> */}
                        <div className="col-md-3">
                          <p className={`inter ${css.Internalwriting}`}>
                            Status{" "}
                            <span className="fw-bold">{order.status}</span>
                          </p>
                        </div>
                        <div className="col-md-3">
                          <p className={`inter ${css.Internalwriting}`}>
                            Amount{" "}
                            <span className="fw-bold">
                              {order.finalAmount.toFixed(2)}
                            </span>
                          </p>
                        </div>
                        {/* <div className="col-md-3"><p className={`inter ${css.Internalwriting}`}>Address <span className='fw-bold'>{order.address}</span></p></div> */}
                      </React.Fragment>
                    ))
                  )}
                </div>
                {userGlobalInfo.admin.name !== "Yafa Owners" && (
                  <div
                    className="text-end mt-1"
                    onClick={() => handleBanUser(user._id)}
                  >
                    <svg
                      width="48"
                      height="40"
                      viewBox="0 0 48 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="48"
                        height="40"
                        rx="8"
                        fill={
                          user.banned
                            ? "#8B442A"
                            : "#1d7829" /* if user is banned make it red, else make it green*/
                        }
                      />
                      <path
                        d="M26.3929 26.4401C26.2662 26.4401 26.1395 26.3934 26.0395 26.2934C25.8462 26.1001 25.8462 25.7801 26.0395 25.5868L27.9195 23.7068C28.1129 23.5134 28.4329 23.5134 28.6262 23.7068C28.8195 23.9001 28.8195 24.2201 28.6262 24.4134L26.7462 26.2934C26.6462 26.3934 26.5195 26.4401 26.3929 26.4401Z"
                        fill="white"
                      />
                      <path
                        d="M28.2729 26.4401C28.1462 26.4401 28.0195 26.3934 27.9195 26.2934L26.0395 24.4134C25.8462 24.2201 25.8462 23.9001 26.0395 23.7068C26.2329 23.5134 26.5529 23.5134 26.7462 23.7068L28.6262 25.5868C28.8195 25.7801 28.8195 26.1001 28.6262 26.2934C28.5262 26.3934 28.3995 26.4401 28.2729 26.4401Z"
                        fill="white"
                      />
                      <path
                        d="M24.1057 19.7466C24.0857 19.7466 24.0724 19.7466 24.0524 19.7466C24.0191 19.7399 23.9724 19.7399 23.9324 19.7466C21.9991 19.6866 20.5391 18.1666 20.5391 16.2933C20.5391 14.3866 22.0924 12.8333 23.9991 12.8333C25.9057 12.8333 27.4591 14.3866 27.4591 16.2933C27.4524 18.1666 25.9857 19.6866 24.1257 19.7466C24.1191 19.7466 24.1124 19.7466 24.1057 19.7466ZM23.9991 13.8333C22.6457 13.8333 21.5391 14.9399 21.5391 16.2933C21.5391 17.6266 22.5791 18.6999 23.9057 18.7466C23.9457 18.7399 24.0324 18.7399 24.1191 18.7466C25.4257 18.6866 26.4524 17.6133 26.4591 16.2933C26.4591 14.9399 25.3524 13.8333 23.9991 13.8333Z"
                        fill="white"
                      />
                      <path
                        d="M24.0006 27.04C22.6406 27.04 21.3539 26.6866 20.3806 26.0333C19.4539 25.4133 18.9473 24.5666 18.9473 23.6533C18.9473 22.74 19.4606 21.9 20.4473 21.9C21.434 21.9 21.9473 22.74 21.9473 23.6533C21.9473 24.5666 21.4406 25.4133 20.5139 26.0333C19.5259 26.652 18.2146 27.04 16.8456 27.04C15.686 27.04 14.5826 26.7466 13.6793 26.2266C12.786 25.7066 12.4673 25.0466 12.4673 24.2533C12.4673 23.46 12.9359 22.74 14.1193 22.74C15.3413 22.74 15.9639 23.46 15.9639 24.2266C15.9639 24.9733 15.2733 25.5733 14.1193 25.5733C13.6493 25.5733 13.3293 25.8266 13.3293 26.0666C13.3293 26.2933 13.794 26.74 15.794 27.2266C16.104 27.3466 16.5973 27.4533 17.0806 27.4533C18.4273 27.4533 19.4273 27.04 20.1739 26.6866C20.8773 26.3533 21.4546 25.96 22.4139 25.96C23.0146 25.96 23.406 26.1333 23.8139 26.2666C24.0139 26.3533 24.106 26.3666 24.1739 26.3666C24.1726 26.48 24.0006 27.04 24.0006 27.04Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
