import { useContext, useEffect, useState } from "react"
import "./productCardStyle.scss"
import { productContext } from "../../../../Context/product"
import { useNavigate } from "react-router-dom"
import productDesImg from "../../../../Images/1 (1).png"
import axios from "axios"
import axiosInstance from "../../../../api/axiosInstance"
import { UserContext } from "../../../../Context/UserInof"
import css from "../../style.module.css"

const ProductCard = ({ productData }) => {
  const [image, setImage] = useState(null)
  const [isFavorites, setIsFavorites] = useState(false)
  const [colors, setColors] = useState([])
  const { handelAddFav } = useContext(productContext)
  const navg = useNavigate()
  const { isUser, userGlobalInfo } = useContext(UserContext)

  const handelGoToProduct = (e) => {
    const id = productData._id

    const varId = productData?.variants ? productData?.variants[0]?._id : "1"
    if (e?.target?.tagName?.toUpperCase() === "I") {
      handelAddFav(e.target, id, varId)
      return
    } else {
      navg(`/productdetails/${id}`)
    }
  }
  const getColors = async (inputColors) => {
    try {
      const response = await axios.get("https://api.yafa-brand.com/color")
      const availableColors = response.data.colors
      const availableColorIds = availableColors.map((color) => color._id)
      const filteredColorIds = inputColors.filter((colorId) =>
        availableColorIds.includes(colorId)
      )
      const finalColors = availableColors.filter((color) =>
        filteredColorIds.includes(color._id)
      )

      setColors(finalColors)
    } catch (error) {
      console.error("Error fetching colors:", error)
    }
  }

  const handelIsFavorite = async () => {
    if (isUser) {
      const req = await axiosInstance.get("/user/wishlist", {
        headers: {
          Authorization: `Bearer ${userGlobalInfo.token}`,
        },
      })
      const favoriteProducts =
        req?.data?.wishlist?.filter(
          (product) =>
            product.productId === productData?._id || productData.productId
        ) || []

      if (favoriteProducts.length > 0) {
        setIsFavorites(true)
      } else {
        setIsFavorites(false)
      }
    } else {
      const favoriteProducts =
        JSON.parse(localStorage.getItem("favorites")) || []
      if (
        favoriteProducts.find(
          (product) => product.productId === productData?._id
        )
      ) {
        setIsFavorites(true)
      } else {
        setIsFavorites(false)
      }
    }
  }

  useEffect(() => {
    if (productData?.images) {
      setImage(productData?.images[0])
    } else {
      setImage(productDesImg)
    }
  }, [productData?.images])

  useEffect(() => {
    handelIsFavorite()
    const colors = productData?.variants?.map((c) => c.color)
    getColors(colors)
  }, [productData])
  const [hovered, setHovered] = useState(false)

  return (
    <div className="productCard" onClick={(e) => handelGoToProduct(e)}>
      <div className="addToFav">
        <i
          className={`${isFavorites ? "fa-solid" : "fa-regular"} fa-heart `}
        ></i>
      </div>
      <div className="d-flex w-100 pt-2 position-relative cursor">
        <div className="imageBox">
          <img
            src={
              hovered
                ? productData?.images[0] // Image to show on hover
                : productData?.images[1] // Default image
            }
            style={{
              width: "100%",
              aspectRatio: "2/3",
            }}
            alt=""
            onMouseEnter={() => setHovered(true)} // Trigger on hover
            onMouseLeave={() => setHovered(false)} // Revert when hover ends
          />
        </div>
      </div>
      <div className="info">
        <p style={{ marginBottom: "8px" }}>{productData?.name}</p>
        <span
          className={
            productData?.variants[0].is_on_sale &&
            `m-0 archivo ${css.strikethrough}`
          }
          style={{
            fontSize: "12px",
            color: !productData?.variants[0].is_on_sale && "#8B442A",
          }}
        >
          {productData?.price}L.E.
        </span>
        {productData?.variants[0].is_on_sale && (
          <span
            style={{ fontSize: "12px", paddingLeft: "10px", color: "#8B442A" }}
          >
            {productData?.price -
              (productData?.variants[0].sale_percentage * productData?.price) /
                100}{" "}
          </span>
        )}
      </div>
      <div className="cardDetails">
        <div>
          {productData?.description && (
            <p
              className="fw-semibold archivo mb-0"
              style={{ fontSize: "13px" }}
            >
              {productData?.description?.split(" ").slice(0, 6).join(" ") +
                " ..."}
            </p>
          )}
          {/* <div className="d-flex">
            {colors?.map((color, idx) => (
              <>
                <div
                  key={idx}
                  className={`circleColor  me-1`}
                  style={{ margin: "5px 0 0", backgroundColor: `${color.hex}` }}
                ></div>
              </>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
