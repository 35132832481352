import React, { useState } from 'react'
import css from '../style.module.css';

export default function RevenueTable() {
    const [active, setActive] = useState(0);

    return (
        <div className={`bg-white p-4 my-3  rounded-2`}>
            <p className='text-gray lato fw-bold' >Best Selling Products</p>
            <table className="table custom-table">
                <thead className='lato fw-normal'>
                    <tr>
                        <th scope="col">Product ID</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total Sales</th>
                        <th scope="col">Stock</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>#11232</td>
                        <td>Name</td>
                        <td>400.00 EGP</td>
                        <td>1000</td>
                        <td>12</td>
                        <td>In Stock</td>
                    </tr>
                    <tr>
                        <td>#11232</td>
                        <td>Name</td>
                        <td>400.00 EGP</td>
                        <td>1000</td>
                        <td>12</td>
                        <td>In Stock</td>
                    </tr>
                    <tr>
                        <td>#11232</td>
                        <td>Name</td>
                        <td>400.00 EGP</td>
                        <td>1000</td>
                        <td>12</td>
                        <td>In Stock</td>
                    </tr>
                </tbody>
            </table>
            <div className='d-flex justify-content-center  '>
                <div className="w-25 d-flex justify-content-between">
                    <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(0)}>
                        <i className="fa-solid fa-angles-left"></i>
                    </div>
                    <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(active ===0 ? active + 2  : active - 1)}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </div>

                    {/* Map through the array and set active state based on index */}
                    {Array(3).fill(null).map((_, index) => (
                        <div
                            key={index}
                            onClick={() => setActive(index)}
                            className={`${css.pages} ${css.pagesShadow} ${active === index ? `${css.activePage} text-white` : "bg-white text-dark"}  d-flex justify-content-center align-items-center cursor`}
                        >
                            <span>{index + 1}</span>
                        </div>
                    ))}
                    <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(active < 2 ? active + 1 : active - 2)}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    <div className={`${css.pages} bg-white cursor d-flex justify-content-center align-items-center text-dark`} onClick={() => setActive(2)}>
                        <i className="fa-solid fa-angles-right"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

