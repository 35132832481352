import React, { useContext, useState } from "react"
import css from "./style.module.css"
import { useNavigate } from "react-router-dom"
import Logo from "./Logo"
import axios from "axios"
import { UserContext } from "../../Context/UserInof"
import { Row, Col } from "react-bootstrap"
export default function Login() {
  const { setUserGlobalInfo } = useContext(UserContext)
  const [animate, setAnimate] = useState(false)
  const navigate = useNavigate()
  const [userInfo, SetUserInfo] = useState({})
  const [spinner, setSpinner] = useState(false)
  const [error, setError] = useState(false)

  const handleClick = () => {
    setAnimate(true)
    navigate("/ForgetPassword")
  }
  const Navigator = (role) => {
    if (role === "superadmin") navigate("/superpanal/dashboard")
    else navigate("/adminpanel/dashboard")
    setAnimate(true)
  }
  const getUserInfo = (e) => {
    const { name, value } = e.target

    SetUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const login = async () => {
    setSpinner(true)
    try {
      const { data, status } = await axios.post(
        "https://api.yafa-brand.com/admin/login",
        userInfo
      )
      setSpinner(false)
      if (status === 200) {
        console.log(data)
        setUserGlobalInfo(data)
        Navigator(data.admin.role)
      }
    } catch (error) {
      setError(error.response.data.message)
      setSpinner(false)
    }
  }
  return (
    <section className={`position-relative ${css.bgLogin}`}>
      <div className={css.layer}>
        <Logo />
        <div
          className={`position-absolute top-50 start-50 translate-middle fadeInAnimate`}
        >
          <div className={css.customizeMargin}>
            <p
              className={`prata-regular ${
                css.loginFontAtrr
              } text-center text-white mb-0 ${animate ? "fadeOutAnimate" : ""}`}
            >
              LOGIN
            </p>
            <p
              className={`work-sans ${css.WelcomFontAtrr} text-center text-white mt-2`}
            >
              Welcome Back, Owner
            </p>
          </div>

          <div
            className={`work-sans d-flex justify-content-center align-items-start`}
          >
            <Row>
              <Col xs={12} md={4}>
                <div className="me-2 ">
                  <input
                    onChange={(e) => getUserInfo(e)}
                    className={css.inputProps}
                    type="email"
                    placeholder="Email Address"
                    name="email"
                  />
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="me-2 ">
                  <input
                    className={css.inputProps}
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => getUserInfo(e)}
                  />
                  <p
                    className={`${css.passwordForget} ${
                      animate ? "fadeOutAnimate" : ""
                    } d-flex justify-content-end mb-0 mt-2 cursor `}
                    onClick={handleClick}
                  >
                    Forgot Your Password?
                  </p>
                </div>
              </Col>
              <div>
                <button
                  onClick={login}
                  className={`bg-transparent text-white rounded-0 text-center ${
                    animate ? "fadeOutAnimate" : ""
                  }`}
                >
                  Login
                  {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
              </div>
            </Row>
          </div>
          {error && (
            <p className="text-danger bg-danger-subtle w-100 rounded-1 p-2 mt-2 fw-semibold">
              {error}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}
