import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import axiosInstance from "../../api/axiosInstance";

export default function AboutUs() {
  const names = ["Mission", "Vision", "Core Value", "Store History", "Terms and Conditions"];
  const [data, setData] = useState({});
  
  const getData = async () => {
    const res = await axiosInstance.get("/metaData");
    setData(res.data);
  };
  
  useEffect(() => {
    getData();
  }, []);
  
  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <div className="row">
          <div className={``}>
            <p className={`${css.categoryHeader} archivo text-center`}>About Us</p>
          </div>
                    
          {/* Terms and Conditions Section */}
          <div className={`${css.cartBlock} py-4 px-4 mt-3 text-start`}>
            <div className="row">
              <div className="col-md-12">
                <p className={`${css.categoryHeader} archivo fs-6 m-0 text-center`}>{names[4]}</p>
                <div className="m-0">
                  <p><strong>Return and Exchange Procedures</strong></p>
                  <p><u>How can I return the product or products in my order?</u></p>
                  <ul>
                    <li>If you are not completely satisfied, you can return items within 15 days, undamaged and unused.</li>
                    <li>Products must be returned unused, in original packaging, with barcodes.</li>
                    <li>Include all free/promotional items with the return.</li>
                    <li>Provide a return reason on the invoice and send with the products.</li>
                    <li>Ensure sturdy and waterproof packaging; responsibility remains with the customer until delivery.</li>
                    <li>Return packages via Yurtiçi Kargo, using the code for a free return.</li>
                  </ul>

                  <p>Packages sent as cash on delivery or from unapproved companies are not accepted.</p>

                  <p><u>Under what conditions can I return products in my order?</u></p>
                  <ul>
                    <li>Return within 15 days of receipt.</li>
                    <li>Products must be unused, with undamaged labels.</li>
                  </ul>

                  <p><u>Return Period</u></p>
                  <ul>
                    <li>Return within 15 days from the receipt date.</li>
                  </ul>

                  <p><u>Free Shipping Campaign Cancellation</u></p>
                  <ul>
                    <li>If the return cancels the free shipping offer, a 25 TL fee will be deducted from the refund.</li>
                  </ul>

                  <p><u>Return Shipping Costs</u></p>
                  <ul>
                    <li>Non-defective returns incur a 9.99 TL fee; for defective items, costs are covered by Yafa Brand.</li>
                  </ul>

                  <p><u>Cash on Delivery Returns</u></p>
                  <ul>
                    <li>Fill out the return form on the invoice and send your IBAN to info@yafabrand.com.tr.</li>
                  </ul>

                  <p><u>Refund Process</u></p>
                  <ul>
                    <li>Refunds are processed within 7 days after product inspection. Credit card refunds depend on your bank.</li>
                  </ul>

                  <p><u>Incorrect or Defective Products</u></p>
                  <ul>
                    <li>Send incorrect or defective items back with cash on delivery. Refunds follow the original payment method.</li>
                  </ul>

                  <p><u>Product Exchange</u></p>
                  <p>Product exchange is not possible.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
