import React, { useContext, useEffect, useState } from 'react'
import { categoriesContext } from '../../../Context/categories';
import css from '../style.module.css'
export default function Sub() {
    const { subCategories } = useContext(categoriesContext);
    console.log("subCategories");
    const [isLoading, setIsLoading] = useState(true);
    const [sub, setSub] = useState(true);


    useEffect(() => {
        if (subCategories?.length > 0) {
            setIsLoading(false);
            setSub(subCategories);
        }
    }, [subCategories]);
    if (isLoading) {
        return (
            <p>
                Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
            </p>
        );
    }

    
    return (
        <>

            <div className="row justify-content-between" >
                {sub.map((item, index) => (
                    <div key={index} className="col-md-4 ">
                        <div className={`mt-3 mb-2 d-flex justify-content-between align-items-center ${css.createdDataBars}`}>
                            <p className={`m-0 inter ${css.permissionGroupsText}`}>{item.name}</p>
                            <div className="d-flex align-items-center">
                                <svg width="28" height="28" className="me-3" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="28" rx="8" fill="#8B442A" />
                                    <path d="M14 19.3334H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 8.33322C17.2652 8.068 17.6249 7.91901 18 7.91901C18.1857 7.91901 18.3696 7.95559 18.5412 8.02666C18.7128 8.09773 18.8687 8.2019 19 8.33322C19.1313 8.46454 19.2355 8.62044 19.3066 8.79202C19.3776 8.9636 19.4142 9.1475 19.4142 9.33322C19.4142 9.51894 19.3776 9.70284 19.3066 9.87442C19.2355 10.046 19.1313 10.2019 19 10.3332L10.6667 18.6666L8 19.3332L8.66667 16.6666L17 8.33322Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                               
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    )

}
