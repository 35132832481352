import React, { useContext, useMemo, useState } from 'react';
import css from "../style.module.css";
import axios from 'axios';
import { colorContext } from '../../../Context/color';
import { UserContext } from '../../../Context/UserInof';

export default function AddVariant({ setAddVaraintState, productDetails }) {
    console.log("singlevariantsData:", productDetails);
    const { userGlobalInfo } = useContext(UserContext);
    const { color } = useContext(colorContext);

    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState({});
    const [stockData, setStockData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSize, setSelectedSize] = useState('');
    const [error, setError] = useState('');

    const getStock = (e) => {
        const { name, value } = e.target;
        setStockData((prevStockData) => ({
            ...prevStockData,
            [name]: Number(value),
        }));
    };

    const sizeOptions = useMemo(() => {
        const sizes = [
            { _id: '1', name: 'XS' },
            { _id: '2', name: 'S' },
            { _id: '3', name: 'M' },
            { _id: '4', name: 'L' },
            { _id: '5', name: 'XL' },
            { _id: '6', name: 'XXL' },
            { _id: '7', name: 'XXXL' },
        ];
        return sizes.map((size) => (
            <option key={size._id} value={size.name}>
                {size.name}
            </option>
        ));
    }, []);

    const handleSizeOptions = (e) => {
        const { value } = e.target;
        setSelectedSize(value);
        setData((prevState) => ({
            ...prevState,
            size: value,
        }));
    };

    const colorOptions = useMemo(() => {
        return color?.colors.map((color) => (
            <option key={color._id} value={color._id}>
                {color.name}
            </option>
        ));
    }, [color]);

    const handleColorOptions = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const removeSelectedFile = () => {
        setSelectedFile(null);
    };

    const validateForm = () => {
        if (!selectedSize || !data.color || !stockData.stock || stockData.stock <= 0) {
            setError('Please fill in all fields with valid data.');
            return false;
        }
        setError('');
        return true;
    };

    const Submit = async () => {
        setIsLoading(true);

        if (!validateForm()) {
            setIsLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }

            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            await axios.post(
                `https://api.yafa-brand.com/product/${productDetails._id}/variant`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${userGlobalInfo.token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        } catch (error) {
            console.error("Error uploading data:", error);
            setError('An error occurred while submitting the variant. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <nav className={`${css.sideNavDesign} mb-5 position-relative m-0`} style={{ height: "98%" }}>
                <p className={`inter ${css.textHeader}`}>Add Variant</p>
                <div className="upload-area w-100">
                    <input
                        type="file"
                        id="file-upload"
                        accept=".svg, .jpg, .jpeg, .png, .gif"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="file-upload" className="upload-label">
                        <div className="upload-icon">⬆️</div>
                        <p><strong>Click to upload</strong> or drag and drop</p>
                        <span>(SVG, JPG, PNG, or GIF)</span>
                    </label>
                </div>
                {selectedFile && (
                    <div className="image-preview" style={{ position: 'relative', display: 'inline-block' }}>
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="preview"
                            style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                        />
                        <button
                            onClick={removeSelectedFile}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                background: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                cursor: 'pointer',
                                padding:"0px"
                            }}
                        >
                            X
                        </button>
                    </div>
                )}
                <select value={selectedSize} name="size" onChange={handleSizeOptions} className={`${css.adminInput} work-sans my-2`}>
                    <option value="">Select a Size</option>
                    {sizeOptions}
                </select>
                <select value={data.color || ''} name="color" onChange={handleColorOptions} className={`${css.adminInput} work-sans my-2`}>
                    <option value="">Select Color</option>
                    {colorOptions}
                </select>
                <input
                    type="number"
                    name="stock"
                    className={`${css.adminInput} work-sans my-2`}
                    placeholder="Quantity"
                    onChange={getStock}
                />
                {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}

                <div className="position-absolute bottom-0 mb-4 ms-5">
                    <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`} onClick={() => setAddVaraintState(false)}>
                        <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Cancel
                    </button>

                    <button className={`${css.btnBoth} text-white`} onClick={Submit}>
                        <svg width="25" className="me-2" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Add
                        
                    </button>
                </div>
            </nav>
        </>
    );
}
