import React, { useContext, useEffect, useState } from "react";
import ProductCard from "./ui/cards/ProductCard";
import "./styles/favoritesStyle.scss";
import axiosInstance from "../../api/axiosInstance";
import { UserContext } from "../../Context/UserInof";

export default function Favorites() {
  const [favorites, setFavorites] = useState([]);
  const { userGlobalInfo, isUser } = useContext(UserContext);
  const getFavoritesData = async () => {
    if (isUser === true) {
      try {
        const req = await axiosInstance.get("/user/wishlist", {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        });
        setFavorites(req.data.wishlist);
    
      } catch (e) {
        console.log(e);
        setFavorites([]);
      }
    } else if (isUser === false) {
      const storage = localStorage.getItem("favorites");
      const favoritesStorage = JSON.parse(storage) || [];
      if (favoritesStorage.length > 0) {
        try {
          const { data } = await axiosInstance.post(
            "/product/getCartItemsDetails",
            {
              products: favoritesStorage,
            }
          );
          setFavorites(data.products);
        } catch (e) {
          console.log(e);
        }
      } else {
        setFavorites([]);
      }
    }
  };

  useEffect(() => {
    getFavoritesData();
  }, [isUser]);
  console.log(favorites);



  return (
    <>
      <div className="container-fluid favoriteSection">
        <div className="pageTitle">
          <h1> Your Favorite Products</h1>
          
        </div>
        <div className="sectionContainer">
          {favorites.length > 0 ? (
            <>
              <div className="cardContainer">
                {favorites.slice(0, 4)?.map((product, index) => (
                  <div key={index} style={{ margin: "0px 0px 65px" }}>
                    <div className="text-decoration-none text-dark">
                      <ProductCard
                        productData={
                          isUser
                            ? { ...product, _id: product.productId }
                            : { ...product }
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="cardContainerPlus">
                {favorites.slice(4).map((product, index) => (
                  <div key={index} className=" ">
                    <div className="text-decoration-none text-dark">
                      <ProductCard
                        productData={
                          isUser
                            ? { ...product, _id: product.productId }
                            : { ...product }
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  color: "#8b442a",
                }}
              >
                your favorite products is empty
              </p>
            </>
          )}
        </div>
        {/* <YoumayAlsoLike /> */}
      </div>
    </>
  );
}
