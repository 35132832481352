import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";

export const colorContext = createContext(null);

export default function ColorProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [color, setColor] = useState({});

  const getcolor = async () => {
    try {
      const response = await axios.get(
        "https://api.yafa-brand.com/color",
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      
      setColor(response.data);
    } catch (error) {
      console.error("Failed to fetch color", error);
    }
  };

  const createcolor = async (Newcolor) => {
    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/color",
        Newcolor,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch color", error);
    }
  };

  const editcolor = async (id,updated) => {
 
    
    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/color/${id}`,
        updated,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch color", error);
    }
  };

  const deletecolor = async (id) => {

    try {
      const response = await axios.delete(
        `https://api.yafa-brand.com/color/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch color", error);
    }
  };


  
  useEffect(() => {
    if (userGlobalInfo?.token) {
      getcolor();
    }
  }, [userGlobalInfo]);
  return (
    <colorContext.Provider value={{ color, createcolor,deletecolor,editcolor }}>
      {children}
    </colorContext.Provider>
  );
}
