export function getFormattedDate() {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
  
    // Add ordinal suffix (st, nd, rd, th)
    const day = today.getDate();
    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th, etc.
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return formattedDate.replace(/\d+/, day + ordinalSuffix(day));
  }
  
  