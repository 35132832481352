import React, { useContext, useState } from 'react';
import css from "../style.module.css";
import { mediaContext } from '../../../Context/Media';

export default function MediaNav({ setBannerNavVisible }) {
    const { updateMedia } = useContext(mediaContext);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedVideos, setSelectedVideos] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        // Ensure no more than 2 videos are selected
        if (selectedVideos.length + files.length > 2) {
            setMessage('You can only upload 2 videos.');
            return;
        }
        setSelectedVideos(prevFiles => [...prevFiles, ...files]);
    };

    const handleDeleteVideo = (index) => {
        setSelectedVideos(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleSubmit = async () => {
        if (selectedVideos.length === 0) {
            setMessage('Please select videos to upload.');
            return;
        }
        setIsLoading(true);
        try {
            // Create form data
            const formData = new FormData();
            formData.append('file', selectedVideos[0]);
            if (selectedVideos[1]) {
                formData.append('file2', selectedVideos[1]);
            }
            const response = await updateMedia(formData);
            if (response.status) {
                setMessage("Vedio Add successfully");
            }
        } catch (error) {
            setMessage('Failed to upload videos. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Add Videos</p>
            <div className="upload-area w-100">
                <input
                    type="file"
                    id="file-upload"
                    accept=".mp4, .mov, .avi, .mkv"
                    onChange={handleFileChange}
                    multiple
                    style={{ display: 'none' }}
                />
                <label htmlFor="file-upload" className="upload-label">
                    <div className="upload-icon">⬆️</div>
                    <p><strong>Click to upload</strong> or drag and drop</p>
                    <span>(MP4, MOV, AVI, MKV)</span>
                </label>
            </div>
            <div className="selected-videos">
                {selectedVideos.map((video, index) => (
                    <div key={index} className="video-preview" style={{ position: 'relative' }}>
                        <video
                            src={URL.createObjectURL(video)}
                            controls
                            style={{ width: '100px', height: '100px', margin: '5px' }}
                        />
                        <button
                            onClick={() => handleDeleteVideo(index)}
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                color: 'red',
                                border: 'none',
                                borderRadius: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            X
                        </button>
                    </div>
                ))}
            </div>
            <div className="position-absolute bottom-0 mb-4 ms-5">
                {message && <p className="text-center fw-bold">{message}</p>}
                <button
                    className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`}
                    onClick={() => setBannerNavVisible(false)}
                >
                    <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Cancel
                </button>
                <button className={`${css.btnBoth}`} onClick={handleSubmit}>
                    <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Submit
                    {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    );
}
