import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import * as Yup from "yup";
import axiosInstance from "../../api/axiosInstance";
import { UserContext } from "../../Context/UserInof";
import "./styles/popupStyle.scss";
export default function PromocodeOffer({ setPromoCodeVisible }) {
  const { setCurrentForm } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const initialValues = { email: "" };

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: "80%",
      scale: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
    visible: {
      opacity: 1,
      y: "0%",
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessage(""); // Clear previous error message
    try {
      const response = await axiosInstance.post(
        "/popup/apply-discount",
        values
      );
      if (response.status === 200 || response.status === 201) {
        setSubmitted(true);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message); // Set the error message from the response
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => setPromoCodeVisible(false), 500); // Delay to allow reverse animation
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center popupContainer"
      style={{
        position: "fixed",
        top: "50px",
        bottom: "0",
        right: "0",
        left: "0",
        background: "rgba(0, 0, 0, 0.4)",
        zIndex: "100",
      }}
    >
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className=" popupBox"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden" // Apply reverse animation on exit
          >
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-times"></i>
            </div>

            <p
              className="description "
              style={{ textAlign: "center", marginTop: "15px" }}
            >
              Subscribe and participate in the 1000 EG giveaway for this Black
              Friday! Enjoy -10% off your first purchase and get exclusive
              access to early offers!
            </p>
            <p
              className=" cursor"
              onClick={() => setCurrentForm("register")}
              style={{
                color: "var(--thrd-Color)",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              Sign up now
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, handleBlur }) => (
                <Form>
                  <div style={{ marginBottom: "10px" }}>
                    <label htmlFor="email" className="d-block mb-2">
                      Email:
                    </label>
                    <Field
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>

                  {/* Display API Error Message */}
                  {errorMessage && (
                    <div className="error my-2">{errorMessage}</div>
                  )}

                  <button
                    type="submit"
                    className="btn w-100 d-flex align-items-center justify-content-center "
                  >
                    {loading ? (
                      <i className="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse me-2"></i>
                    ) : submitted ? (
                      <>
                        <i className="fa-solid fa-check-circle me-2"></i>{" "}
                        Success
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
