import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";

export const promoCodeContext = createContext(null);

export default function PromoCodeProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [promo, setPromo] = useState(null);
  const [pagination, setPagination] = useState(null);

  const getProme = async () => {
    try {
      const { data } = await axios.get(
        "https://api.yafa-brand.com/promoCode",
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      setPromo(data.promoCodes);
      setPagination(data.pagination);
    } catch (error) {
      console.error("Failed to fetch prome", error);
    }
  };

  const createProme = async (Newprome) => {


    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/promoCode",
        Newprome,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch prome", error);
    }
  };
  const deleteprome = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.yafa-brand.com/promoCode/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch prome", error);
    }
  };
  const updateprome = async (promeId, data) => {
    console.log(userGlobalInfo.token);

    console.log(data);

    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/promoCode/${promeId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch prome", error);
    }
  };

  useEffect(() => {
    if (userGlobalInfo?.token) {
      getProme();
    }
  }, [userGlobalInfo]);
  return (
    <promoCodeContext.Provider
      value={{
        promo,
        setPromo,
        pagination,
        createProme,
        deleteprome,
        updateprome,
      }}
    >
      {children}
    </promoCodeContext.Provider>
  );
}
