import React, { useContext, useEffect, useState } from 'react'
import css from '../style.module.css';
import Search from '../Search';
import AdminNav from '../../Navs/SideNav/AdminNav';
import { adminContext } from "../../../Context/Admin";

export default function Admin() {
    const { admin, createadmin, deleteAdmin } = useContext(adminContext);


    useEffect(() => {
        if (admin && admin.admins && Array.isArray(admin.admins) && admin.admins.length > 0) {
            setIsLoading(false);
            setAdminData(admin.admins);
            setPagination(admin.pagination || {}); // Fallback to an empty object if pagination is not defined
        }
    }, [admin]);

    const [adminData, setAdminData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [openIndexes, setOpenIndexes] = useState([]);
    const [visible, setVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    if (isLoading) {
        return (
            <p>
                Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
            </p>
        );
    }

    // functions
    const deleteButton = async (id) => {
        try {
            const response = await deleteAdmin(id); // Assuming deleteAdmin returns response object
            const { data, status } = response;

            // Log the actual data structure
            console.log("Response data is: ", data);

            if (status === 200) {
                // If 'data' is not an array, log and inspect it to understand its structure
                if (Array.isArray(data)) {
                    const updated = data.filter((e) => e._id !== id); // Filter out the item with the matching id
                    setAdminData(updated);
                } else {
                    console.error('Expected data to be an array, but received:', typeof data, data);
                    // Handle data appropriately based on its actual structure
                }
            }
        } catch (error) {
            console.log("error is:", error);
        }
    };



    const toggleOpenIndex = (index) => {
        // If the index is already open, remove it from the array; otherwise, add it
        setOpenIndexes(prevIndexes =>
            prevIndexes.includes(index) ? prevIndexes.filter(i => i !== index) : [...prevIndexes, index]
        );
    };
    console.log(adminData);

    return (
        <>
            <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
                <Search />
                <div className='fadeInAnimate'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <p className={`${css.pad} ${css.textProp} inter m-0`}>Admins</p>
                            <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                        </div>
                        <button className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white  d-flex justify-content-center align-items-center border-0`} onClick={() => setVisible(true)}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 8.5C14 5.1875 11.3125 2.5 8 2.5C4.6875 2.5 2 5.1875 2 8.5C2 11.8125 4.6875 14.5 8 14.5C11.3125 14.5 14 11.8125 14 8.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                <path d="M8 6V11V6ZM10.5 8.5H5.5H10.5Z" fill="white" />
                                <path d="M8 6V11M10.5 8.5H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            ADD NEW
                        </button>
                    </div>
                    <div className="row">
                        {adminData.map((item, firstIndex) => (
                            <div
                                key={item._id} // Use a unique identifier for the key
                                className={`${css.permissionGroupsProp} bg-white my-2 cursor`}
                                onClick={() => toggleOpenIndex(firstIndex)} // Toggle visibility
                            >

                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className={`inter ${css.permissionGroupsText} m-0`}>{item.name}</p>
                                    {openIndexes.includes(firstIndex) ? (
                                        <i className="fa-solid fa-angle-down"></i>
                                    ) : (
                                        <i className="fa-solid fa-angle-up"></i>
                                    )}
                                </div>
                                <div
                                    className='slidingContentAnimate'
                                    style={{
                                        maxHeight: openIndexes.includes(firstIndex) ? '200px' : '0px', // Adjust maxHeight for animation
                                        opacity: openIndexes.includes(firstIndex) ? 1 : 0,
                                        transition: 'max-height 0.4s ease, opacity 0.4s ease', // Smooth transition
                                        overflow: 'hidden' // Prevent overflow
                                    }}
                                >

                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6'>
                                            <p className={`inter ${css.mainText}`}>Phone Number <span className='fw-bold'>{item.phone}</span></p>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <p className={`inter ${css.mainText}`}>Country <span className='fw-bold'>{item.country && item.country.name}</span></p>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6'>
                                            <p className={`inter ${css.mainText}`}>Email <span className='fw-bold'>{item.email}</span></p>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <p className={`inter ${css.mainText}`}>Permission Group
                                                <span className='fw-bold ms-1'>
                                                    {
                                                        item.permissionGroup && item.permissionGroup.permissions
                                                            ? Object.keys(item.permissionGroup.permissions)
                                                                .filter(key => item.permissionGroup.permissions[key])
                                                                .join(', ') // Join the filtered true keys into a string
                                                            : 'None' // Fallback text if permissionGroup is not available
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end align-items-center mt-1' onClick={(e) => { e.stopPropagation() }}>
                                        {/* Edit Icon */}
                                        <svg width="28" height="28" className='me-3' viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="28" height="28" rx="8" fill="#8B442A" />
                                            <path d="M14 19.3334H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17 8.33322C17.2652 8.068 17.6249 7.91901 18 7.91901C18.1857 7.91901 18.3696 7.95559 18.5412 8.02666C18.7128 8.09773 18.8687 8.2019 19 8.33322C19.1313 8.46454 19.2355 8.62044 19.3066 8.79202C19.3776 8.9636 19.4142 9.1475 19.4142 9.33322C19.4142 9.51894 19.3776 9.70284 19.3066 9.87442C19.2355 10.046 19.1313 10.2019 19 10.3332L10.6667 18.6666L8 19.3332L8.66667 16.6666L17 8.33322Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                        {/* Delete Icon */}
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={(e) => {
                                                e.stopPropagation();  // Prevent event bubbling
                                                deleteButton(item._id);  // Calls the delete function with the item id
                                            }}
                                        >
                                            <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#8B442A" />
                                            <path d="M8 10H9.33333H20" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.3334 10V8.66671C11.3334 8.31309 11.4739 7.97395 11.7239 7.7239C11.9739 7.47385 12.3131 7.33337 12.6667 7.33337H15.3334C15.687 7.33337 16.0261 7.47385 16.2762 7.7239C16.5262 7.97395 16.6667 8.31309 16.6667 8.66671V10M18.6667 10V19.3334C18.6667 19.687 18.5262 20.0261 18.2762 20.2762C18.0261 20.5262 17.687 20.6667 17.3334 20.6667H10.6667C10.3131 20.6667 9.97395 20.5262 9.7239 20.2762C9.47385 20.0261 9.33337 19.687 9.33337 19.3334V10H18.6667Z" stroke="#8B442A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.6666 15.6667C12.0462 15.6667 11.5 16.213 11.5 16.8334C11.5 17.4537 12.0462 18 12.6666 18C13.287 18 13.8333 17.4537 13.8333 16.8334C13.8333 16.213 13.287 15.6667 12.6666 15.6667Z" fill="#8B442A" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
            {/* SideBar */}
            {
                visible && (
                    <div className={`p-0 displayAnimation ${visible ? 'show' : 'hide'}`}>
                        <AdminNav setVisible={setVisible} setAdminData={setAdminData} adminData={adminData}/>
                    </div>
                )
            }
        </>
    )
}
