import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "./ui/cards/ProductCard";
import axiosInstance from "../../api/axiosInstance";
export default function YoumayAlsoLike({ data, breakpoints }) {
  const [allData, setAllData] = useState();

  const getAllProducts = async () => {
    const req = await axiosInstance.get(`/product`);
    return req?.data?.products;
  };
  const getData = async () => {
    setAllData(data ? data : await getAllProducts());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {allData && (
        <div
          className="container-fluid mt-3 youMightLike "
          style={{ margin: "20px 0px" }}
        >
          <div className="row ">
            <p
              className={`${css.categoryHeader} archivo  text-center mt-2 mb-4`}
            >
              You might also like
            </p>
            <div className="row">
              <Swiper
                className="py-2 mobileFont"
                direction="horizontal"
                spaceBetween={15}
                grabCursor={true}
                breakpoints={
                  breakpoints
                    ? breakpoints
                    : {
                        500: {
                          slidesPerView: 2,
                        },
                        640: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 4,
                        },
                        1024: {
                          slidesPerView: 6,
                        },
                      }
                }
              >
                <>
                  {allData?.map((product, index) => (
                    <SwiperSlide key={index}>
                      <div>
                        <ProductCard productData={product} />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
