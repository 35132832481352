import React, { useState } from 'react'
import Search from '../Search'
import css from '../style.module.css';


export default function Users() {
    const [openIndexes, setOpenIndexes] = useState([]);


    const toggleOpenIndex = (index) => {
        // If the index is already open, remove it from the array; otherwise, add it
        setOpenIndexes(prevIndexes =>
            prevIndexes.includes(index) ? prevIndexes.filter(i => i !== index) : [...prevIndexes, index]
        );
    };
    return (
        <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
            <Search />
            <div className='fadeInAnimate'>
                <div>
                    <p className={`${css.pad} ${css.textProp} inter m-0`}>Users</p>
                    <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                </div>
                <div className="row">
                    {Array(3).fill(null).map((_, firstIndex) => (
                        <div
                            key={firstIndex}
                            className={`${css.permissionGroupsProp} bg-white my-2 cursor`}
                            onClick={() => toggleOpenIndex(firstIndex)} // Toggle visibility
                        >
                            <div className='d-flex justify-content-between align-items-center '>
                                <p className={`inter ${css.permissionGroupsText} m-0 inter fw-semibold`} style={{ lineHeight: '19.36px' }}>Username</p>
                                {openIndexes.includes(firstIndex) ? (
                                    <i className="fa-solid fa-angle-down"></i>
                                ) : (
                                    <i className="fa-solid fa-angle-up"></i>
                                )}
                            </div>
                            <div
                                className='slidingContentAnimate'
                                style={{
                                    maxHeight: openIndexes.includes(firstIndex) ? '200px' : '0px', // Adjust maxHeight for animation
                                    opacity: openIndexes.includes(firstIndex) ? 1 : 0,
                                    transition: 'max-height 0.4s ease, opacity 0.4s ease', // Smooth transition
                                    overflow: 'hidden' // Prevent overflow
                                }}
                            >
                                <div className='row mt-2'>
                                    <div className="col-md-6">
                                        <p className={` inter ${css.Internalwriting}`}>Email <span className='fw-bold'>haaiaatarek@gmail.com</span></p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className={` inter ${css.Internalwriting}`}>Address <span className='fw-bold'>Egypt, Cairo</span></p>
                                    </div>
                                    {Array(3).fill(null).map((_, index) => (
                                        <React.Fragment key={index} >
                                            <div className="col-md-3 "><p className={` inter ${css.Internalwriting}`}>Order ID <span className='fw-bold'>#11232</span></p></div>
                                            <div className="col-md-3"><p className={` inter ${css.Internalwriting}`}>Date <span className='fw-bold'>Jun 29,2022</span></p></div>
                                            <div className="col-md-3"><p className={` inter ${css.Internalwriting}`}>Status <span className='fw-bold'>Delivered</span></p></div>
                                            <div className="col-md-3"><p className={` inter ${css.Internalwriting}`}>Amount <span className='fw-bold'>400.00 EGP</span></p></div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className='text-end mt-1'>
                                    <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="48" height="40" rx="8" fill="#8B442A" />
                                        <path d="M26.3929 26.4401C26.2662 26.4401 26.1395 26.3934 26.0395 26.2934C25.8462 26.1001 25.8462 25.7801 26.0395 25.5868L27.9195 23.7068C28.1129 23.5134 28.4329 23.5134 28.6262 23.7068C28.8195 23.9001 28.8195 24.2201 28.6262 24.4134L26.7462 26.2934C26.6462 26.3934 26.5195 26.4401 26.3929 26.4401Z" fill="white" />
                                        <path d="M28.2729 26.4401C28.1462 26.4401 28.0195 26.3934 27.9195 26.2934L26.0395 24.4134C25.8462 24.2201 25.8462 23.9001 26.0395 23.7068C26.2329 23.5134 26.5529 23.5134 26.7462 23.7068L28.6262 25.5868C28.8195 25.7801 28.8195 26.1001 28.6262 26.2934C28.5262 26.3934 28.3995 26.4401 28.2729 26.4401Z" fill="white" />
                                        <path d="M24.1057 19.7466C24.0857 19.7466 24.0724 19.7466 24.0524 19.7466C24.0191 19.7399 23.9724 19.7399 23.9324 19.7466C21.9991 19.6866 20.5391 18.1666 20.5391 16.2933C20.5391 14.3866 22.0924 12.8333 23.9991 12.8333C25.9057 12.8333 27.4591 14.3866 27.4591 16.2933C27.4524 18.1666 25.9857 19.6866 24.1257 19.7466C24.1191 19.7466 24.1124 19.7466 24.1057 19.7466ZM23.9991 13.8333C22.6457 13.8333 21.5391 14.9399 21.5391 16.2933C21.5391 17.6266 22.5791 18.6999 23.9057 18.7466C23.9457 18.7399 24.0324 18.7399 24.1191 18.7466C25.4257 18.6866 26.4524 17.6133 26.4591 16.2933C26.4591 14.9399 25.3524 13.8333 23.9991 13.8333Z" fill="white" />
                                        <path d="M24.0006 27.04C22.6406 27.04 21.3539 26.6866 20.3806 26.0333C19.4539 25.4133 18.9473 24.5666 18.9473 23.6533C18.9473 22.74 19.4606 21.9 20.3806 21.2866C22.3739 19.9533 25.6139 19.9533 27.6073 21.2866C27.8339 21.44 27.9006 21.7533 27.7473 21.98C27.5939 22.2133 27.2806 22.2733 27.0539 22.12C25.3939 21.0133 22.5939 21.0133 20.9339 22.12C20.2939 22.5466 19.9473 23.0866 19.9473 23.6533C19.9473 24.22 20.3006 24.7733 20.9339 25.2C21.7406 25.74 22.8273 26.0333 23.9939 26.0333C24.2673 26.0333 24.4939 26.26 24.4939 26.5333C24.4939 26.8066 24.2739 27.04 24.0006 27.04Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
