import React, { useContext, useEffect, useState } from "react"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore from "swiper"
import { Row, Col, Container } from "react-bootstrap"
import { Autoplay } from "swiper/modules"
import css from "./style.module.css"
// import youngBeautifulWoman from "../../Images/src/Images/_DSC9442-min_1731444765204.jpg"
// import youngBeautifulWoman2 from "../../Images/_DSC8504 copy-min_1731441720278.jpg"
import { Link } from "react-router-dom"
import banner1 from "../../Images/_DSC8504 copy-min_1731441720278.jpg"
import banner from "../../Images/_DSC9442-min_1731444765204.jpg"
import { productContext } from "../../Context/product"
import Stories from "./ui/stories/Stories"
import Loding from "../Loding/Loding"
import axiosInstance from "../../api/axiosInstance"
import ProductCard from "./ui/cards/ProductCard"
import { mediaContext } from "../../Context/Media"
import "./styles/homeStyle.scss"
import PromocodeOffer from "./PromocodeOfffer"
import { UserContext } from "../../Context/UserInof"

SwiperCore.use([Autoplay])
export default function Home() {
  const { product } = useContext(productContext)
  const { media } = useContext(mediaContext)
  const [isLoading, setIsLoading] = useState(true)
  const [bannerOne, setBannerOne] = useState([])
  const [bannerTwo, setBannerTwo] = useState([])
  const [productData, setProductData] = useState([])
  const [Category, setCategory] = useState([])
  const [mostProductData, setMostProductData] = useState([])
  const [carousels, setCarousels] = useState([])
  const [reviews, setReviews] = useState([])
  const [promoCodeVisible, setPromoCodeVisible] = useState(false)
  const [popUpDetails, setPopUpDetails] = useState(false)
  const { isUser } = useContext(UserContext)

  useEffect(() => {
    if (product?.products?.length > 0) {
      getMostProducts()
      setProductData(product?.products)
      setIsLoading(false)
    }
    getCarousels()
    getReviews()
  }, [product])

  useEffect(() => {
    if (media) {
      const { banner, banner2 } = media
      setBannerOne(banner)
      setBannerTwo(banner2)
    }
  }, [media])
  const getMostCategories = async () => {
    try {
      const data = await axiosInstance.get("/product/categories")
      // setMostProductData(data?.data?.products);
      console.log(data.data.categories)
      setCategory(data?.data?.categories)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getMostCategories()
  }, [])
  const getMostProducts = async () => {
    try {
      const data = await axiosInstance.get("/product?sort=rating")
      setMostProductData(data?.data?.products)
    } catch (error) {
      console.error(error)
    }
  }

  const getCarousels = async () => {
    try {
      const { data } = await axiosInstance.get("/carousell")
      setCarousels(data?.carousells)
    } catch (error) {
      console.error("Failed to fetch carousels", error)
      setCarousels([])
    }
  }
  const getReviews = async () => {
    try {
      const { data } = await axiosInstance.get("/review")

      setReviews(data?.reviews)
    } catch (error) {
      console.error("Failed to fetch carousels", error)
      setReviews([])
    }
  }
  const popUp = async () => {
    try {
      const { data } = await axiosInstance.get("/popup")

      console.log(data)
      setPopUpDetails(data)
    } catch (error) {
      console.error("Failed to fetch carousels", error)
      setReviews([])
    }
  }
  useEffect(() => {
    if (!isUser) {
      popUp()
      setPromoCodeVisible(popUpDetails.homepage)
    }
  }, [])
  return (
    <>
      {isLoading ? (
        <Loding />
      ) : (
        <>
          <section className="homePage">
            <div className="d-flex w-100">
              <div className="video-container">
                <img
                  style={{
                    width: "100%",
                    aspectRatio: "9/9",
                    objectFit: "cover",
                  }}
                  // controlsList="nofullscreen"
                  // autoPlay
                  // muted
                  // loop
                  src={banner1}
                />
                {/* <video
                  style={{
                    width: "100%",
                    aspectRatio: "8/9",
                    objectFit: "cover",
                  }}
                  controlsList="nofullscreen"
                  autoPlay
                  muted
                  loop
                  playsInline
                  webkit-playsinline
                  preload="auto" // Preloads the video to start buffering as soon as possible
                  // poster="https://firebasestorage.googleapis.com/v0/b/yafa-storage.appspot.com/" // Add the cover image here
                >
                  <source src={bannerOne} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
              </div>
              <div className="video-container second-video">
                <img
                  style={{
                    width: "100%",
                    aspectRatio: "9/9",
                    objectFit: "cover",
                  }}
                  // controlsList="nofullscreen"
                  // autoPlay
                  // muted
                  // loop
                  src={banner}
                />
                {/* <source src={bannerTwo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <Swiper
                  className="py-2 mobileFont categoriesHomepage"
                  direction="horizontal"
                  spaceBetween={10}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2000, // This sets the autoplay delay to 2000ms (2 seconds)
                    disableOnInteraction: false, // Ensures autoplay continues even after user interaction
                  }}
                  breakpoints={{
                    300: {
                      slidesPerView: 3,
                    },
                    500: {
                      slidesPerView: 3,
                    },
                    640: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                  loop={true}
                  grabCursor={true}
                >
                  {Category?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Link
                        to={`/category/${item?._id}`}
                        state={{ categoryId: item?._id }} // Accessing the first product's first image and item name
                        className="text-decoration-none text-dark"
                      >
                        <div className="d-flex w-100 pt-2">
                          <img
                            src={item.products[0]?.images[0]}
                            alt={item.name || "image"}
                            className="w-100"
                            style={{
                              width: "100%",
                              aspectRatio: "2/3",
                            }}
                          />
                        </div>
                        <p
                          className={`${css.category} text-center archivo my-2`}
                        >
                          {item.name || " "}
                        </p>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <Row className="categoriesMob">
                {Category?.map((item, index) => (
                  <Col
                    style={{
                      paddingLeft: index == 0 || index % 4 == 0 ? "0px" : "3px",

                      paddingRight:
                        index % 3 == 0 && index != 0 ? "0px" : "6px",
                    }}
                    xs={3}
                    key={index}
                  >
                    <Link
                      to={`/category/${item?._id}`}
                      state={{ categoryId: item?._id }} // Accessing the first product's first image and item name
                      className="text-decoration-none text-dark"
                    >
                      <div className="d-flex w-100 pt-2">
                        <img
                          src={item.products[0]?.images[0]}
                          alt={item.name || "image"}
                          className="w-100"
                          style={{
                            width: "100%",
                            height: "16vh",
                            aspectRatio: "2/3",
                          }}
                        />
                      </div>
                      <p className={`${css.category} text-center archivo my-2`}>
                        {item.name || " "}
                      </p>
                    </Link>
                  </Col>
                ))}
              </Row>

              <div className={`${css.storiesBanar} my-4 `}>
                <p
                  className={`text-center Archivo fw-semibold ${css.headerbanar}`}
                >
                  The Art of Fewer, Better Choices
                </p>
                <Row>
                  <Col md={3}></Col>

                  <Col md={6} xs={12}>
                    <p
                      className={`nunito text-center text-white ${css.bodybanar} mt-4`}
                    >
                      Opting for quality over quantity means selecting timeless,
                      durable, and responsibly made items. This approach
                      simplifies our lives and fosters a deeper appreciation for
                      our surroundings. Emphasizing longevity and responsible
                      production resonates with a more sustainable and mindful
                      lifestyle.
                    </p>
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </div>

              <Stories />
              {/* <Swiper
                className="py-2 mobileFont"
                direction="horizontal"
                spaceBetween={15}
                slidesPerView={3}
                loop={true}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                speed={6000}
                grabCursor={true}
              >
                {carousels.slice(3).map((item, index) => (
                  <SwiperSlide key={index}>
                    <div>
                      <div className="d-flex w-100   pt-2 ">
                        <img
                          src={item.image}
                          alt="japanesewoman"
                          style={{
                            width: "100%",
                            aspectRatio: "23/43 ",
                          }}
                        />
                      </div>
                      <p className={`${css.category}  ${css.swiperText}`}>
                        {item.text}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper> */}
              <div className="mostWantedHomeWeb">
                <p
                  className={`${css.categoryHeader} text-md-center archivo mb-4 mobileFontHeader`}
                >
                  The most wanted products
                </p>
                <div className="row mb-4">
                  <Swiper
                    className="py-2 "
                    direction="horizontal"
                    spaceBetween={15}
                    breakpoints={{
                      300: {
                        slidesPerView: 3,
                      },
                      500: {
                        slidesPerView: 3,
                      },
                      640: {
                        slidesPerView: 3,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 6,
                      },
                    }}
                    loop={true}
                    grabCursor={true}
                  >
                    {mostProductData?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <ProductCard productData={item} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="mostWantedHomeMob">
                <p
                  className={`${css.categoryHeader} text-md-left archivo mb-1 mobileFontHeader`}
                >
                  The most wanted products
                </p>
                <div className="row mb-4">
                  {mostProductData?.map((item, index) => (
                    // <SwiperSlide key={index}>
                    <>
                      {index < 6 && (
                        <Col xs={4}>
                          <ProductCard productData={item} />
                        </Col>
                      )}
                    </>
                    // </SwiperSlide>/
                  ))}
                </div>
              </div>
            </div>

            <div className="d-flex w-100 mt-4 p-0">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/yafa-storage.appspot.com/o/_DSC8603-min_1731428153143.jpg?alt=media&token=692b50c7-93c5-4c5b-9a37-e91491341a8e"
                alt="womanjacket"
                style={{
                  width: "50%",
                  aspectRatio: "2/3",
                }}
                // className={`w-50 ${isMobile ? "w-100 mobile-image vh-100" : ""}`}
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/yafa-storage.appspot.com/o/_DSC8616-min_1731428153148.jpg?alt=media&token=88bb6ab6-48c3-4b08-ba12-31c6f0a59bc5"
                alt="japanesewoman"
                // className={`w-50 ${isMobile ? "w-100 mobile-image vh-100" : ""}`}
                style={{
                  width: "50%",
                  aspectRatio: "2/3",
                }}
              />
            </div>

            <div className="container-fluid">
              {/*  */}
              <p
                className={`${css.categoryHeader} text-md-center archivo my-4 mobileFontHeader`}
              >
                What They Said{" "}
              </p>
              <div id="testimonialsContainer" className="row mb-4 px-5">
                {reviews?.map((e, index) => (
                  <div key={index} className="col-md-3 col-sm-12">
                    <div className={css.testimonials}>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <div>
                          <img
                            src={e.product.images[0]}
                            style={{ width: "80px", aspectRatio: "1/1" }}
                            alt=""
                          />
                        </div>
                        <div>
                          {Array(e.rating)
                            .fill(null)
                            .map((_, index) => (
                              <i
                                key={index}
                                className="fa-solid fa-star me-1"
                                style={{ color: "#FC9231" }}
                              ></i>
                            ))}
                          <p className="mt-3">{e.product.name}</p>
                        </div>
                      </div>
                      <p
                        className="lineHeight inter mt-2"
                        style={{ fontSize: "13px", color: " #5c5c5c" }}
                      >
                        {e.review}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <Swiper
                className="py-2"
                direction="horizontal" // Set the direction to horizontal
                spaceBetween={15}
                slidesPerView={3}
                loop={true}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                speed={6000}
                grabCursor={true}
              >
                {carousels?.slice(0, 4).map((item, index) => (
                  <SwiperSlide key={index}>
                    <div>
                      <div className=" w-100   pt-2 ">
                        <img
                          src={item.image}
                          alt="japanesewoman"
                          style={{
                            width: "100%",
                            aspectRatio: "30/43 ",
                          }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
          {promoCodeVisible && isUser === false ? (
            <PromocodeOffer setPromoCodeVisible={setPromoCodeVisible} />
          ) : null}
        </>
      )}
    </>
  )
}
