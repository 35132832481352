import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserInof";
import axios from "axios";

export const PermissionContext = createContext(null);

export default function PermissionProvider({ children }) {
  const { userGlobalInfo } = useContext(UserContext);
  const [permission, setPermission] = useState({});

  const getPermission = async () => {
    try {
      const response = await axios.get(
        "https://api.yafa-brand.com/permissionGroup",
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );
      setPermission(response.data);
    } catch (error) {
      console.error("Failed to fetch permission", error);
    }
  };

  const createPermission = async (NewPermission) => {
    console.log("NewPermission");
    console.log(NewPermission);

    try {
      const response = await axios.post(
        "https://api.yafa-brand.com/permissionGroup",
        NewPermission,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch permission", error);
    }
  };

  const editPermission = async (id,updated) => {
    console.log("id + "+id);
    
    try {
      const response = await axios.patch(
        `https://api.yafa-brand.com/permissionGroup/${id}`,
        updated,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch permission", error);
    }
  };

  const deletePermission = async (id) => {
    console.log("id is "+id);
    console.log("userGlobalInfo.token is ");
    console.log(userGlobalInfo.token);
    try {
      const response = await axios.delete(
        `https://api.yafa-brand.com/permissionGroup/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userGlobalInfo.token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Failed to fetch permission", error);
    }
  };


  
  useEffect(() => {
    if (userGlobalInfo?.token) {
      getPermission();
    }
  }, [userGlobalInfo]);
  return (
    <PermissionContext.Provider value={{ permission, createPermission,deletePermission,editPermission }}>
      {children}
    </PermissionContext.Provider>
  );
}
