import React, { useContext, useEffect, useState } from "react";
import css from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import YoumayAlsoLike from "./YoumayAlsoLike";
import PhoneInput from "react-phone-input-2";
import { CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useFormik } from "formik";
import * as yup from "yup";
import "./styles/checkOutStyle.scss";
import { UserContext } from "../../Context/UserInof";

export default function CheckOut() {
  const initialValues = {
    contactno: "",
    country_code: "",
    dialCode: "",
    country_Name: "",
  };
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  const [phoneData, setPhoneData] = useState(initialValues);
  const navigate = useNavigate();
  const { isUser } = useContext(UserContext);

  const handleChangePhone = (e, value, name) => {
    if (name === "contactno") {
      const splitMobile = e.split(value.dialCode);

      setPhoneData({
        ...phoneData,
        country_code: value.dialCode,
        contactno: splitMobile?.[1] || "",
        dialCode: value.countryCode,
        country_Name: value.name,
      });
    } else {
      setPhoneData({
        ...phoneData,
        [e.target?.name]: [e.target?.value],
      });
    }
  };

  const submitData = async (values) => {
    let storedItems;
    if (isUser === true) {
      storedItems = localStorage.getItem("checkOutCart");
    } else if (isUser === false) {
      storedItems = localStorage.getItem("cart");
    }
    const items = await (storedItems ? JSON.parse(storedItems) : []);
    console.log(phoneData.country_code + phoneData.contactno);

    if (items?.length > 0) {
      const orderData = {
        guestUser: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: ` +${phoneData.country_code} ${phoneData.contactno}`,
        },
        address: {
          floor: values.floor,
          apartment: values.apartment,
          street: values.street,
          building: values.building,
          postalCode: values.postalCode,
          city: stateid?.name,
          country: countryid?.name,
        },
        paymentMethod: "",
        items: items?.map((item) => {
          return {
            productId: item?._id,
            variantId: item?.variants[0]._id,
            quantity: item?.quantity,
          };
        }),
      };
      navigate("/payment", { state: { orderData } });
    } else {
      alert("Cart is empty. Please add items to proceed.");
    }
  };

  let validationSchema = yup.object({
    firstName: yup
      .string()
      .required("Name is required")
      .min(3, "Min char is 3")
      .max(20, "Max char is 20"),
    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Min char is 3")
      .max(20, "Max char is 20"),
    email: yup
      .string()
      .required("Email is required")
      .email("Enter valid email"),
    street: yup
      .string()
      .required("street is required")
      .max(200, "Max char is 200")
      .min(3, "Min char is 3"),
    floor: yup
      .string()
      .required("floor is required")
      .max(200, "Max char is 200"),

    building: yup
      .string()
      .required("building is required")
      .max(200, "Max char is 200"),

    postalCode: yup
      .string()
      .required("postalCode is required")
      .max(200, "Max char is 200")
      .min(3, "Min char is 3"),
    apartment: yup
      .string()
      .required("apartment is required")
      .max(200, "Max char is 200"),
  });

  let checkoutForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      street: "",
      floor: "",
      postalCode: "",
      building: "",
      apartment: "",
    },
    onSubmit: submitData,
    validationSchema,
  });

  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <div className="cartText">
          <p
            className={`${css.categoryHeader} archivo text-center text-md-start`}
          >
            Check Out
          </p>
          {/* <p className="nunito p-0">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry...
          </p> */}
        </div>
        <form onSubmit={checkoutForm.handleSubmit} className="checkOutForm">
          <div className="formSection">
            <div className="sectionTitle">
              <p className={`${css.categorySubHeader} archivo  `}>
                Contact Information
              </p>
            </div>
            <div className="inputContainer">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                placeholder="First Name"
                className={`form-control ${css.inputCheckOut}`}
                name="firstName"
                id="firstName"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.firstName &&
                checkoutForm.touched.firstName && (
                  <span className="inputError">
                    {checkoutForm.errors.firstName}
                  </span>
                )}
            </div>
            <div className="inputContainer">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                className={`form-control ${css.inputCheckOut}`}
                name="lastName"
                id="lastName"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.lastName &&
                checkoutForm.touched.lastName && (
                  <span className="inputError">
                    {checkoutForm.errors.lastName}
                  </span>
                )}
            </div>
            <div className="inputContainer">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                placeholder="Your Email"
                className={`form-control ${css.inputCheckOut}`}
                name="email"
                id="email"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.email && checkoutForm.touched.email && (
                <span className="inputError">{checkoutForm.errors.email}</span>
              )}
            </div>
          </div>
          <div className="formSection">
            <div className="sectionTitle">
              <p className={`${css.categorySubHeader} archivo  `}>
                Shipping Address
              </p>
              {/* <Link className={css.SelectAddress} to={"/selectaddress"}>
                <p>Select Address</p>
              </Link> */}
            </div>

            {/* <div className="inputContainer">
              <label htmlFor="country">Country</label>
              <CountrySelect
                required
                onChange={(e) => {
                  setCountryid(e);
                }}
                placeHolder="Select Country"
              />
            </div>
            <div className="inputContainer">
              <label htmlFor="city">City</label>
              <StateSelect
                countryid={countryid.id}
                disabled={!countryid}
                required
                onChange={(e) => {
                  setstateid(e);
                }}
                placeHolder="Select State"
              />
            </div> */}
            <div className="inputContainer">
              <label htmlFor="street">Street</label>
              <input
                type="text"
                placeholder="Add"
                className={`form-control ${css.inputCheckOut}`}
                name="street"
                id="street"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.street && checkoutForm.touched.street && (
                <span className="inputError">{checkoutForm.errors.street}</span>
              )}
            </div>
            <div className="inputContainer">
              <label htmlFor="floor">Floor</label>
              <input
                type="text"
                placeholder="Add"
                className={`form-control ${css.inputCheckOut}`}
                name="floor"
                id="floor"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.floor && checkoutForm.touched.floor && (
                <span className="inputError">{checkoutForm.errors.floor}</span>
              )}
            </div>
            <div className="inputContainer">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="text"
                placeholder="Add"
                className={`form-control ${css.inputCheckOut}`}
                name="postalCode"
                id="postalCode"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.postalCode &&
                checkoutForm.touched.postalCode && (
                  <span className="inputError">
                    {checkoutForm.errors.postalCode}
                  </span>
                )}
            </div>

            <div className="inputContainer">
              <label htmlFor="building">Building</label>
              <input
                type="text"
                placeholder="Add"
                className={`form-control ${css.inputCheckOut}`}
                name="building"
                id="building"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.building &&
                checkoutForm.touched.building && (
                  <span className="inputError">
                    {checkoutForm.errors.building}
                  </span>
                )}
            </div>
            <div className="inputContainer">
              <label htmlFor="apartment">Apartment</label>
              <input
                type="text"
                placeholder="Add"
                className={`form-control ${css.inputCheckOut}`}
                name="apartment"
                id="apartment"
                onChange={checkoutForm.handleChange}
                onBlur={checkoutForm.handleBlur}
              />
              {checkoutForm.errors.apartment &&
                checkoutForm.touched.apartment && (
                  <span className="inputError">
                    {checkoutForm.errors.apartment}
                  </span>
                )}
            </div>
            <div className="inputContainer">
              <label htmlFor="phone">Phone Number</label>
              <PhoneInput
                country={"eg"}
                required
                value={`${phoneData.country_code}${phoneData.contactno}`}
                onChange={(e, phone) => {
                  handleChangePhone(e, phone, "contactno");
                }}
                inputStyle={{ height: "50px", width: "100%" }}
              />
            </div>
          </div>
          <div className="buttonContainer">
            <button type="submit">Continue</button>
          </div>
        </form>
      </div>
      <YoumayAlsoLike />
    </section>
  );
}
