import React, { useContext, useEffect, useState } from 'react';
import css from "../../style.module.css";
import { productContext } from '../../../../Context/product';

export default function ProductNavEdition({ setVisible, visible, productDetails }) {
    const { updateproduct } = useContext(productContext);

    const [data, setData] = useState({
        name: productDetails?.name || '',
        description: productDetails?.description || '',
        price: productDetails?.price || '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isProductNavVisible, setIsProductNavVisible] = useState(false);

    useEffect(() => {
        setIsProductNavVisible(visible);
    }, [visible]);

    // Handles changes to input fields
    const getData = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handles file selection
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    // Handles form submission
    const handleSubmit = async () => {
        setIsLoading(true);
        const productData = {
            ...data,
            files: selectedFiles[0], // You can send multiple files if needed
        };

        try {
            const response = await updateproduct(productData, productDetails._id);
            if (response) {
                setMessage('Product updated successfully!');
            }
        } catch (error) {
            setMessage('Failed to update product. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <nav className={`${css.sideNavDesign} mb-5 position-relative m-0`} style={{ height: "98%" }}>
                <p className={`inter ${css.textHeader}`}>Edit Product</p>
                {/* Product Name */}
                <input
                    type="text"
                    className={`${css.adminInput} work-sans my-2`}
                    value={data.name}
                    name="name"
                    placeholder="Name"
                    onChange={getData}
                />

                {/* File Upload */}
                <div className="upload-area w-100">
                    <input
                        type="file"
                        id="file-upload"
                        accept=".svg, .jpg, .jpeg, .png, .gif"
                        onChange={handleFileChange}
                        multiple
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="file-upload" className="upload-label">
                        <div className="upload-icon">⬆️</div>
                        <p><strong>Click to upload</strong> or drag and drop</p>
                        <span>(SVG, JPG, PNG, or GIF maximum *** x ***)</span>
                    </label>
                </div>

                {/* Display Selected Images */}
                <div className="selected-images">
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="image-preview">
                            <img
                                src={URL.createObjectURL(file)}
                                alt="preview"
                                style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                            />
                        </div>
                    ))}
                </div>

                {/* Product Description */}
                <input
                    type="text"
                    className={`${css.adminInput} work-sans my-2`}
                    value={data.description}
                    name="description"
                    placeholder="Description"
                    onChange={getData}
                />

                {/* Product Price */}
                <input
                    type="text"
                    className={`${css.adminInput} work-sans my-2`}
                    value={data.price}
                    name="price"
                    placeholder="Price per item"
                    onChange={getData}
                />

                {/* Action Buttons */}
                <div className="position-absolute bottom-0 mb-4 ms-5">
                    {message && (
                        <p className={`text-center ${message === "name is already used" ? "text-danger" : "text-success"} fw-bold`}>
                            {message}
                        </p>
                    )}

                    {/* Cancel Button */}
                    <button
                        className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`}
                        onClick={() => {
                            setIsProductNavVisible(false);
                            setVisible(false);
                        }}
                    >
                        <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Cancel
                    </button>

                    {/* Submit Button */}
                    <button
                        className={`${css.btnBoth} text-white`}
                        onClick={handleSubmit}
                    >
                        <svg width="25" className="me-2 " height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        Submit
                        {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                    </button>
                </div>
            </nav>
        </>
    );
}
