import React, { useState } from 'react'
import css from './style.module.css';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function CreatePassword() {
    const navigate = useNavigate();
    const code = JSON.parse(localStorage.getItem("code"));
    const [userPassword, SetUserPassword] = useState({});
    const [spinner, setSpinner] = useState(false)
    const [error, setError] = useState(false);

    const getUserInfo = (e) => {
        const { name, value } = e.target;
     

        SetUserPassword(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const changePassword = async () => {
        setSpinner(true);


        try {

            const response = await axios.patch(`https://api.yafa-brand.com/admin/resetpassword/${code}`, userPassword);
            setSpinner(false);
            if (response.status === 200) {
                localStorage.removeItem("code");
                navigate('/');
            }
        } catch (error) {
            setError(error.response.data.message);
            setSpinner(false);
        }
    }
    return (
        <section className={css.bgLogin}>
            <div className={`${css.layer}`}>
                <Logo />
                {/* Add fade-out animation on the main div when the button is clicked */}
                <div className={`position-absolute top-50 start-50 translate-middle fadeInAnimate`}>
                    <div className={`${css.customizeMargin} `}>
                        <p className={`prata-regular ${css.loginFontAtrr} text-center text-white mb-0 `}>
                            Create New Password

                        </p>
                        <div className='d-flex justify-content-center '>
                            <p className={`work-sans ${css.WelcomFontAtrr} text-white mt-2 ms-4 `}>
                                Your new password must be unique from those <br /> previously used.
                            </p>
                        </div>

                    </div>
                    <div className={`work-sans d-flex justify-content-center align-items-start  `}>
                        <div className='me-2 '>
                            <input onChange={(e) => getUserInfo(e)} className={`${css.inputProps} `} type="email" name='email' placeholder='Email' />
                        </div>

                        <div className='me-2 '>
                            <input onChange={(e) => getUserInfo(e)} className={`${css.inputProps} `} type="password" name='password' placeholder='New Password' />
                        </div>
                        <div>
                            <button className='bg-transparent text-white rounded-0 text-center' onClick={changePassword}>Reset
                                {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
