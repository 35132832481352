import { useEffect, useState } from "react";
import "./paginationStyle.scss";
const Pagination = ({ totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage]);

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1); // بداية الصفحات (الصفحة الحالية - 1)
    const endPage = Math.min(totalPages, startPage + 2); // نهاية الصفحات (بداية الصفحات + 2)
    console.log(endPage);

    // التأكد من عدم تجاوز الحدود
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }
    return items;
  };

  return (
    <div className="paginationSection">
      <nav aria-label="...">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <span
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </span>
          </li>
          {renderPaginationItems()}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <span
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
