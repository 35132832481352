import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import { Link } from "react-router-dom";
import "./styles/storeStyle.scss";
import axiosInstance from "../../api/axiosInstance";

export default function Stores() {
  const [stores, setStores] = useState([]);
  const getStores = async () => {
    try {
      const req = await axiosInstance.get("/store");
      setStores(req?.data?.stores);
    } catch (error) {
      console.error("Error fetching stores", error);
    }
  };
  useEffect(() => {
    getStores();
  }, []);

  return (
    <section className="" style={{ marginTop: "120px" }}>
      <div className="container">
        <div className="storeSection">
          <div>
            <p className={`${css.categoryHeader} archivo `}>Stores</p>
            <p className="nunito p-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="storeContainer">
            {stores &&
              stores.map((store, index) => (
                <div key={index} className="storeBox">
                  <div className="title">
                    <h5>Store Name : </h5>
                    <p>{store.name}</p>
                  </div>
                  <Link className="location" to={store?.location}>
                    <i className="fa-solid fa-location-dot"></i>
                    <p>Get Location</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
