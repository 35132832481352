import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import css from '../style.module.css';

const data = [
    { name: 'Product Name ', value: 38.6 },
    { name: 'Product Name', value: 3.1 },
    { name: 'Product Name', value: 5 },
    { name: 'Product Name', value: 22.5 },
    { name: 'Product Name', value: 30.8 },
];


const COLORS = ['#BEAE99', '#B0B9BF', ' #8B693F', '#8B442A', '#584A2F'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default class Example extends PureComponent {
    render() {
        return (
            <div className={`${css.pieChart}`}>
                <p className={`m-0 inter ${css.headerFStyle}`}>Best Selling Products</p>
                <div className='d-flex justify-content-between align-items-center ' >
                    <div className='flex-grow-1'>
                        <ResponsiveContainer height={250}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    // label={renderCustomizedLabel}
                                    outerRadius={80}
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className=''>
                        {data.map((entry, index) => (
                            <p key={index} className='me-2 w-100'>
                                <span style={{ color: COLORS[index] }}>●</span> 
                                <span className={`ps-2 ${css.ProductProp}`}>{entry.name}</span>
                            </p>
                        ))}
                    </div>
                    <div className=''>
                        {data.map((entry, index) => (
                            <p key={index} className='me-2 w-100'>
                                {/* <span style={{ color: COLORS[index] }}>●</span>  */}
                                <span className={`ps-2 ${css.ProductProp}`}>{entry.value}K</span>
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
