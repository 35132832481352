import React from 'react';
import css from '../style.module.css';
import Search from '../Search';
import BarChart from '../Charts/BarChart';
import PieChart from '../Charts/PieChart';

export default function Dashboard() {
    const percentageArr = [
        { field: 'Total Users', result: "12.9K", percentage: 50, color: "#584A2F" },
        { field: 'Total Revenue', result: "700,825 EGP", percentage: 75, color: "#8B693F" },
        { field: 'Total Products', result: "15.5K", percentage: 50, color: "#B0B9BF" },
        { field: 'Total Orders', result: "11.4K", percentage: 75, color: "#8B442A" }
    ];
    return (  
        <div className="flex-grow-1 " style={{ transition: 'margin-left 0.3s' }} >
            <div>
                <Search />
                <div className="fadeInAnimate">
                    <p className={`${css.pad} ${css.textProp} inter m-0`}>Dashboard</p>
                    <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                    <div className="row mb-5">
                        {percentageArr.map((e, index) => (
                            <div key={index} className="col-md-3">
                                <div className={`${css.percentageBox} d-flex justify-content-between align-items-center`}>
                                    <div className="lato">
                                        <p className={`m-0 ${css.field} ${css.fieldColor}`}>{e.field}</p>
                                        <p className={`m-0 fw-bold ${css.results} ${css.resultsColor}`}>{e.result}</p>
                                    </div>
                                    <div className={`${css.percentageContainer}`}>
                                        <svg className={`${css.percentageCircle}`} viewBox="-2 -2 40 40">
                                            <path
                                                className={`${css.circleBackground}`}
                                                d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                                            />
                                            <path
                                                className={`${css.circleProgress}`}
                                                strokeDasharray={`${e.percentage}, 100`}
                                                d="M18 2.0845A15.9155 15.9155 0 0 0 2.0845 18A15.9155 15.9155 0 0 0 18 33.9155A15.9155 15.9155 0 0 0 33.9155 18A15.9155 15.9155 0 0 0 18 2.0845Z"
                                                style={{ stroke: e.color }}
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* BarChart */}
                    <div className='d-flex justify-content-center align-items-center '>
                        <div style={{ width: "65%" }} className=' me-3'>
                            <BarChart />
                        </div>
                        <div style={{ width: "35%" }} className=''>
                            <PieChart />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
