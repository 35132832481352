import React, { useContext, useState } from "react";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import YoumayAlsoLike from "./YoumayAlsoLike";
import css from "./style.module.css";
import axios from "axios";
import { UserContext } from "../../Context/UserInof";
import OtpPopup from "./ui/otp/OtpPopup";

export default function Payment() {
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedOptionCash, setSelectedOptionCash] = useState(false);
  const [error, setError] = useState(false);
  const [visable, setVisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const { isUser } = useContext(UserContext);
  const { orderData } = location.state || {};
  const { userGlobalInfo } = useContext(UserContext);

  const CreditedSelect = () => {
    setSelectedOption(true);
    setSelectedOptionCash(false);
    setVisable(true);
  };

  const CashSelect = () => {
    setSelectedOption(false);
    setSelectedOptionCash(true);
    setVisable(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const paymentMethod = selectedOption ? "paymob" : "cash";

    if (isUser === true) {
      const reqBody = {
        address: orderData?.address,
        items: orderData?.items,
        paymentMethod,
      };
      try {
        const response = await axios.post(
          "https://api.yafa-brand.com/order/createOrderIntent",
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${userGlobalInfo.token}`,
            },
          }
        );

        if (response?.data?.paymentUrl) {
          window.location.href = response.data.paymentUrl;
        } else {
          const orderId = response?.data.orderId;
          const myOrders = localStorage.getItem("myorders");
          const orders = myOrders ? JSON.parse(myOrders) : [];
          orders.push(orderId);
          localStorage.setItem("myorders", JSON.stringify(orders));
          setSelectedOption(false);
          setSelectedOptionCash(false);
          setOrderId(orderId);
          setIsVerified(true);
        }
      } catch (error) {
        console.error("Error submitting payment:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else if (isUser === false) {
      const reqBody = {
        ...orderData,
        paymentMethod,
      };
      try {
        const response = await axios.post(
          "https://api.yafa-brand.com/order/createOrderIntent",
          reqBody
        );
        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "purchase");
        }

        if (response?.data?.paymentUrl) {
          window.location.href = response.data.paymentUrl;
        } else {
          const orderId = response?.data.orderId;
          const myOrders = localStorage.getItem("myorders");
          const orders = myOrders ? JSON.parse(myOrders) : [];
          orders.push(orderId);
          localStorage.setItem("myorders", JSON.stringify(orders));
          setSelectedOption(false);
          setSelectedOptionCash(false);
          setOrderId(orderId);
          setIsVerified(true);
        }
      } catch (error) {
        console.error("Error submitting payment:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section className="" style={{ marginTop: "120px" }}>
      {isVerified && (
        <OtpPopup
          orderId={orderId}
          orderData={orderData}
          close={() => {
            setIsVerified(false);
          }}
        />
      )}
      <div className="container">
        <div className="cartText">
          <p
            className={`${css.categoryHeader} archivo text-center text-md-start`}
          >
            Check Out
          </p>
          <p className="nunito p-0">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry...
          </p>
        </div>
        <div className="row">
          <p
            className={`${css.categoryHeader} archivo text-center text-md-start`}
          >
            Payment Methods
          </p>
          {/* <div className="col-md-6">
            <div className="mt-4" style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Credit Card"
                className={`form-control ${css.inputCheckOut}`}
                name="card"
                id="card"
                style={{ paddingRight: "40px" }}
              />
              <div
                className={`cursor ${css.choose}`}
                onClick={CreditedSelect}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <div className={selectedOption ? css.ischoose : ""}></div>
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="mt-4" style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Cash"
                className={`form-control ${css.inputCheckOut}`}
                name="cash"
                id="cash"
                style={{ paddingRight: "40px" }}
                readOnly
              />
              <div
                className={`cursor ${css.choose}`}
                onClick={CashSelect}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <div className={selectedOptionCash ? css.ischoose : ""}></div>
              </div>
            </div>
          </div>
          <button
            className={`${css.btnContinue} text-center mt-5`}
            onClick={handleSubmit}
            disabled={loading || !visable}
          >
            {loading ? (
              <i className="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Submit"
            )}
          </button>
          {error && (
            <p className="mt-3 text-center text-danger fw-bold">
              {error.message}
            </p>
          )}
        </div>
      </div>
      <YoumayAlsoLike />
    </section>
  );
}
