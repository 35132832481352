import React, { useState } from 'react'
import Search from '../Search'
import css from '../style.module.css';

export default function OrderManagement() {
    const [openIndexes, setOpenIndexes] = useState([]);

    const toggleOpenIndex = (index) => {
        // If the index is already open, remove it from the array; otherwise, add it
        setOpenIndexes(prevIndexes =>
            prevIndexes.includes(index) ? prevIndexes.filter(i => i !== index) : [...prevIndexes, index]
        );
    };
    return (
        <div className="flex-grow-1 px-3" style={{ transition: 'margin-left 0.3s' }}>
            <Search />
            <div className='fadeInAnimate'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <p className={`${css.pad} ${css.textProp} inter m-0`}>Order Management</p>
                        <p className={`${css.dateProp}`}>Friday, December 15th 2023</p>
                    </div>
                    <button className={`${css.btnAddNew} rubik ${css.AddNewProp} text-white  d-flex justify-content-center align-items-center border-0 py-2`} >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 2.5H3C2.17157 2.5 1.5 3.17157 1.5 4V13C1.5 13.8284 2.17157 14.5 3 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V4C14.5 3.17157 13.8284 2.5 13 2.5Z" stroke="white" strokeLinejoin="round" />
                            <path d="M9.5 7.25C9.5 7.38807 9.38807 7.5 9.25 7.5C9.11193 7.5 9 7.38807 9 7.25C9 7.11193 9.11193 7 9.25 7C9.38807 7 9.5 7.11193 9.5 7.25Z" fill="white" stroke="white" />
                            <path d="M12 7.25C12 7.38807 11.8881 7.5 11.75 7.5C11.6119 7.5 11.5 7.38807 11.5 7.25C11.5 7.11193 11.6119 7 11.75 7C11.8881 7 12 7.11193 12 7.25Z" fill="white" stroke="white" />
                            <path d="M9.5 9.75C9.5 9.88807 9.38807 10 9.25 10C9.11193 10 9 9.88807 9 9.75C9 9.61193 9.11193 9.5 9.25 9.5C9.38807 9.5 9.5 9.61193 9.5 9.75Z" fill="white" stroke="white" />
                            <path d="M12 9.75C12 9.88807 11.8881 10 11.75 10C11.6119 10 11.5 9.88807 11.5 9.75C11.5 9.61193 11.6119 9.5 11.75 9.5C11.8881 9.5 12 9.61193 12 9.75Z" fill="white" stroke="white" />
                            <path d="M4.5 9.75C4.5 9.88807 4.38807 10 4.25 10C4.11193 10 4 9.88807 4 9.75C4 9.61193 4.11193 9.5 4.25 9.5C4.38807 9.5 4.5 9.61193 4.5 9.75Z" fill="white" stroke="white" />
                            <path d="M7 9.75C7 9.88807 6.88807 10 6.75 10C6.61193 10 6.5 9.88807 6.5 9.75C6.5 9.61193 6.61193 9.5 6.75 9.5C6.88807 9.5 7 9.61193 7 9.75Z" fill="white" stroke="white" />
                            <path d="M4.5 12.25C4.5 12.3881 4.38807 12.5 4.25 12.5C4.11193 12.5 4 12.3881 4 12.25C4 12.1119 4.11193 12 4.25 12C4.38807 12 4.5 12.1119 4.5 12.25Z" fill="white" stroke="white" />
                            <path d="M7 12.25C7 12.3881 6.88807 12.5 6.75 12.5C6.61193 12.5 6.5 12.3881 6.5 12.25C6.5 12.1119 6.61193 12 6.75 12C6.88807 12 7 12.1119 7 12.25Z" fill="white" stroke="white" />
                            <path d="M9.5 12.25C9.5 12.3881 9.38807 12.5 9.25 12.5C9.11193 12.5 9 12.3881 9 12.25C9 12.1119 9.11193 12 9.25 12C9.38807 12 9.5 12.1119 9.5 12.25Z" fill="white" stroke="white" />
                            <path d="M4 1.5V2.5M12 1.5V2.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.5 5H1.5" stroke="white" strokeLinejoin="round" />
                        </svg>
                        Oct 11,2023 - Nov 11,2022
                    </button>
                </div>
                <div className="d-flex justify-content-between my-2" >
                    {Array(3).fill(null).map((_, index) => {
                        const HeadersText = ["Total Orders", "Active Orders", "Completed Orders"]
                        return (
                            <div className={`${css.orderResult}  `} key={index}>
                                <p className={` rubik ${css.orderResultHeader} ${css.listProp}`}>{HeadersText[index]}</p>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <div className={`${css.bagContainer} d-flex justify-content-center align-items-center `}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.25 6.875V5.625C6.25 4.63044 6.64509 3.67661 7.34835 2.97335C8.05161 2.27009 9.00544 1.875 10 1.875V1.875C10.9946 1.875 11.9484 2.27009 12.6517 2.97335C13.3549 3.67661 13.75 4.63044 13.75 5.625V6.875M3.125 6.875C2.95924 6.875 2.80027 6.94085 2.68306 7.05806C2.56585 7.17527 2.5 7.33424 2.5 7.5V15.9375C2.5 17.1187 3.50625 18.125 4.6875 18.125H15.3125C16.4937 18.125 17.5 17.1676 17.5 15.9863V7.5C17.5 7.33424 17.4342 7.17527 17.3169 7.05806C17.1997 6.94085 17.0408 6.875 16.875 6.875H3.125Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.25 8.75V9.375C6.25 10.3696 6.64509 11.3234 7.34835 12.0267C8.05161 12.7299 9.00544 13.125 10 13.125C10.9946 13.125 11.9484 12.7299 12.6517 12.0267C13.3549 11.3234 13.75 10.3696 13.75 9.375V8.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <p className='m-0 ms-2 rubik fw-semibold text-gray' >126.500</p>
                                    </div>
                                    <div>
                                        <i className="fa-solid fa-arrow-up fa-fw text-gray"></i><span className='text-gray'>34.7%</span>
                                    </div>
                                </div>
                                <p className='text-end my-1 text-gray fw-semibold open-sans' style={{ fontSize: " 12px" }}>Compared to Oct 2023</p>
                            </div>
                        )
                    })}
                </div>
                <div className="row px-2">
                    {Array(3).fill(null).map((_, firstIndex) => (
                        <div
                            key={firstIndex}
                            className={`${css.permissionGroupsProp} bg-white my-2 cursor `}
                            onClick={() => toggleOpenIndex(firstIndex)} // Toggle visibility
                        >
                            <div className='d-flex justify-content-between align-items-center '>
                                <p className={`inter ${css.permissionGroupsText} m-0 inter fw-semibold`} style={{ lineHeight: '19.36px' }}>Order ID</p>
                                {openIndexes.includes(firstIndex) ? (
                                    <i className="fa-solid fa-angle-down"></i>
                                ) : (
                                    <i className="fa-solid fa-angle-up"></i>
                                )}
                            </div>
                            <div
                                className='slidingContentAnimate'
                                style={{
                                    maxHeight: openIndexes.includes(firstIndex) ? '200px' : '0px', // Adjust maxHeight for animation
                                    opacity: openIndexes.includes(firstIndex) ? 1 : 0,
                                    transition: 'max-height 0.4s ease, opacity 0.4s ease', // Smooth transition
                                    overflow: 'hidden' // Prevent overflow
                                }}
                            >
                                <div className='row mt-2'>
                                    <div className="col-md-4">
                                        <p className={` inter ${css.Internalwriting}`}>Customer Name <span className='fw-bold'>Omar Hany</span></p>
                                    </div>
                                    <div className="col-md-8">
                                        <p className={` inter ${css.Internalwriting}`}>Location <span className='fw-bold'>Egypt, Cairo</span></p>
                                    </div>
                                    <div className="col-md-4 "><p className={` inter ${css.Internalwriting}`}>Date <span className='fw-bold'>20 - 1 - 2025</span></p></div>
                                    <div className="col-md-4"><p className={` inter ${css.Internalwriting}`}>Status <span className='fw-bold'>Delivered</span></p></div>
                                    <div className="col-md-4"><p className={` inter ${css.Internalwriting}`}>Amount <span className='fw-bold'>1000.00 EGP</span></p></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
