import React, { useContext, useEffect, useState } from 'react';
import css from "../../style.module.css";
import { categoriesContext } from '../../../../Context/categories';

export default function CreateDataEdition({ setVisibleEdit, editCategory }) {
    const { updateCategories } = useContext(categoriesContext);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [categoryData, setCategoryData] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        if (editCategory) {
            setCategoryData(editCategory); // Set initial category data from editCategory
            setIsLoading(false);
        }
    }, [editCategory]);

    const getData = (e) => {
        const { name, value } = e.target;
        setCategoryData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const Submit = async () => {
        console.log(data);
        
        setIsLoading(true);
        try {
            const response = await updateCategories(categoryData._id, data);
            if (response.status === 200) {
                setMessage(response.data.message);
            } else {
                setMessage("An error occurred");
            }
        } catch (error) {
            setMessage("Name is already used");
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <p>
                Loading <i className="fas fa-spinner fa-pulse ms-2"></i>
            </p>
        );
    }

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Edit New Category</p>
            <input
                type="text"
                className={`${css.adminInput} work-sans my-2`}
                value={categoryData.name || ''}
                name="name"
                placeholder='Name'
                onChange={getData}
            />
            <input
                type="text"
                className={`${css.adminInput} work-sans my-2`}
                value={categoryData.description || ''}
                name="description"
                placeholder='Description'
                onChange={getData}
            />
            <div className='position-absolute bottom-0 mb-4 ms-5'>
                {message && <p className={`text-center ${message === "Name is already used" ? "text-danger" : "text-success"} fw-bold`}>{message}</p>}
                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`} onClick={() => setVisibleEdit(false)}>
                    Cancel
                </button>
                <button className={`${css.btnBoth}`} onClick={Submit}>
                    Submit
                    {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    );
}
