import React, { useContext, useState } from 'react';
import css from "../style.module.css";
import { countriesContext } from '../../../Context/Countries';

export default function CountriesNav({setCitiesData,setCountriesData ,countriesData,citiesData,setVisible}) {
    // const [active, setActive] = useState(null);
    const { addCountry, addCity } = useContext(countriesContext);
    const [countryInfo, SetCountryInfo] = useState({});
    const [cityInfo, SetCityInfo] = useState({});
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState(false);

    const liveCountryAdded = (country) => {
        const copyCountryGroup = [...countriesData]
        console.log(copyCountryGroup);  
        copyCountryGroup.push(country)
        setCountriesData(copyCountryGroup)
    }

    const liveCityAdded = (city) => {
        const copyCityGroup = [...citiesData]
        console.log(copyCityGroup);  
        copyCityGroup.push(city)
        setCitiesData(copyCityGroup)
    }

    const getCountryInfo = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        SetCountryInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const getCityInfo = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        SetCityInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const addNewCountry = async () => {
        setSpinner(true);
        try {
            const { data, status } = await addCountry(countryInfo)
            console.log("result : ");
            console.log({ data, status });
            if (status === 201) {
                liveCountryAdded({ name: countryInfo.name, _id: data.country._id })
            }
            setSpinner(false);
        } catch (error) {
            setError(error.response.data.message);
            setSpinner(false);
        }
    }
    const addNewCity = async () => {
        setSpinner(true);
        try {
            const { data, status } = await addCity(cityInfo)
            console.log("result : ");
            console.log({ data, status });
            if (status === 201) {
                liveCityAdded({ name: cityInfo.name, country: { _id: data.city.country } })
            }
            setSpinner(false);
        } catch (error) {
            setError(error.response.data.message);
            setSpinner(false);
        }
    }
    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Add New Country</p>
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='name' onChange={(e) => getCountryInfo(e)} placeholder='Name' />
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='code' onChange={(e) => getCountryInfo(e)} placeholder='Code' />
            <button className={`${css.btnBoth} my-3 text-white`} onClick={addNewCountry} >
                <svg width="25" className='me-2' height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                Submit
                {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
            </button>
            <div className='d-flex justify-content-between align-items-center mt-2 '>
                <p className={`inter ${css.textHeader}`} >Add New City</p>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0001 1.66663C8.35191 1.66663 6.74074 2.15537 5.37033 3.07105C3.99992 3.98672 2.93182 5.28821 2.30109 6.81093C1.67036 8.33365 1.50533 10.0092 1.82687 11.6257C2.14842 13.2422 2.94209 14.7271 4.10753 15.8925C5.27297 17.058 6.75782 17.8516 8.37433 18.1732C9.99084 18.4947 11.6664 18.3297 13.1891 17.699C14.7118 17.0682 16.0133 16.0001 16.929 14.6297C17.8447 13.2593 18.3334 11.6481 18.3334 9.99996C18.3334 8.90561 18.1179 7.82198 17.6991 6.81093C17.2803 5.79988 16.6665 4.88122 15.8926 4.1074C15.1188 3.33358 14.2002 2.71975 13.1891 2.30096C12.1781 1.88217 11.0944 1.66663 10.0001 1.66663ZM10.0001 16.6666C8.68154 16.6666 7.39261 16.2756 6.29628 15.5431C5.19996 14.8105 4.34547 13.7694 3.84089 12.5512C3.3363 11.333 3.20428 9.99256 3.46152 8.69936C3.71875 7.40615 4.35369 6.21826 5.28604 5.28591C6.21839 4.35356 7.40628 3.71863 8.69948 3.46139C9.99269 3.20416 11.3331 3.33618 12.5513 3.84076C13.7695 4.34535 14.8107 5.19983 15.5432 6.29616C16.2758 7.39249 16.6668 8.68142 16.6668 9.99996C16.6668 11.7681 15.9644 13.4638 14.7141 14.714C13.4639 15.9642 11.7682 16.6666 10.0001 16.6666ZM13.3334 9.16663H10.8334V6.66663C10.8334 6.44561 10.7456 6.23365 10.5893 6.07737C10.4331 5.92109 10.2211 5.83329 10.0001 5.83329C9.77907 5.83329 9.56711 5.92109 9.41083 6.07737C9.25455 6.23365 9.16675 6.44561 9.16675 6.66663V9.16663H6.66675C6.44574 9.16663 6.23378 9.25442 6.0775 9.4107C5.92122 9.56698 5.83342 9.77895 5.83342 9.99996C5.83342 10.221 5.92122 10.4329 6.0775 10.5892C6.23378 10.7455 6.44574 10.8333 6.66675 10.8333H9.16675V13.3333C9.16675 13.5543 9.25455 13.7663 9.41083 13.9225C9.56711 14.0788 9.77907 14.1666 10.0001 14.1666C10.2211 14.1666 10.4331 14.0788 10.5893 13.9225C10.7456 13.7663 10.8334 13.5543 10.8334 13.3333V10.8333H13.3334C13.5544 10.8333 13.7664 10.7455 13.9227 10.5892C14.079 10.4329 14.1668 10.221 14.1668 9.99996C14.1668 9.77895 14.079 9.56698 13.9227 9.4107C13.7664 9.25442 13.5544 9.16663 13.3334 9.16663Z" fill="#71839B" />
                </svg>
            </div>
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='name' onChange={(e) => getCityInfo(e)} placeholder='Name' />
            <input type="text" className={`${css.adminInput} work-sans my-2 `} name='country' onChange={(e) => getCityInfo(e)} placeholder='Country Id' />

            <div className='position-absolute bottom-0 mb-4 ms-5 '>
                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3 `} onClick={()=>setVisible(0)}>
                    <svg width="25" height="24" className='me-2' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Cancel
                </button>
                <button className={`${css.btnBoth} text-white`} onClick={addNewCity}>
                    <svg width="25" className='me-2' height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Submit
                    {spinner && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    )
}
