import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext(null);

export default function UserProvider({ children }) {
  const [isUser, setIsUser] = useState();
  const [currentForm, setCurrentForm] = useState("");
  const [Code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [userGlobalInfo, setUserGlobalInfo] = useState(() => {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : {};
  });

  useEffect(() => {
    if (userGlobalInfo) {
      localStorage.setItem("user", JSON.stringify(userGlobalInfo));
    }
    setIsUser(userGlobalInfo.token ? true : false);
  }, [userGlobalInfo]);

  return (
    <UserContext.Provider
      value={{
        userGlobalInfo,
        setUserGlobalInfo,
        isUser,
        setCurrentForm,
        currentForm,
        Code,setCode,
        setEmail,email
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
