import './lodingStyle.scss'
const Loding = () => {
  return (
    <>
      <div className="lodingPage">
        <i className="fa-solid fa-circle-notch fa-spin"></i>
      </div>
    </>
  );
};

export default Loding;
