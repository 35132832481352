import React, { useContext, useMemo, useState } from 'react';
import css from "../style.module.css";
import { colorContext } from '../../../Context/color';
import { productContext } from '../../../Context/product';

export default function NextProduct({ setVisible, someData }) {
    console.log("someData : ");
    console.log(someData);

    const { color } = useContext(colorContext);
    const { createaproduct } = useContext(productContext);
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedSizeId, setSelectedSizeId] = useState(''); // Track selected size ID

    const getData = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const colorOptions = useMemo(() => {
        return color?.colors.map((color) => (
            <option key={color._id} value={color._id}>
                {color.name}
            </option>
        ));
    }, [color]);

    const sizeOptions = useMemo(() => {
        const sizes = [
            { _id: '1', name: 'XS' },
            { _id: '2', name: 'S' },
            { _id: '3', name: 'M' },
            { _id: '4', name: 'L' },
            { _id: '5', name: 'XL' },
            { _id: '6', name: 'XXL' },
            { _id: '7', name: 'XXXL' },
        ];
        return sizes.map((size) => (
            <option key={size._id} value={size.name}>
                {size.name}
            </option>
        ));
    }, []);




    const handleColorOptions = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSizeOptions = (e) => {
        const { value } = e.target;
        setSelectedSizeId(value); // Save the selected size ID
        setData(prevState => ({
            ...prevState,
            size: value, // Update size in data state
        }));
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const productData = {
            ...someData,
            ...data,
            variantImage: selectedFiles, // Include selected files as product images
        };
        console.log("productData is : ");
        console.log(productData);

        try {
            const response = await createaproduct(productData);
            if (response) {
                setMessage('Product created successfully!');
            }
        } catch (error) {
            setMessage('Failed to create product. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <nav className={`${css.sideNavDesign} mb-5 position-relative`} style={{ height: "98%" }}>
            <p className={`inter ${css.textHeader}`}>Add Variant</p>
            <select value={data.color || ''} name='color' onChange={handleColorOptions} className={`${css.adminInput} work-sans my-2`}>
                <option value="">Select Color</option>
                {colorOptions}
            </select>

            <div className="upload-area w-100">
                <input
                    type="file"
                    id="file-upload"
                    accept=".svg, .jpg, .jpeg, .png, .gif"
                    onChange={handleFileChange}
                    multiple
                    style={{ display: 'none' }}
                />
                <label htmlFor="file-upload" className="upload-label">
                    <div className="upload-icon">⬆️</div>
                    <p><strong>Click to upload</strong> or drag and drop</p>
                    <span>(SVG, JPG, PNG, or GIF maximum *** x ***)</span>
                </label>
            </div>

            {/* Display selected images */}
            <div className="selected-images">
                {selectedFiles.map((file, index) => (
                    <div key={index} className="image-preview">
                        <img
                            src={URL.createObjectURL(file)}
                            alt="preview"
                            style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                        />
                    </div>
                ))}
            </div>

            <select value={selectedSizeId} name='size' onChange={handleSizeOptions} className={`${css.adminInput} work-sans my-2`}>
                <option value="">Select a Size</option>
                {sizeOptions}
            </select>

            <input type="text" className={`${css.adminInput} work-sans my-2`} name="stock" placeholder='Quantity' onChange={getData} />

            <div className='position-absolute bottom-0 mb-4 ms-5'>
                {message && <p className={`text-center ${message === "name is already used" ? "text-danger" : "text-success"} fw-bold`}>{message}</p>}

                <button className={`${css.btnBoth} ${css.btnBorder} ${css.Cnaceltext} bg-white me-3`} onClick={() => setVisible(false)}>
                    <svg width="25" height="24" className="me-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8955 9.59473L10.1035 14.3867" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path d="M14.8956 14.3898L10.0996 9.59277" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 21.25C17.6086 21.25 21.75 17.1086 21.75 12C21.75 6.89137 17.6086 2.75 12.5 2.75C7.39137 2.75 3.25 6.89137 3.25 12C3.25 17.1086 7.39137 21.25 12.5 21.25Z" stroke="#8B442A" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Cancel
                </button>

                <button className={`${css.btnBoth} text-white`} onClick={handleSubmit}>
                    <svg width="25" className="me-2 " height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.87075 11.2583C10.9598 12.579 12.5207 14.939 12.5207 14.939H12.552C12.552 14.939 15.868 9.0712 22.0286 5.4624" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2212 21.25C17.3298 21.25 21.4712 17.1086 21.4712 12C21.4712 6.89137 17.3298 2.75 12.2212 2.75C7.11256 2.75 2.97119 6.89137 2.97119 12C2.97119 17.1086 7.11256 21.25 12.2212 21.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    Submit
                    {isLoading && <i className="fas fa-spinner fa-pulse ms-2"></i>}
                </button>
            </div>
        </nav>
    );
}
